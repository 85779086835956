import { createStore } from 'vuex'
import login from './modules/login'
import files from './modules/files'
import artists from './modules/artists'
import galleries from './modules/galleries'
import others from './modules/others'
import artworks from './modules/artworks'
import auctionhouses from './modules/auctionhouses'
import articles from './modules/articles'
import exhibitions from './modules/exhibitions'
import auctions from './modules/auctions'
export default createStore({
  state: {
    SiteName: "Infomaster",
    translations: [
      {
        id: 1,
        en: "Home",
        kny: "Ahabanza",
      },
    ],
    isLoading: false,
    showSANav: true,
    apiUploadUrl: "",
    activePage: "dashboard",
    showAuth: true,
    countries: [
      { "code": "AF", "code3": "AFG", "name": "Afghanistan", "number": "004", "korean_name": "아프가니스탄" },
      { "code": "AL", "code3": "ALB", "name": "Albania", "number": "008", "korean_name": "알바니아" },
      { "code": "DZ", "code3": "DZA", "name": "Algeria", "number": "012", "korean_name": "알제리" },
      { "code": "AD", "code3": "AND", "name": "Andorra", "number": "020", "korean_name": "안도라" },
      { "code": "AO", "code3": "AGO", "name": "Angola", "number": "024", "korean_name": "앙골라" },
      { "code": "AI", "code3": "AIA", "name": "Anguilla", "number": "660", "korean_name": "앵귈라" },
      { "code": "AG", "code3": "ATG", "name": "Antigua and Barbuda", "number": "028", "korean_name": "앤티가 바부다" },
      { "code": "AR", "code3": "ARG", "name": "Argentina", "number": "032", "korean_name": "아르헨티나" },
      { "code": "AM", "code3": "ARM", "name": "Armenia", "number": "051", "korean_name": "아르메니아" },
      { "code": "AW", "code3": "ABW", "name": "Aruba", "number": "533", "korean_name": "아루바" },
      { "code": "AU", "code3": "AUS", "name": "Australia", "number": "036", "korean_name": "오스트레일리아" },
      { "code": "AT", "code3": "AUT", "name": "Austria", "number": "040", "korean_name": "오스트리아" },
      { "code": "AZ", "code3": "AZE", "name": "Azerbaijan", "number": "031", "korean_name": "아제르바이잔" },
      { "code": "BS", "code3": "BHS", "name": "Bahamas", "number": "044", "korean_name": "바하마" },
      { "code": "BH", "code3": "BHR", "name": "Bahrain", "number": "048", "korean_name": "바레인" },
      { "code": "BD", "code3": "BGD", "name": "Bangladesh", "number": "050", "korean_name": "방글라데시" },
      { "code": "BB", "code3": "BRB", "name": "Barbados", "number": "052", "korean_name": "바베이도스" },
      { "code": "BY", "code3": "BLR", "name": "Belarus", "number": "112", "korean_name": "벨라루스" },
      { "code": "BE", "code3": "BEL", "name": "Belgium", "number": "056", "korean_name": "벨기에" },
      { "code": "BZ", "code3": "BLZ", "name": "Belize", "number": "084", "korean_name": "벨리즈" },
      { "code": "BJ", "code3": "BEN", "name": "Benin", "number": "204", "korean_name": "베냉" },
      { "code": "BM", "code3": "BMU", "name": "Bermuda", "number": "060", "korean_name": "버뮤다" },
      { "code": "BT", "code3": "BTN", "name": "Bhutan", "number": "064", "korean_name": "부탄" },
      { "code": "BO", "code3": "BOL", "name": "Bolivia (Plurinational State of)", "number": "068", "korean_name": "볼리비아" },
      { "code": "BQ", "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535", "korean_name": "보네르, 신트 유스타티우스, 사바" },
      { "code": "BA", "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070", "korean_name": "보스니아 헤르체고비나" },
      { "code": "BW", "code3": "BWA", "name": "Botswana", "number": "072", "korean_name": "보츠와나" },
      { "code": "BV", "code3": "BVT", "name": "Bouvet Island", "number": "074", "korean_name": "부베 섬" },
      { "code": "BR", "code3": "BRA", "name": "Brazil", "number": "076", "korean_name": "브라질" },
      { "code": "IO", "code3": "IOT", "name": "British Indian Ocean Territory", "number": "086", "korean_name": "영국령 인도양 지역" },
      { "code": "BN", "code3": "BRN", "name": "Brunei Darussalam", "number": "096", "korean_name": "브루나이 다루살람" },
      { "code": "BG", "code3": "BGR", "name": "Bulgaria", "number": "100", "korean_name": "불가리아" },
      { "code": "BF", "code3": "BFA", "name": "Burkina Faso", "number": "854", "korean_name": "부르키나 파소" },
      { "code": "BI", "code3": "BDI", "name": "Burundi", "number": "108", "korean_name": "부룬디" },
      { "code": "CV", "code3": "CPV", "name": "Cabo Verde", "number": "132", "korean_name": "카보 베르데" },
      { "code": "KH", "code3": "KHM", "name": "Cambodia", "number": "116", "korean_name": "캄보디아" },
      { "code": "CM", "code3": "CMR", "name": "Cameroon", "number": "120", "korean_name": "카메룬" },
      { "code": "CA", "code3": "CAN", "name": "Canada", "number": "124", "korean_name": "캐나다" },
      { "code": "KY", "code3": "CYM", "name": "Cayman Islands", "number": "136", "korean_name": "케이맨 제도" },
      { "code": "CF", "code3": "CAF", "name": "Central African Republic", "number": "140", "korean_name": "중앙아프리카 공화국" },
      { "code": "TD", "code3": "TCD", "name": "Chad", "number": "148", "korean_name": "차드" },
      { "code": "CL", "code3": "CHL", "name": "Chile", "number": "152", "korean_name": "칠레" },
      { "code": "CN", "code3": "CHN", "name": "China", "number": "156", "korean_name": "중국" },
      { "code": "CX", "code3": "CXR", "name": "Christmas Island", "number": "162", "korean_name": "크리스마스 섬" },
      { "code": "CC", "code3": "CCK", "name": "Cocos (Keeling) Islands", "number": "166", "korean_name": "코코스(킬링) 제도" },
      { "code": "CO", "code3": "COL", "name": "Colombia", "number": "170", "korean_name": "콜롬비아" },
      { "code": "KM", "code3": "COM", "name": "Comoros", "number": "174", "korean_name": "코모로" },
      { "code": "CG", "code3": "COG", "name": "Congo (Congo-Brazzaville)", "number": "178", "korean_name": "콩고(브라자빌)" },
      { "code": "CD", "code3": "COD", "name": "Congo (Congo-Kinshasa)", "number": "180", "korean_name": "콩고(킨샤사)" },
      { "code": "CK", "code3": "COK", "name": "Cook Islands", "number": "184", "korean_name": "쿡 제도" },
      { "code": "CR", "code3": "CRI", "name": "Costa Rica", "number": "188", "korean_name": "코스타리카" },
      { "code": "HR", "code3": "HRV", "name": "Croatia", "number": "191", "korean_name": "크로아티아" },
      { "code": "CU", "code3": "CUB", "name": "Cuba", "number": "192", "korean_name": "쿠바" },
      { "code": "CW", "code3": "CUW", "name": "Curaçao", "number": "531", "korean_name": "쿠라사오" },
      { "code": "CY", "code3": "CYP", "name": "Cyprus", "number": "196", "korean_name": "키프로스" },
      { "code": "CZ", "code3": "CZE", "name": "Czechia", "number": "203", "korean_name": "체코" },
      { "code": "DK", "code3": "DNK", "name": "Denmark", "number": "208", "korean_name": "덴마크" },
      { "code": "DJ", "code3": "DJI", "name": "Djibouti", "number": "262", "korean_name": "지부티" },
      { "code": "DM", "code3": "DMA", "name": "Dominica", "number": "212", "korean_name": "도미니카" },
      { "code": "DO", "code3": "DOM", "name": "Dominican Republic", "number": "214", "korean_name": "도미니카 공화국" },
      { "code": "EC", "code3": "ECU", "name": "Ecuador", "number": "218", "korean_name": "에콰도르" },
      { "code": "EG", "code3": "EGY", "name": "Egypt", "number": "818", "korean_name": "이집트" },
      { "code": "SV", "code3": "SLV", "name": "El Salvador", "number": "222", "korean_name": "엘살바도르" },
      { "code": "GQ", "code3": "GNQ", "name": "Equatorial Guinea", "number": "226", "korean_name": "적도 기니" },
      { "code": "ER", "code3": "ERI", "name": "Eritrea", "number": "232", "korean_name": "에리트레아" },
      { "code": "EE", "code3": "EST", "name": "Estonia", "number": "233", "korean_name": "에스토니아" },
      { "code": "SZ", "code3": "SWZ", "name": "Eswatini", "number": "748", "korean_name": "에스와티니" },
      { "code": "ET", "code3": "ETH", "name": "Ethiopia", "number": "231", "korean_name": "에티오피아" },
      { "code": "FK", "code3": "FLK", "name": "Falkland Islands (Malvinas)", "number": "238", "korean_name": "포클랜드 제도(말비나스)" },
      { "code": "FO", "code3": "FRO", "name": "Faroe Islands", "number": "234", "korean_name": "페로 제도" },
      { "code": "FJ", "code3": "FJI", "name": "Fiji", "number": "242", "korean_name": "피지" },
      { "code": "FI", "code3": "FIN", "name": "Finland", "number": "246", "korean_name": "핀란드" },
      { "code": "FR", "code3": "FRA", "name": "France", "number": "250", "korean_name": "프랑스" },
      { "code": "GF", "code3": "GUF", "name": "French Guiana", "number": "254", "korean_name": "프랑스 기아나" },
      { "code": "PF", "code3": "PYF", "name": "French Polynesia", "number": "258", "korean_name": "프랑스 폴리네시아" },
      { "code": "TF", "code3": "ATF", "name": "French Southern Territories", "number": "260", "korean_name": "프랑스 남부 지역" },
      { "code": "GA", "code3": "GAB", "name": "Gabon", "number": "266", "korean_name": "가봉" },
      { "code": "GM", "code3": "GMB", "name": "Gambia", "number": "270", "korean_name": "감비아" },
      { "code": "GE", "code3": "GEO", "name": "Georgia", "number": "268", "korean_name": "조지아" },
      { "code": "DE", "code3": "DEU", "name": "Germany", "number": "276", "korean_name": "독일" },
      { "code": "GH", "code3": "GHA", "name": "Ghana", "number": "288", "korean_name": "가나" },
      { "code": "GI", "code3": "GIB", "name": "Gibraltar", "number": "292", "korean_name": "지브롤터" },
      { "code": "GR", "code3": "GRC", "name": "Greece", "number": "300", "korean_name": "그리스" },
      { "code": "GL", "code3": "GRL", "name": "Greenland", "number": "304", "korean_name": "그린란드" },
      { "code": "GD", "code3": "GRD", "name": "Grenada", "number": "308", "korean_name": "그레나다" },
      { "code": "GP", "code3": "GLP", "name": "Guadeloupe", "number": "312", "korean_name": "과들루프" },
      { "code": "GU", "code3": "GUM", "name": "Guam", "number": "316", "korean_name": "괌" },
      { "code": "GT", "code3": "GTM", "name": "Guatemala", "number": "320", "korean_name": "과테말라" },
      { "code": "GG", "code3": "GGY", "name": "Guernsey", "number": "831", "korean_name": "건지" },
      { "code": "GN", "code3": "GIN", "name": "Guinea", "number": "324", "korean_name": "기니" },
      { "code": "GW", "code3": "GNB", "name": "Guinea-Bissau", "number": "624", "korean_name": "기니비사우" },
      { "code": "GY", "code3": "GUY", "name": "Guyana", "number": "328", "korean_name": "가이아나" },
      { "code": "HT", "code3": "HTI", "name": "Haiti", "number": "332", "korean_name": "아이티" },
      { "code": "HM", "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334", "korean_name": "허드 섬과 맥도날드 제도" },
      { "code": "VA", "code3": "VAT", "name": "Holy See", "number": "336", "korean_name": "바티칸 시국" },
      { "code": "HN", "code3": "HND", "name": "Honduras", "number": "340", "korean_name": "온두라스" },
      { "code": "HK", "code3": "HKG", "name": "Hong Kong", "number": "344", "korean_name": "홍콩" },
      { "code": "HU", "code3": "HUN", "name": "Hungary", "number": "348", "korean_name": "헝가리" },
      { "code": "IS", "code3": "ISL", "name": "Iceland", "number": "352", "korean_name": "아이슬란드" },
      { "code": "IN", "code3": "IND", "name": "India", "number": "356", "korean_name": "인도" },
      { "code": "ID", "code3": "IDN", "name": "Indonesia", "number": "360", "korean_name": "인도네시아" },
      { "code": "IR", "code3": "IRN", "name": "Iran (Islamic Republic of)", "number": "364", "korean_name": "이란" },
      { "code": "IQ", "code3": "IRQ", "name": "Iraq", "number": "368", "korean_name": "이라크" },
      { "code": "IE", "code3": "IRL", "name": "Ireland", "number": "372", "korean_name": "아일랜드" },
      { "code": "IM", "code3": "IMN", "name": "Isle of Man", "number": "833", "korean_name": "맨 섬" },
      { "code": "IL", "code3": "ISR", "name": "Israel", "number": "376", "korean_name": "이스라엘" },
      { "code": "IT", "code3": "ITA", "name": "Italy", "number": "380", "korean_name": "이탈리아" },
      { "code": "JM", "code3": "JAM", "name": "Jamaica", "number": "388", "korean_name": "자메이카" },
      { "code": "JP", "code3": "JPN", "name": "Japan", "number": "392", "korean_name": "일본" },
      { "code": "JE", "code3": "JEY", "name": "Jersey", "number": "832", "korean_name": "저지" },
      { "code": "JO", "code3": "JOR", "name": "Jordan", "number": "400", "korean_name": "요르단" },
      { "code": "KZ", "code3": "KAZ", "name": "Kazakhstan", "number": "398", "korean_name": "카자흐스탄" },
      { "code": "KE", "code3": "KEN", "name": "Kenya", "number": "404", "korean_name": "케냐" },
      { "code": "KI", "code3": "KIR", "name": "Kiribati", "number": "296", "korean_name": "키리바시" },
      { "code": "KP", "code3": "PRK", "name": "Korea (Democratic People's Republic of)", "number": "408", "korean_name": "조선민주주의인민공화국" },
      { "code": "KR", "code3": "KOR", "name": "Korea (Republic of)", "number": "410", "korean_name": "대한민국" },
      { "code": "KW", "code3": "KWT", "name": "Kuwait", "number": "414", "korean_name": "쿠웨이트" },
      { "code": "KG", "code3": "KGZ", "name": "Kyrgyzstan", "number": "417", "korean_name": "키르기스스탄" },
      { "code": "LA", "code3": "LAO", "name": "Lao People's Democratic Republic", "number": "418", "korean_name": "라오스" },
      { "code": "LV", "code3": "LVA", "name": "Latvia", "number": "428", "korean_name": "라트비아" },
      { "code": "LB", "code3": "LBN", "name": "Lebanon", "number": "422", "korean_name": "레바논" },
      { "code": "LS", "code3": "LSO", "name": "Lesotho", "number": "426", "korean_name": "레소토" },
      { "code": "LR", "code3": "LBR", "name": "Liberia", "number": "430", "korean_name": "라이베리아" },
      { "code": "LY", "code3": "LBY", "name": "Libya", "number": "434", "korean_name": "리비아" },
      { "code": "LI", "code3": "LIE", "name": "Liechtenstein", "number": "438", "korean_name": "리히텐슈타인" },
      { "code": "LT", "code3": "LTU", "name": "Lithuania", "number": "440", "korean_name": "리투아니아" },
      { "code": "LU", "code3": "LUX", "name": "Luxembourg", "number": "442", "korean_name": "룩셈부르크" },
      { "code": "MO", "code3": "MAC", "name": "Macao", "number": "446", "korean_name": "마카오" },
      { "code": "MK", "code3": "MKD", "name": "North Macedonia", "number": "807", "korean_name": "북마케도니아" },
      { "code": "MG", "code3": "MDG", "name": "Madagascar", "number": "450", "korean_name": "마다가스카르" },
      { "code": "MW", "code3": "MWI", "name": "Malawi", "number": "454", "korean_name": "말라위" },
      { "code": "MY", "code3": "MYS", "name": "Malaysia", "number": "458", "korean_name": "말레이시아" },
      { "code": "MV", "code3": "MDV", "name": "Maldives", "number": "462", "korean_name": "몰디브" },
      { "code": "ML", "code3": "MLI", "name": "Mali", "number": "466", "korean_name": "말리" },
      { "code": "MT", "code3": "MLT", "name": "Malta", "number": "470", "korean_name": "몰타" },
      { "code": "MH", "code3": "MHL", "name": "Marshall Islands", "number": "584", "korean_name": "마셜 제도" },
      { "code": "MQ", "code3": "MTQ", "name": "Martinique", "number": "474", "korean_name": "마르티니크" },
      { "code": "MR", "code3": "MRT", "name": "Mauritania", "number": "478", "korean_name": "모리타니" },
      { "code": "MU", "code3": "MUS", "name": "Mauritius", "number": "480", "korean_name": "모리셔스" },
      { "code": "YT", "code3": "MYT", "name": "Mayotte", "number": "175", "korean_name": "마요트" },
      { "code": "MX", "code3": "MEX", "name": "Mexico", "number": "484", "korean_name": "멕시코" },
      { "code": "FM", "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583", "korean_name": "미크로네시아" },
      { "code": "MD", "code3": "MDA", "name": "Moldova (Republic of)", "number": "498", "korean_name": "몰도바" },
      { "code": "MC", "code3": "MCO", "name": "Monaco", "number": "492", "korean_name": "모나코" },
      { "code": "MN", "code3": "MNG", "name": "Mongolia", "number": "496", "korean_name": "몽골" },
      { "code": "ME", "code3": "MNE", "name": "Montenegro", "number": "499", "korean_name": "몬테네그로" },
      { "code": "MS", "code3": "MSR", "name": "Montserrat", "number": "500", "korean_name": "몬세라트" },
      { "code": "MA", "code3": "MAR", "name": "Morocco", "number": "504", "korean_name": "모로코" },
      { "code": "MZ", "code3": "MOZ", "name": "Mozambique", "number": "508", "korean_name": "모잠비크" },
      { "code": "MM", "code3": "MMR", "name": "Myanmar", "number": "104", "korean_name": "미얀마" },
      { "code": "NA", "code3": "NAM", "name": "Namibia", "number": "516", "korean_name": "나미비아" },
      { "code": "NR", "code3": "NRU", "name": "Nauru", "number": "520", "korean_name": "나우루" },
      { "code": "NP", "code3": "NPL", "name": "Nepal", "number": "524", "korean_name": "네팔" },
      { "code": "NL", "code3": "NLD", "name": "Netherlands", "number": "528", "korean_name": "네덜란드" },
      { "code": "NC", "code3": "NCL", "name": "New Caledonia", "number": "540", "korean_name": "뉴칼레도니아" },
      { "code": "NZ", "code3": "NZL", "name": "New Zealand", "number": "554", "korean_name": "뉴질랜드" },
      { "code": "NI", "code3": "NIC", "name": "Nicaragua", "number": "558", "korean_name": "니카라과" },
      { "code": "NE", "code3": "NER", "name": "Niger", "number": "562", "korean_name": "니제르" },
      { "code": "NG", "code3": "NGA", "name": "Nigeria", "number": "566", "korean_name": "나이지리아" },
      { "code": "NU", "code3": "NIU", "name": "Niue", "number": "570", "korean_name": "니우에" },
      { "code": "NF", "code3": "NFK", "name": "Norfolk Island", "number": "574", "korean_name": "노퍽 섬" },
      { "code": "MP", "code3": "MNP", "name": "Northern Mariana Islands", "number": "580", "korean_name": "북마리아나 제도" },
      { "code": "NO", "code3": "NOR", "name": "Norway", "number": "578", "korean_name": "노르웨이" },
      { "code": "OM", "code3": "OMN", "name": "Oman", "number": "512", "korean_name": "오만" },
      { "code": "PK", "code3": "PAK", "name": "Pakistan", "number": "586", "korean_name": "파키스탄" },
      { "code": "PW", "code3": "PLW", "name": "Palau", "number": "585", "korean_name": "팔라우" },
      { "code": "PS", "code3": "PSE", "name": "Palestine, State of", "number": "275", "korean_name": "팔레스타인" },
      { "code": "PA", "code3": "PAN", "name": "Panama", "number": "591", "korean_name": "파나마" },
      { "code": "PG", "code3": "PNG", "name": "Papua New Guinea", "number": "598", "korean_name": "파푸아 뉴기니" },
      { "code": "PY", "code3": "PRY", "name": "Paraguay", "number": "600", "korean_name": "파라과이" },
      { "code": "PE", "code3": "PER", "name": "Peru", "number": "604", "korean_name": "페루" },
      { "code": "PH", "code3": "PHL", "name": "Philippines", "number": "608", "korean_name": "필리핀" },
      { "code": "PN", "code3": "PCN", "name": "Pitcairn", "number": "612", "korean_name": "핏케언" },
      { "code": "PL", "code3": "POL", "name": "Poland", "number": "616", "korean_name": "폴란드" },
      { "code": "PT", "code3": "PRT", "name": "Portugal", "number": "620", "korean_name": "포르투갈" },
      { "code": "PR", "code3": "PRI", "name": "Puerto Rico", "number": "630", "korean_name": "푸에르토리코" },
      { "code": "QA", "code3": "QAT", "name": "Qatar", "number": "634", "korean_name": "카타르" },
      { "code": "RE", "code3": "REU", "name": "Réunion", "number": "638", "korean_name": "레위니옹" },
      { "code": "RO", "code3": "ROU", "name": "Romania", "number": "642", "korean_name": "루마니아" },
      { "code": "RU", "code3": "RUS", "name": "Russian Federation", "number": "643", "korean_name": "러시아" },
      { "code": "RW", "code3": "RWA", "name": "Rwanda", "number": "646", "korean_name": "르완다" },
      { "code": "BL", "code3": "BLM", "name": "Saint Barthélemy", "number": "652", "korean_name": "생바르텔레미" },
      { "code": "SH", "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654", "korean_name": "세인트헬레나" },
      { "code": "KN", "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659", "korean_name": "세인트 키츠 네비스" },
      { "code": "LC", "code3": "LCA", "name": "Saint Lucia", "number": "662", "korean_name": "세인트루시아" },
      { "code": "MF", "code3": "MAF", "name": "Saint Martin (French part)", "number": "663", "korean_name": "생마르탱" },
      { "code": "PM", "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666", "korean_name": "생피에르 미클롱" },
      { "code": "VC", "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670", "korean_name": "세인트빈센트 그레나딘" },
      { "code": "WS", "code3": "WSM", "name": "Samoa", "number": "882", "korean_name": "사모아" },
      { "code": "SM", "code3": "SMR", "name": "San Marino", "number": "674", "korean_name": "산마리노" },
      { "code": "ST", "code3": "STP", "name": "Sao Tome and Principe", "number": "678", "korean_name": "상투메 프린시페" },
      { "code": "SA", "code3": "SAU", "name": "Saudi Arabia", "number": "682", "korean_name": "사우디아라비아" },
      { "code": "SN", "code3": "SEN", "name": "Senegal", "number": "686", "korean_name": "세네갈" },
      { "code": "RS", "code3": "SRB", "name": "Serbia", "number": "688", "korean_name": "세르비아" },
      { "code": "SC", "code3": "SYC", "name": "Seychelles", "number": "690", "korean_name": "세이셸" },
      { "code": "SL", "code3": "SLE", "name": "Sierra Leone", "number": "694", "korean_name": "시에라리온" },
      { "code": "SG", "code3": "SGP", "name": "Singapore", "number": "702", "korean_name": "싱가포르" },
      { "code": "SX", "code3": "SXM", "name": "Sint Maarten (Dutch part)", "number": "534", "korean_name": "신트마르턴" },
      { "code": "SK", "code3": "SVK", "name": "Slovakia", "number": "703", "korean_name": "슬로바키아" },
      { "code": "SI", "code3": "SVN", "name": "Slovenia", "number": "705", "korean_name": "슬로베니아" },
      { "code": "SB", "code3": "SLB", "name": "Solomon Islands", "number": "090", "korean_name": "솔로몬 제도" },
      { "code": "SO", "code3": "SOM", "name": "Somalia", "number": "706", "korean_name": "소말리아" },
      { "code": "ZA", "code3": "ZAF", "name": "South Africa", "number": "710", "korean_name": "남아프리카" },
      { "code": "GS", "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239", "korean_name": "사우스조지아 사우스샌드위치 제도" },
      { "code": "SS", "code3": "SSD", "name": "South Sudan", "number": "728", "korean_name": "남수단" }, { "code": "ES", "code3": "ESP", "name": "Spain", "number": "724", "korean_name": "스페인" },
      { "code": "LK", "code3": "LKA", "name": "Sri Lanka", "number": "144", "korean_name": "스리랑카" },
      { "code": "SD", "code3": "SDN", "name": "Sudan", "number": "729", "korean_name": "수단" },
      { "code": "SR", "code3": "SUR", "name": "Suriname", "number": "740", "korean_name": "수리남" },
      { "code": "SJ", "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744", "korean_name": "스발바르 얀마옌" },
      { "code": "SE", "code3": "SWE", "name": "Sweden", "number": "752", "korean_name": "스웨덴" },
      { "code": "CH", "code3": "CHE", "name": "Switzerland", "number": "756", "korean_name": "스위스" },
      { "code": "SY", "code3": "SYR", "name": "Syrian Arab Republic", "number": "760", "korean_name": "시리아" },
      { "code": "TW", "code3": "TWN", "name": "Taiwan, Province of China", "number": "158", "korean_name": "대만" },
      { "code": "TJ", "code3": "TJK", "name": "Tajikistan", "number": "762", "korean_name": "타지키스탄" },
      { "code": "TZ", "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834", "korean_name": "탄자니아" },
      { "code": "TH", "code3": "THA", "name": "Thailand", "number": "764", "korean_name": "태국" },
      { "code": "TL", "code3": "TLS", "name": "Timor-Leste", "number": "626", "korean_name": "동티모르" },
      { "code": "TG", "code3": "TGO", "name": "Togo", "number": "768", "korean_name": "토고" },
      { "code": "TK", "code3": "TKL", "name": "Tokelau", "number": "772", "korean_name": "토켈라우" },
      { "code": "TO", "code3": "TON", "name": "Tonga", "number": "776", "korean_name": "통가" },
      { "code": "TT", "code3": "TTO", "name": "Trinidad and Tobago", "number": "780", "korean_name": "트리니다드 토바고" },
      { "code": "TN", "code3": "TUN", "name": "Tunisia", "number": "788", "korean_name": "튀니지" },
      { "code": "TR", "code3": "TUR", "name": "Turkey", "number": "792", "korean_name": "터키" },
      { "code": "TM", "code3": "TKM", "name": "Turkmenistan", "number": "795", "korean_name": "투르크메니스탄" },
      { "code": "TC", "code3": "TCA", "name": "Turks and Caicos Islands", "number": "796", "korean_name": "터크스 케이커스 제도" },
      { "code": "TV", "code3": "TUV", "name": "Tuvalu", "number": "798", "korean_name": "투발루" },
      { "code": "UG", "code3": "UGA", "name": "Uganda", "number": "800", "korean_name": "우간다" },
      { "code": "UA", "code3": "UKR", "name": "Ukraine", "number": "804", "korean_name": "우크라이나" },
      { "code": "AE", "code3": "ARE", "name": "United Arab Emirates", "number": "784", "korean_name": "아랍에미리트" },
      { "code": "GB", "code3": "GBR", "name": "United Kingdom of Great Britain and Northern Ireland", "number": "826", "korean_name": "영국" },
      { "code": "US", "code3": "USA", "name": "United States of America", "number": "840", "korean_name": "미국" },
      { "code": "UM", "code3": "UMI", "name": "United States Minor Outlying Islands", "number": "581", "korean_name": "미국령 군소 제도" },
      { "code": "UY", "code3": "URY", "name": "Uruguay", "number": "858", "korean_name": "우루과이" },
      { "code": "UZ", "code3": "UZB", "name": "Uzbekistan", "number": "860", "korean_name": "우즈베키스탄" },
      { "code": "VU", "code3": "VUT", "name": "Vanuatu", "number": "548", "korean_name": "바누아투" },
      { "code": "VE", "code3": "VEN", "name": "Venezuela (Bolivarian Republic of)", "number": "862", "korean_name": "베네수엘라" },
      { "code": "VN", "code3": "VNM", "name": "Viet Nam", "number": "704", "korean_name": "베트남" },
      { "code": "VG", "code3": "VGB", "name": "Virgin Islands (British)", "number": "092", "korean_name": "영국령 버진아일랜드" },
      { "code": "VI", "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850", "korean_name": "미국령 버진아일랜드" },
      { "code": "WF", "code3": "WLF", "name": "Wallis and Futuna", "number": "876", "korean_name": "왈리스 퓌튀나" },
      { "code": "EH", "code3": "ESH", "name": "Western Sahara", "number": "732", "korean_name": "서사하라" },
      { "code": "YE", "code3": "YEM", "name": "Yemen", "number": "887", "korean_name": "예멘" },
      { "code": "ZM", "code3": "ZMB", "name": "Zambia", "number": "894", "korean_name": "잠비아" },
      { "code": "ZW", "code3": "ZWE", "name": "Zimbabwe", "number": "716", "korean_name": "짐바브웨" }
    ],
    krwexchange: 1310,
    usdexchange: 1,

  },
  getters: {

  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    files,
    artists,
    galleries,
    others,
    artworks,
    auctionhouses,
    articles,
    exhibitions,
    auctions,
  }
})
