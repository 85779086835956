<template>
  <div class="top-section">
    <div class="desc">
      <h3>{{ item.gallery_name_eng }}</h3>
      <h5>{{ item.address_eng }}</h5>
      <h5>{{ item.contact_phone_number }}</h5>
    </div>
    <router-link
      :to="{
        name: 'ViewGallery',
        params: {
          id: item.gallery_id,
          name: $urlCompatible(item.gallery_name_eng),
        },
      }"
      >View&nbsp;More</router-link
    >
  </div>
  <div class="images-section">
    <div class="image">
      <img :src="$uploads(item.gallery_image)" @error="$imageurlalt()" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
h5 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>