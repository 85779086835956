<template>
  <div class="SiteFooter" id="footer">
    <div class="top">
      <div class="left">
        <div class="title">Art Ocean</div>
        <div class="desc">
          Immerse yourself in a curated collection of breathtaking artworks from
          the world's most talented creators, elevating your creative projects
          beyond the constraints of standard stock art.
        </div>
      </div>
      <div class="right">
        <div class="title">Popular</div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="nav-links">
          <ul>
            <li>
              <router-link :to="{ name: 'Home' }">{{
                $txt("Home")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ArtWorks' }">{{
                $txt("ArtWorks")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Artists' }">{{
                $txt("Artists")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Login' }">{{
                $txt("Galleries")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Login' }">{{
                $txt("Auction Houses")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Login' }">{{
                $txt("Auctions")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Login' }">{{
                $txt("About Us")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Login' }">{{
                $txt("Contact Us")
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="nav-links">
          <ul>
            <li>
              <router-link :to="{ name: 'Home' }">{{
                $txt("Most Viewed")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ArtWorks' }">{{
                $txt("Portrait")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Artists' }">{{
                $txt("Landscape")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Login' }">{{
                $txt("Stone")
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "SiteFooter",
  directives: {
    clickOutSide,
  },
  data() {
    return {
      translatedText: "",
      showDrop: false,
      ismodules: [],
    };
  },
  methods: {
    customMethod() {
      this.showDrop = false;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  mounted() {
    this.ismodules = this.$store.state.ismodules;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.SiteFooter {
  background-color: #fff;
  color: #000;
  border-top: 1px solid #d4d4d4;
  margin-top: 50px;
  padding: 50px 5rem;
  @media screen and (max-width: 1500px) {
  padding: 50px 5rem;
  }
  @media screen and (max-width: 1100px) {
  padding: 50px 5rem;
  }
  @media screen and (max-width: 850px) {
  padding: 50px 1rem;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 5rem;
    @media screen and (max-width: 1100px) {
      column-gap: 2rem;
    }
    @media screen and (max-width: 850px) {
    }
    .left {
      width: 50%;
      .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 14px;
        line-height: 1.5;
      }
    }
    .right {
      width: 50%;
      .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
    column-gap: 5rem;
    .left {
      width: 50%;
      .nav-links {
        ul {
          display: flex;
          flex-direction: column;
          li {
            width: 100%;
            margin-bottom: 10px;
            a {
              color: #000;
              font-size: 16px;
              font-weight: 300;
              &:hover {
                color: $blue;
              }
            }
          }
        }
      }
    }
    .right {
      width: 50%;
      .nav-links {
        ul {
          display: flex;
          flex-direction: column;
          li {
            width: 100%;
            margin-bottom: 10px;
            a {
              color: #000;
              font-size: 16px;
              font-weight: 300;
              &:hover {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
}
</style>