<template>
  <div class="filters">
    <div class="filter-header">
      <h3 class="header">Filters</h3>
      <br />
      <div class="search">
        <input
          type="text"
          placeholder="Search by artist name"
          v-model="filterData.artist_name"
        />
      </div>
    </div>
    <div class="filter-categories">
      <div
        class="category-item"
        :class="filterData.selectedCategory == 'All' ? 'active' : ''"
        @click="filterData.selectedCategory = 'All'"
      >
        All
      </div>
      <div
        class="category-item"
        v-for="category in categories"
        :key="category.id"
        :class="filterData.selectedCategory == category ? 'active' : ''"
        @click="filterData.selectedCategory = category"
      >
        {{ category }}
      </div>
    </div>
    <div class="filter-expands">
      <div class="expand-item">
        <div class="top" @click="filters.genre = !filters.genre">
          <i class="fa-solid fa-plus" v-if="!filters.genre"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>GENRE</p>
        </div>
        <div class="bottom" :class="filters.genre ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by genre"
            v-model="filterData.genre"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.countries = !filters.countries">
          <i class="fa-solid fa-plus" v-if="!filters.countries"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>ARTIST NATIONALITY</p>
        </div>
        <div class="bottom" :class="filters.countries ? 'active' : ''">
          <select v-model="filterData.nationality">
            <option value="">Select Nationality</option>
            <option
              v-for="(item, index) in $store.state.countries"
              :key="index"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.gender = !filters.gender">
          <i class="fa-solid fa-plus" v-if="!filters.gender"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>GENDER</p>
        </div>
        <div class="bottom" :class="filters.gender ? 'active' : ''">
          <select name="" id="" v-model="filterData.gender">
            <option value="">All</option>
            <option>Male</option>
            <option>Female</option>
          </select>
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.activityArea = !filters.activityArea">
          <i class="fa-solid fa-plus" v-if="!filters.activityArea"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>ACTIVITY AREA</p>
        </div>
        <div class="bottom" :class="filters.activityArea ? 'active' : ''">
          <input
            type="text"
            id="artwork-location"
            placeholder="Activity Area (ENG)"
            v-model="filterData.activityArea"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.Exhibition = !filters.Exhibition">
          <i class="fa-solid fa-plus" v-if="!filters.Exhibition"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>EXHIBITIONS</p>
        </div>
        <div class="bottom" :class="filters.Exhibition ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by exhibition"
            v-model="filterData.Exhibition"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.awards = !filters.awards">
          <i class="fa-solid fa-plus" v-if="!filters.awards"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>AWARDS</p>
        </div>
        <div class="bottom" :class="filters.awards ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by awards"
            v-model="filterData.awards"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nationalities: Array,
    activityAreas: Array,
  },
  data() {
    return {
      filters: {
        sizes: false,
        materials: false,
        orientation: false,
        medium: false,
        color: false,
        year: false,
        prices: false,
        hammer: false,
        uci: false,
        actionDate: false,
        lastUpdate: false,
        location: false,
        countries: false,
      },
      selectedCategory: "All",
      categories: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      filterData: {
        genre: "",
        nationality: "",
        gender: "",
        activityArea: "",
        Exhibition: "",
        awards: "",
        selectedCategory: "All",
        artist_name: "",
      },
    };
  },
  methods: {},
  watch: {
    filterData: {
      handler() {
        this.$emit("filterData", this.filterData);
      },
      deep: true,
    },
  },
  mounted() {
    let artist = this.$route.query.artist;
    if (artist) {
      this.filterData.artist_name = decodeURIComponent(artist);
      this.$emit("filterData", this.filterData);
    }
  },
};
</script>

<style scoped lang="scss">
.search {
  width: 100%;
  margin-bottom: 10px;
  input {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
}
</style>