<template>
  <div class="Home" v-scroll="handleScroll">
    <site-navbar />
    <div class="slider-container">
      <div class="hooper">
        <div class="myslide" v-if="selectedItem && selectedItem != null">
          <div
            class="slide-img"
            :style="{
              backgroundImage:
                'url(' + $uploads(selectedItem.article_image) + ')',
            }"
          >
            <div class="slide-content">
              <div class="left-bottom">
                <h6>{{ selectedItem.article_source_eng }}</h6>
                <h3>{{ selectedItem.article_name_eng }}</h3>
                <div class="description">
                  <p class="desc" v-html="selectedItem.article_text_eng"></p>
                </div>
                <div class="item-date">
                  <h4>By {{ selectedItem.article_writer_eng }}</h4>
                </div>
              </div>
              <!-- <div class="featured" v-if="selectedItem.article_featured">
                Featured
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="article-content" v-if="selectedItem">
        <div class="article-header">
          <div class="article-date">
            <h6>{{ selectedItem.article_date }}</h6>
          </div>
          <div class="article-title">
            <hr />
          </div>
          <div class="article-date">
            <h6>{{ $ago(selectedItem.article_date) }}</h6>
          </div>
        </div>
        <div class="article-description">
          <div v-html="selectedItem.article_text_eng"></div>
        </div>
      </div>
        <div class="popular-header">
          <h3>RELATED ARTICLES</h3>
          <br>
        </div>
      <div class="articles-items">
        <router-link
          :to="{
            name: 'ViewArticle',
            params: {
              id: item.article_id,
              name: $urlCompatible(item.article_name_eng),
            },
          }"
          class="item"
          v-for="item in allItems"
          :key="item.article_id"
        >
          <article-card :item="item" />
        </router-link>
      </div>
    </div>
    <site-footer />
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      viewPass: "password",
      hooperSettings: {
        itemsToShow: 1.3,
        itemsToScroll: 1,
        wrapAround: true,
        snapAlign: "center",
        transition: 300,
        autoplay: true,
        touchDrag: true,
      },
      selectedItem: null,
      items: [],
      current_index: 0,
      isLoading: true,
      viewPopup: false,
      selectedItem: null,
      categories: [
        {
          id: 1,
          name: "Photography",
        },
        {
          id: 2,
          name: "Drawings",
        },
        {
          id: 3,
          name: "Mixed Media",
        },
        {
          id: 4,
          name: "Sculpture",
        },
        {
          id: 5,
          name: "Digital",
        },
        {
          id: 6,
          name: "Installation",
        },
        {
          id: 7,
          name: "Collage",
        },
        {
          id: 8,
          name: "Printmaking",
        },
      ],
      selectedCategory: "All",
      filters: {
        subjects: false,
        styles: false,
        orientation: false,
        materials: false,
        sizes: false,
        prices: false,
        countries: false,
      },
      articles: [],
      allItems: [],
      slides: [
        {
          id: 1,
          article_image: "article1.jpeg",
          article_title:
            "The Art of Color: Exploring Vibrancy in Contemporary Masterpieces",
          article_tag: "ART MOVEMENT",
          article_date: "08.08.2023",
          article_author: "Jennifer Lawrence",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 1,
          article_image: "cover1.png",
          article_title:
            "The Art of Color: Exploring Vibrancy in Contemporary Masterpieces",
          article_tag: "ART MOVEMENT",
          article_date: "08.08.2023",
          article_author: "Jennifer Lawrence",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
      ],
    };
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >=
          document.documentElement.offsetHeight - 600;

        if (bottomOfWindow) {
          if (this.allItems.length != 0) {
            setTimeout(() => {
              this.items.push(
                ...this.allItems.splice(0, this.current_index + 10)
              );
              this.current_index = this.current_index + 10;
            }, 2000);
          } else {
            setTimeout(() => {
              console.log("done");
              this.isLoading = false;
            }, 2000);
          }
        }
      };
    },
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    filterArticles() {
      if (this.selectedCategory == "All") {
        this.articles = this.allItems;
      } else {
        this.articles = this.allItems.filter((article) => {
          return (
            article.article_tag.toLocaleLowerCase() ==
            this.selectedCategory.toLocaleLowerCase()
          );
        });
      }
    },
    getSingleItem() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTICLE_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.selectedItem = res.data;
          this.selectedItem.article_images_initial = JSON.parse(
            this.selectedItem.article_images
          );
          this.allItems = res.data.related;
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getSingleItem();
  },
  // watch
  watch: {
    "$route.params.id": function (id) {
      this.getSingleItem();
      scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .slider-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 180px;
    margin-bottom: 50px;
    @media screen and (max-width: 1500px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 850px) {
      // height: 300px;
    }
    .slide-img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .slide-content {
        min-height: 800px;
        background: rgba(0, 0, 0, 0.582);
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 1500px) {
          min-height: 350px;
        }
        .left-bottom {
          padding: 5rem;
          padding-bottom: 2rem;
          color: #fff;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          row-gap: 2rem;
          @media screen and (max-width: 1500px) {
          }
          h6 {
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            background: rgba(109, 109, 109, 0.397);
            padding: 0.5rem 1rem;
            border-radius: 500px;
            @media screen and (max-width: 1500px) {
              font-size: 0.7rem;
              padding: 0.2rem 0.5rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
          h3 {
            font-size: 2.7rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            width: 50%;
            @media screen and (max-width: 1500px) {
              width: 60%;
              font-size: 1.8rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1.5rem;
            }
          }
          .item-date {
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            margin-right: 1rem;
            margin-bottom: 0.7rem;
            color: #fff;
            @media screen and (max-width: 1500px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.8rem;
            }
          }
          .description {
            display: flex;
            align-items: flex-start;
            .date {
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              text-align: left;
              margin-right: 1rem;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
            hr {
              width: 40px;
              height: 2px;
              background: #fff;
              margin-top: 0.7rem;
              margin-right: 1rem;
            }
            p {
              font-size: 1.25rem;
              text-align: left;
              color: #ececec;
              width: 50%;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              span{
                background: transparent !important;
              }
              @media screen and (max-width: 1500px) {
                font-size: 0.9rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 0.9rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
        .featured {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          padding: 0.2rem 1rem;
          color: #fff;
          background: rgba(255, 255, 255, 0.259);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          font-size: 1rem;
          border-radius: 500px;
          display: none;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .populars-items-widget {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    .popular-header {
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0rem;
      @media screen and (max-width: 850px) {
        padding: 1rem;
        padding-left: 0px;
      }
      h3 {
        color: #000000;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        @media screen and (max-width: 1500px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 27px;
        }
        @media screen and (max-width: 850px) {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 1rem;
        }
      }
      .search {
        width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        input {
          width: 100%;
          padding: 0.5rem 1rem;
          border: 1px #adadad solid;
          border-radius: 5px;
          padding-left: 2.5rem;
          font-size: 1rem;
          outline: none;
          @media screen and (max-width: 1500px) {
            font-size: 0.9rem;
            padding: 0.25rem 0.5rem;
            padding-left: 1.8rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
        i {
          color: #adadad;
          font-size: 1.25rem;
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-50%);
          @media screen and (max-width: 1500px) {
            left: 0.5rem;
            font-size: 1rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
    }
    .popular-items {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      column-gap: 2rem;
      row-gap: 2rem;
      @media screen and (max-width: 1500px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
      .item {
        display: flex;
        flex-direction: column;
        .item-img {
          width: 100%;
          height: 200px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px;
          }
          .tag {
            position: absolute;
            top: 1rem;
            right: 1rem;
            padding: 0.2rem 1rem;
            color: #fff;
            background: rgba(255, 255, 255, 0.259);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            border-radius: 500px;
            font-size: 1rem;
            @media screen and (max-width: 1500px) {
              font-size: 0.7rem;
            }
          }
        }
        .item-content {
          margin-top: 1rem;
          .item-header {
            .item-date {
              font-size: 1rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-align: left;
              margin-right: 1rem;
              margin-bottom: 0.7rem;
              color: $blue;
              @media screen and (max-width: 1500px) {
                font-size: 0.8rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.6rem;
              }
            }
            .item-title {
              font-size: 1.25rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-align: left;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
          .item-description {
            font-size: 1rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-align: left;
            color: #333;
            margin-top: 1rem;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (max-width: 1500px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
  .article-content {
    display: flex;
    column-gap: 2rem;
    margin: 2rem 0;
    margin-bottom: 3rem;
    @media screen and (max-width: 850px) {
      flex-direction: column;
      margin: 1rem 0;
      margin-bottom: 3rem;
    }
    .article-header {
      display: flex;
      align-items: center;
      height: fit-content;
      hr {
        width: 30px;
        height: 2px;
        background: #000;
        margin: 0 1rem;
        margin-top: 0.7rem;
      }
      h6 {
        font-size: 0.75rem;
        white-space: nowrap;
      }
      .article-title {
        display: flex;
        align-items: center;
        hr {
          width: 30px;
          height: 2px;
          background: #6b6b6b;
          margin: 0 1rem;
        }
      }
    }
    .article-description {
    }
  }
  .page-content {
    margin-top: 0;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .page-body {
      width: calc(100% - 300px);
      padding-top: 2rem;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 250px);
      }
      @media screen and (max-width: 850px) {
        width: 100%;
      }
      .items-container {
        .items-header {
          h3 {
            color: #000000;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
          p {
            color: #333;
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            @media screen and (max-width: 1500px) {
              margin-top: 0.2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
        .on-phone-search {
          display: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          column-gap: 10px;
          @media screen and (max-width: 850px) {
            padding: 1rem;
            padding-left: 0px;
          }
          .search {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .form {
              input {
                width: 100%;
                padding: 0.5rem 1rem;
                border: 1px #adadad solid;
                border-radius: 5px;
                font-size: 1.25rem;
                padding-left: 2.5rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
              i {
                color: #adadad;
                font-size: 1.25rem;
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);

                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .filter {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 5px;
            background: $blue;
            color: #fff;
            padding: 10px 1.5rem;
            border-radius: 500px;
            position: relative;
            i {
              color: #fff;
              position: relative;
              font-size: 1.25rem;
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
        .items-list {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
          column-gap: 2rem;
          row-gap: 2rem;
          @media screen and (max-width: 1500px) {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          }
          @media screen and (max-width: 400px) {
            grid-template-columns: 1fr;
          }
          .artist-item {
            width: 100%;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 0px 10px 1px #0000001f;
            cursor: pointer;
            @media screen and (max-width: 1100px) {
            }
            @media screen and (max-width: 850px) {
              width: 100%;
            }
            .top-section {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1rem;
              @media screen and (max-width: 1500px) {
                padding: 0.5rem;
              }
              .desc {
                padding-left: 1rem;
                @media screen and (max-width: 1500px) {
                  padding-left: 0.5rem;
                }
                h3 {
                  color: #000000;
                  font-size: 1.6rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  @media screen and (max-width: 1500px) {
                    font-size: 1rem;
                  }
                  @media screen and (max-width: 1100px) {
                    font-size: 1.5rem;
                  }
                  @media screen and (max-width: 850px) {
                    font-size: 1.25rem;
                  }
                }
                h5 {
                  color: #969696;
                  font-size: 1rem;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.7rem;
                  }
                  @media screen and (max-width: 1100px) {
                    font-size: 1rem;
                  }
                  @media screen and (max-width: 850px) {
                    font-size: 0.9rem;
                  }
                }
                p {
                  color: #333;
                  font-size: 1rem;
                  margin-top: 0.3rem;
                  @media screen and (max-width: 1100px) {
                    font-size: 1rem;
                  }
                  @media screen and (max-width: 850px) {
                    font-size: 0.9rem;
                  }
                }
              }
              a {
                color: #5f5f5f;
                font-size: 1.25rem;
                font-weight: 500;
                border: 1px $blue solid;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                @media screen and (max-width: 1500px) {
                  padding: 0.2rem 1rem;
                  font-size: 0.9rem;
                }
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
                &:hover {
                  background: $blue;
                  color: #fff;
                }
              }
            }
            .images-section {
              // one big image and 4 small images
              display: grid;
              grid-template-columns: 1fr;
              column-gap: 5px;
              row-gap: 5px;
              padding: 1rem;
              .image {
                width: 100%;
                height: fit-content;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 10px;
                  height: 200px;
                  @media screen and (max-width: 1500px) {
                    height: 150px;
                  }
                }
              }
            }
            .bottom-section {
              padding: 1rem;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              a {
                padding: 0.7rem 2rem;
                background: white;
                color: #000;
                font-size: 1.25rem;
                border-radius: 5px;
                border: 1px #000 solid;
                &:hover {
                  background: #000;
                  color: white;
                }
                @media screen and (max-width: 1100px) {
                  padding: 0.7rem 2rem;
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  padding: 0.5rem 2rem;
                  font-size: 1rem;
                }
              }
            }
          }
        }
        .loader {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3rem 0;
          img {
            -webkit-animation: spin 1s linear infinite;
            -moz-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
            @media screen and (max-width: 1500px) {
              width: 50px;
            }
          }
          @-moz-keyframes spin {
            100% {
              -moz-transform: rotate(360deg);
            }
          }
          @-webkit-keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}
</style>