<template>
  <div class="item-img">
    <img :src="$uploads(item.exhibition_image)" @error="$imageurlalt()" />
    <!-- <div class="tag">{{ item.article_tag }}</div> -->
  </div>
  <div class="item-content">
    <div class="left">
      <label for="">{{ $date(item.startDate, "month") }}</label>
      <h1>{{ $date(item.startDate, "day") }}</h1>
    </div>
    <div class="right">
      <div class="item-header">
        <div class="item-title">
          <h3>{{ item.exhibition_name_eng }}</h3>
        </div>
      </div>
      <div class="item-description">
        <p v-html="item.exhibition_description_eng"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>