<template>
  <notifications position="bottom right" :style="`z-index: 999`" />
  <iloader v-if="$store.state.isLoading" />
  <PageAuth
    v-if="$store.state.showAuth"
    @login="$store.state.showAuth = false"
  />
  <router-view />
</template>
<script>
import iloader from "./views/components/iloader.vue";
import PageAuth from "./views/site/components/PageAuth.vue";
export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    iloader,
    PageAuth,
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
    if (this.$getUser()) {
      this.$store.state.showAuth = false;
    }
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "@/assets/scss/main.scss";
* {
  // font-family: "Fira Sans", sans-serif;
  // font-family: "Poppins", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left;
}
button {
  text-align: center;
}
// input color
input[type="color"] {
  padding: 0 !important;
}
button {
  cursor: pointer;
}
form {
  width: 100%;
}
.form-group {
  width: 100%;
  .input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 1500px) {
      margin-bottom: 1rem;
      row-gap: 0.3rem;
    }
    input,
    select,
    textarea {
      width: 100%;
      color: #000;
      padding: 10px 10px;
      border-radius: 10px;
      border: 1px #66666659 solid;
      @media screen and (max-width: 1500px) {
        font-size: 0.8rem;
        padding: 7px 10px;
        border-radius: 5px;
      }
    }
    label {
      font-weight: 400;
      font-size: 0.9rem;
      color: #333;
      @media screen and (max-width: 1500px) {
        font-size: 0.8rem;
      }
      &.password {
        display: flex;
        justify-content: space-between;
        span {
          cursor: pointer;
        }
      }
    }
  }
  .pass-mark {
    margin-top: -1.5rem;
    color: #333;
    @media screen and (max-width: 1500px) {
      font-size: 0.65rem;
      margin-top: -1rem;
    }
  }
  .terms {
    margin: 1.5rem 0;
    color: #333;
    @media screen and (max-width: 1500px) {
      margin: 1rem 0;
      font-size: 0.8rem;
    }
    a {
      color: $blue;
    }
  }
}
.page-content {
  margin-top: 170px;
  @media screen and (max-width: 1500px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 1100px) {
    margin-top: 0;
  }
}

.on-sm {
  display: none !important;
}
.on-lg {
  display: flex;
}
@media screen and (max-width: 850px) {
  .on-sm {
    display: flex !important;
  }
  .on-lg {
    display: none !important;
  }
}

button.danger {
  background: rgb(141, 58, 58) !important;
  color: white !important;
}

.artist-tab-content {
  display: flex;
  .artist-tab-body {
    width: calc(100% - 300px);
    padding-top: 2rem;
    @media screen and (max-width: 1500px) {
      width: calc(100% - 200px);
    }
    @media screen and (max-width: 850px) {
      width: 100%;
    }
    .artists-container {
      .artists-header {
        h3 {
          color: #000000;
          font-size: 3rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          @media screen and (max-width: 1100px) {
            font-size: 27px;
          }
          @media screen and (max-width: 850px) {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 1rem;
          }
        }
        p {
          color: #333;
          font-size: 1.6rem;
          margin-top: 1rem;
          margin-bottom: 2rem;
          @media screen and (max-width: 1100px) {
            font-size: 1.3rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 1rem;
          }
        }
      }
      .on-phone-search {
        display: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        column-gap: 10px;
        @media screen and (max-width: 850px) {
          padding: 1rem;
          padding-left: 0px;
        }
        .search {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .form {
            input {
              width: 100%;
              padding: 0.5rem 1rem;
              border: 1px #adadad solid;
              border-radius: 5px;
              font-size: 1.25rem;
              padding-left: 2.5rem;
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
            i {
              color: #adadad;
              font-size: 1.25rem;
              position: absolute;
              top: 50%;
              left: 1rem;
              transform: translateY(-50%);

              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
        .filter {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 5px;
          background: $blue;
          color: #fff;
          padding: 10px 1.5rem;
          border-radius: 500px;
          position: relative;
          i {
            color: #fff;
            position: relative;
            font-size: 1.25rem;
            @media screen and (max-width: 1100px) {
              font-size: 1rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.9rem;
            }
          }
        }
      }
      .items-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        column-gap: 2rem;
        row-gap: 2rem;
        @media screen and (max-width: 400px) {
          grid-template-columns: 1fr;
        }
        .artist-item {
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0px 0px 10px 1px #0000001f;
          cursor: pointer;
          @media screen and (max-width: 1100px) {
          }
          @media screen and (max-width: 850px) {
            width: 100%;
          }
          .top-section {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 1rem;
            .image {
              img {
                border-radius: 50%;
                width: 100px;
                height: 100px;
                object-fit: cover;
                border: 5px $blue solid;
              }
            }
            .desc {
              padding-left: 1rem;
              h3 {
                color: #000000;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                @media screen and (max-width: 1100px) {
                  font-size: 1.5rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 1.25rem;
                }
              }
              h5 {
                color: #969696;
                font-size: 1rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
              p {
                color: #333;
                font-size: 1rem;
                margin-top: 0.3rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .images-section {
            // one big image and 4 small images
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 5px;
            row-gap: 5px;
            padding: 1rem;
            .image:first-child {
              grid-column: 1 / span 2;
              grid-row: 1 / span 2;
              height: 205px;
              @media screen and (max-width: 400px) {
                height: 145px;
              }
            }
            .image {
              width: 100%;
              height: 100px;
              overflow: hidden;
              @media screen and (max-width: 400px) {
                height: 70px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .bottom-section {
            padding: 1rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
              padding: 0.7rem 2rem;
              background: white;
              color: #000;
              font-size: 1.25rem;
              border-radius: 5px;
              border: 1px #000 solid;
              &:hover {
                background: #000;
                color: white;
              }
              @media screen and (max-width: 1100px) {
                padding: 0.7rem 2rem;
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                padding: 0.5rem 2rem;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
.filters {
  padding-top: 2rem;
  width: 300px;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1500px) {
    width: 250px;
  }
  .filter-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 1rem;
    .header {
      color: #333;
      font-size: 1.25rem;
      font-weight: 700;
      text-transform: uppercase;
      @media screen and (max-width: 1500px) {
        font-size: 1rem;
      }
    }

    .sorts {
      display: flex;
      justify-content: flex-end;
      position: relative;
      i {
        position: absolute;
        left: 10px;
        top: 28px;
        color: #696969;

        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
          top: 23px;
        }
      }

      input {
        width: 100%;
        margin: 1rem 0;
        border: 1px #adadad solid;
        padding: 8px;
        border-radius: 5px;
        padding-right: 1rem;
        padding-left: 2rem;
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
          padding: 4px 8px;
          padding-left: 2rem;
        }
      }
      select {
        width: 100%;
        margin: 1rem 0;
        border: 1px #adadad solid;
        padding: 8px;
        border-radius: 5px;
        padding-right: 1rem;
        padding-left: 2rem;
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
        }
        option {
          padding: 10px 0;
        }
      }
    }
  }
  .filter-categories {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
    .category-item {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px $blue solid;
      cursor: pointer;
      font-size: 0.8rem;

      @media screen and (max-width: 1500px) {
        font-size: 0.7rem;
        padding: 5px 8px;
      }
      &:hover,
      &.active {
        background: $blue;
        color: white;
      }
    }
  }
  .filter-expands {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    .expand-item {
      border-top: 1px #0000004f solid;
      // border-bottom: 1px #0000004f solid;
      &:last-child {
        border-bottom: 1px #0000004f solid;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem 1rem;
        color: #000000a1;
        cursor: pointer;

        @media screen and (max-width: 1500px) {
          padding: 0.45rem 0.5rem;
        }
        i {
          font-size: 1.35rem;
          @media screen and (max-width: 1500px) {
            font-size: 1rem;
          }
        }
        p {
          font-size: 1.25rem;
          text-transform: uppercase;
          @media screen and (max-width: 1500px) {
            font-size: 0.9rem;
          }
        }
      }
      .bottom {
        background: #fff;
        height: 0;
        overflow: hidden;
        &.active {
          padding: 10px;
          padding-bottom: 1rem;
          height: fit-content;
        }
        select,
        input {
          width: 100%;
          border-bottom: 1px #e6e6e6 solid;
          padding: 8px;
          border-radius: 0px;
          outline: none;
          @media screen and (max-width: 1500px) {
            font-size: 0.7rem;
          }
          option {
            padding: 10px 0;
          }
        }
        .options {
          display: flex;
          flex-wrap: wrap;
          column-gap: 1rem;
          row-gap: 1rem;
          .option-item {
            border: 1px #333 solid;
            padding: 5px 10px;
            border-radius: 3px;
            min-width: 100px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .8rem;
            &:hover,
            &.active {
              background: $blue !important;
              border: 1px $blue solid !important;
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

.artworks-images-container {
  .images-header {
    h3 {
      color: #000000;
      font-size: 3rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media screen and (max-width: 1500px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 1100px) {
        font-size: 27px;
      }
      @media screen and (max-width: 850px) {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 1rem;
      }
    }
    p {
      color: #333;
      font-size: 1.6rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
      @media screen and (max-width: 1500px) {
        margin-top: 0.2rem;
        font-size: 1rem;
      }
    }
  }
  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    @media screen and (max-width: 1500px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    @media screen and (max-width: 400px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    grid-gap: 1rem;
    .image-item {
      width: 100%;
      position: relative;
      cursor: pointer;
      border-radius: 10px;
      border: 0.3px solid #00000021;
      overflow: hidden;
      &:hover {
        box-shadow: 0px 0px 10px 3px #00000028;
      }
      img {
        height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: top center;
        @media screen and (max-width: 1500px) {
          height: 200px;
        }
      }
      .art-desc {
        padding: 1rem;
        background: white;
        @media screen and (max-width: 1500px) {
          padding: 0.5rem;
        }
        .art-name {
          font-size: 1.5rem;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 1.1rem;
          }
        }
        .art-dime {
          font-size: 1rem;
          font-weight: 300;
          @media screen and (max-width: 1500px) {
            font-size: 0.7rem;
          }
        }
        .artist {
          margin-top: 1rem;
          display: block;
        }
        .loc {
          font-style: italic;
        }
        .owner {
          margin-top: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media screen and (max-width: 1500px) {
            margin-top: 1rem;
          }
          .left {
            .owner-name {
              font-size: 0.9rem;
              font-weight: 400;
              @media screen and (max-width: 1500px) {
                font-size: 0.8rem;
              }
            }
            p {
              font-size: 0.9rem;
              font-weight: 400;
              @media screen and (max-width: 1500px) {
                font-size: 0.7rem;
              }
            }
          }
          .right {
            h3 {
              font-size: 1.1rem;
              font-weight: 300;
              color: #5e5e5e;
              @media screen and (max-width: 1500px) {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}

.images-tab-body {
  width: calc(100% - 300px);
  padding-top: 2rem;
  @media screen and (max-width: 1500px) {
    width: calc(100% - 200px);
  }
  @media screen and (max-width: 850px) {
    width: 100%;
  }
}

.artists-items-list {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
  .artist-item {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 1px #0000001f;
    cursor: pointer;
    @media screen and (max-width: 1100px) {
    }
    @media screen and (max-width: 850px) {
      width: 100%;
    }
    .top-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 1rem;
      @media screen and (max-width: 1500px) {
        padding: 0.5rem;
      }
      .image {
        img {
          border-radius: 50%;
          width: 100px;
          height: 100px;
          object-fit: cover;
          border: 5px $blue solid;
          @media screen and (max-width: 1500px) {
            width: 55px;
            height: 55px;
            border: 3px $blue solid;
          }
        }
      }
      .desc {
        h3 {
          color: #000000;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 0.7rem;
          text-align: center;
          @media screen and (max-width: 1500px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1.5rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 1.25rem;
          }
        }
        h5 {
          color: #969696;
          font-size: 1rem;
          text-align: center;
          @media screen and (max-width: 1500px) {
            font-size: 0.7rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
        p {
          color: #333;
          font-size: 1rem;
          margin-top: 0.3rem;
          text-align: center;
          font-weight: 600;
          font-style: italic;
          @media screen and (max-width: 1500px) {
            margin-top: 0.1rem;
            font-size: 0.75rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
    }
    // .images-section {
    //   // one big image and 4 small images
    //   display: grid;
    //   grid-template-columns: repeat(4, 1fr);
    //   column-gap: 5px;
    //   row-gap: 5px;
    //   padding: 1rem;
    //   .image:first-child {
    //     grid-column: 1 / span 2;
    //     grid-row: 1 / span 2;
    //     height: 205px;
    //     @media screen and (max-width: 1500px) {
    //       height: 120px;
    //     }
    //     @media screen and (max-width: 400px) {
    //       height: 145px;
    //     }
    //   }
    //   .image {
    //     width: 100%;
    //     height: 100px;
    //     overflow: hidden;
    //     @media screen and (max-width: 1500px) {
    //       height: 57px;
    //     }
    //     @media screen and (max-width: 400px) {
    //       height: 70px;
    //     }
    //     img {
    //       width: 100%;
    //       height: 100%;
    //       object-fit: cover;
    //     }
    //   }
    // }
    .bottom-section {
      padding: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        padding: 0.7rem 2rem;
        background: white;
        color: #000;
        font-size: 1.25rem;
        border-radius: 5px;
        border: 1px #000 solid;
        &:hover {
          background: #000;
          color: white;
        }
        @media screen and (max-width: 1500px) {
          padding: 0.2rem 1rem;
          font-size: 0.9rem;
        }
        @media screen and (max-width: 1100px) {
          padding: 0.7rem 2rem;
          font-size: 1rem;
        }
        @media screen and (max-width: 850px) {
          padding: 0.5rem 2rem;
          font-size: 1rem;
        }
      }
    }
  }
}

.articles-items-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .article-item {
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    border: 0.3px solid #00000021;
    overflow: hidden;
    &:hover {
      box-shadow: 0px 0px 10px 3px #00000028;
    }
    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
      object-position: top center;
      @media screen and (max-width: 1500px) {
        height: 170px;
      }
    }
    .content {
      padding: 1rem;
      background: white;
      @media screen and (max-width: 1500px) {
        padding: 0.5rem;
      }
      .title {
        h3 {
          font-size: 1.25rem;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
      .author {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 1500px) {
          margin-top: 1rem;
        }
        .left {
          .owner-name {
            font-size: 1.25rem;
            font-weight: 400;
            @media screen and (max-width: 1500px) {
              font-size: 0.9rem;
            }
          }
          p {
            font-size: 1.1rem;
            font-weight: 300;
            @media screen and (max-width: 1500px) {
              font-size: 0.8rem;
            }
          }
        }
        .right {
          h3 {
            font-size: 1.5rem;
            font-weight: 200;
            color: #5e5e5e;
          }
        }
      }
    }
  }
}

.actionhouse-items-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  column-gap: 2rem;
  row-gap: 2rem;
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
  .artist-item {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 1px #0000001f;
    cursor: pointer;
    @media screen and (max-width: 1100px) {
    }
    @media screen and (max-width: 850px) {
      width: 100%;
    }
    .top-section {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      @media screen and (max-width: 1500px) {
        padding: 0.5rem;
      }
      .desc {
        padding-left: 1rem;
        @media screen and (max-width: 1500px) {
          padding-left: 0.5rem;
        }
        h3 {
          color: #000000;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          @media screen and (max-width: 1500px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1.5rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 1.25rem;
          }
        }
      }
    }
    .images-section {
      // one big image and 4 small images
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 5px;
      row-gap: 5px;
      padding: 1rem;
      .image {
        width: 100%;
        height: fit-content;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          height: 150px;
          @media screen and (max-width: 1500px) {
            height: 100px;
          }
        }
      }
    }
    .description-section {
      padding: 1rem 1rem;
      p {
        color: #686868;
        font-size: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
        text-align: center;
        line-height: 1.3;
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 1.3rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 1rem;
        }
      }
      .location {
        border-bottom: 1px $blue solid;
        display: flex;
        justify-content: center;
        margin-top: 4rem;
        h5 {
          color: #000;
          font-size: 1rem;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
    }
    .bottom-section {
      padding: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        padding: 0.7rem 2rem;
        background: $blue;
        color: #fff;
        font-size: 1.25rem;
        border-radius: 5px;
        border: 1px $blue solid;
        width: 100%;
        text-align: center;
        &:hover {
          background: white;
          color: $blue;
        }
        @media screen and (max-width: 1500px) {
          padding: 0.4rem 2rem;
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          padding: 0.7rem 2rem;
          font-size: 1rem;
        }
        @media screen and (max-width: 850px) {
          padding: 0.5rem 2rem;
          font-size: 1rem;
        }
      }
    }
  }
}

.gallery-items-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 350px));
  column-gap: 2rem;
  row-gap: 2rem;
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  }
  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
  .artist-item {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 1px #0000001f;
    cursor: pointer;
    @media screen and (max-width: 1100px) {
    }
    @media screen and (max-width: 850px) {
      width: 100%;
    }
    .top-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      @media screen and (max-width: 1500px) {
        padding: 0.5rem;
      }
      .desc {
        padding-left: 1rem;
        @media screen and (max-width: 1500px) {
          padding-left: 0.5rem;
        }
        h3 {
          color: #000000;
          font-size: 1.1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 5px;
          @media screen and (max-width: 1500px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1.5rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 1.25rem;
          }
        }
        h5 {
          color: #969696;
          font-size: 0.8rem;
          @media screen and (max-width: 1500px) {
            font-size: 0.7rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
        p {
          color: #333;
          font-size: 1rem;
          margin-top: 0.3rem;
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
      a {
        color: #5f5f5f;
        font-size: 1.25rem;
        font-weight: 500;
        border: 1px $blue solid;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        @media screen and (max-width: 1500px) {
          padding: 0.2rem 1rem;
          font-size: 0.9rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 0.9rem;
        }
        &:hover {
          background: $blue;
          color: #fff;
        }
      }
    }
    .images-section {
      // one big image and 4 small images
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 5px;
      row-gap: 5px;
      padding: 1rem;
      .image {
        width: 100%;
        height: fit-content;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          height: 200px;
          @media screen and (max-width: 1500px) {
            height: 150px;
          }
        }
      }
    }
    .bottom-section {
      padding: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        padding: 0.7rem 2rem;
        background: white;
        color: #000;
        font-size: 1.25rem;
        border-radius: 5px;
        border: 1px #000 solid;
        &:hover {
          background: #000;
          color: white;
        }
        @media screen and (max-width: 1100px) {
          padding: 0.7rem 2rem;
          font-size: 1rem;
        }
        @media screen and (max-width: 850px) {
          padding: 0.5rem 2rem;
          font-size: 1rem;
        }
      }
    }
  }
}

.exhibit-list-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 420px));
  column-gap: 2rem;
  row-gap: 2rem;
  width: calc(100% - 300px);
  &.all {
    width: calc(100%);
    @media screen and (max-width: 1500px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
      width: calc(100%);
    }
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    width: calc(100% - 250px);
  }
  @media screen and (max-width: 850px) {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .item {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 0px 20px 10px rgba(9, 45, 48, 0.1);
    border-radius: 10px;
    overflow: hidden;
    .item-img {
      width: 100%;
      height: 200px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      .tag {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0.2rem 1rem;
        color: #fff;
        background: rgba(255, 255, 255, 0.259);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        border-radius: 500px;
        font-size: 1rem;
        @media screen and (max-width: 1500px) {
          font-size: 0.7rem;
        }
      }
    }
    .item-content {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 25% 75%;
      padding: 2rem 1rem;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        label {
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-align: left;
          color: $blue;
          text-transform: uppercase;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.7rem;
          }
        }
        h1 {
          font-size: 3rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-align: left;
          color: #000000;
          margin-top: 0rem;
          @media screen and (max-width: 1500px) {
            font-size: 2rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 1.5rem;
          }
        }
      }
      .right {
        .item-header {
          .item-title {
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700 !important;
            line-height: normal;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (max-width: 1500px) {
              font-size: 1rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.9rem;
            }
          }
        }
        .item-description {
          font-size: 1rem;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-align: left;
          color: #333;
          margin-top: 1rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

.actions-items-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(550px, 30%));
  column-gap: 1rem;
  row-gap: 1rem;
  padding-top: 2rem;
  row-gap: 3rem;
  @media screen and (max-width: 1500px) {
    row-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(450px, 50%));
  }
  @media screen and (max-width: 850px) {
    row-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 100%));
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .event-item {
    width: 100%;
    display: flex;
    box-shadow: 0px 0px 10px 3px #00000028;
    @media screen and (max-width: 850px) {
      flex-direction: column;
    }
    .left {
      width: 50%;
      height: 100%;
      background: #000;
      max-height: 250px;
      @media screen and (max-width: 1500px) {
        max-height: 250px;
      }
      @media screen and (max-width: 850px) {
        width: 100%;
        max-height: 150px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      width: 50%;
      padding: 1rem;
      @media screen and (max-width: 850px) {
        width: 100%;
      }
      .title {
        margin-bottom: 1rem;
        h3 {
          font-size: 1.25rem !important;
          font-weight: 500;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 1.15rem;
          }
        }
        &::after {
          content: "";
          display: block;
          width: 50px;
          height: 5px;
          background: $blue;
          margin-bottom: 1rem;
          border-radius: 100px;
          @media screen and (max-width: 1500px) {
            width: 35px;
            height: 3px;
          }
        }
      }
      label {
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
        }
      }
      .date,
      .location,
      .time {
        margin-right: 1rem;
        display: flex;
        column-gap: 0.5rem;
        width: 100%;
        @media screen and (max-width: 1500px) {
          column-gap: 0.25rem;
        }
        p {
          font-size: 1rem;
          font-weight: 300;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
      }
      .price {
        margin-top: 2rem;
        display: flex;
        column-gap: 0.5rem;
        font-size: 1.5rem;
        @media screen and (max-width: 1500px) {
          margin-top: 1rem;
          font-size: 1.25rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 1.25rem;
          margin-top: 1rem;
        }
        label {
          @media screen and (max-width: 1500px) {
            font-size: 1.25rem;
          }
        }
        p {
          font-size: 1.5rem;
          font-weight: 500;
          color: $blue;
          @media screen and (max-width: 1500px) {
            font-size: 1.25rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 1.25rem;
          }
        }
      }
      .description {
        margin-top: 1rem;
        @media screen and (max-width: 1500px) {
          margin-top: 0.5rem;
        }
        p {
          font-size: 1rem;
          font-weight: 300;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
.description-tab-navs {
  width: 100%;
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    column-gap: 2rem;
    li {
      padding: 0.2rem 0rem;
      font-size: 1.25rem;
      font-weight: 500;
      color: #000;
      cursor: pointer;
      border-bottom: 2px transparent solid;
      text-transform: uppercase;
      @media screen and (max-width: 1500px) {
        font-size: 0.9rem;
      }
      &:hover {
        border-bottom: 2px $blue solid;
        color: $blue;
      }
      &.active {
        border-bottom: 2px $blue solid;
        color: $blue;
      }
      @media screen and (max-width: 1100px) {
        font-size: 1rem;
      }
      @media screen and (max-width: 850px) {
        font-size: 0.9rem;
      }
    }
  }
}

.description-tab-content {
  width: 100%;
  .tab-content {
    &.about {
      p {
        color: #333;
        font-size: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 1.3rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 1rem;
        }
      }
      .about-bottom {
        display: flex;
        flex-direction: column;
        .web {
          font-size: 1.5rem;
          margin-top: 2rem;
          font-weight: 500;
          margin-bottom: 0.5rem;
        }
        .socials {
          display: flex;
          column-gap: 2rem;
          i {
            font-size: 1.75rem;
            color: #000000;
            cursor: pointer;
            &:hover {
              color: $blue;
            }
          }
        }
      }
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  img {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    @media screen and (max-width: 1500px) {
      width: 50px;
    }
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

a {
  text-decoration: none;
  color: #000;
}
input {
  outline: none;
  border: none;
}
.flex-btn {
  display: flex;
  justify-content: space-between;
}

.art-table {
  width: 100%;
  border-radius: 10.919px;
  box-shadow: 0px 3.64px 27.299px 0px rgba(46, 45, 116, 0.05);
  table {
    width: 100%;
  }
  thead {
    background: white;
    th {
      padding: 1rem;
      font-size: 0.9rem;
      border-bottom: 1px solid #bbb2c721;
      text-align: left;
      &.sm {
        width: 10px;
      }
    }
  }
  tbody {
    tr {
      background: #f7f7f7b7;
      td {
        padding: 1rem;
        font-size: 0.9rem;
        border-bottom: 1px solid #bbb2c721;
        text-align: left;
        font-weight: 550;
        color: #667085;
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 5px;
        }
        &.sm {
          width: 10px;
        }
        .img {
          display: flex;
          .right {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            p {
              font-size: 1rem;
              font-weight: 600;
              color: #1d1f2c;
            }
            span {
              margin-top: 5px;
              font-size: 0.8rem;
              font-weight: 500;
              color: #667085;
            }
          }
        }
        &.blue {
          color: $blue;
        }
        &.big {
          font-weight: 600;
        }
        .status {
          padding: 0.15rem 0.5rem;
          border-radius: 5px;
          font-size: 0.8rem;
          color: #fff;
          display: inline-block;
          &.pending {
            background: $lightBlue;
            color: $blue;
          }
          &.completed {
            background: #27ae60;
          }
          &.cancelled {
            background: #ff00001a;
            color: #ff0000;
          }
        }
        .actions {
          display: flex;
          column-gap: 1rem;
          i {
            font-size: 0.85rem;
            color: #667085;
            cursor: pointer;
            &:hover {
              color: $blue;
            }
          }
        }
      }
      &:hover,
      &.active {
        background: #efeff3;
      }
    }
  }
}
button.outline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  background-color: $lightBlue;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  color: $blue;
  font-size: 1.2rem;
  &:hover {
    background-color: lighten($color: $blue, $amount: 30%);
    color: #000;
    i {
      color: #000;
    }
    span {
      color: #000;
    }
  }
  i {
    font-size: 20px;
    color: $blue;
    margin-right: 5px;
  }
  span {
    font-size: 1.2rem;
    font-weight: 400;
    color: $blue;
  }
}
.btn-primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  background-color: $blue;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.2rem;
  &:hover {
    background-color: darken($color: $blue, $amount: 20%);
    color: #fff;
  }
  i {
    font-size: 20px;
    color: #fff;
    margin-right: 5px;
  }
  span {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
  }
}
.form-group {
  margin-bottom: 3rem !important;
  .form-header {
    margin-bottom: 0.5rem;
    font-size: 1.1rem !important;
    font-weight: 600;
    color: #000;
  }
}
.double-input {
  display: flex;
  select {
    width: 72px !important;
    border-radius: 10px 0 0 10px !important;
    display: block !important;
  }
  input {
    width: calc(100% - 72px) !important;
    border-radius: 0 10px 10px 0 !important;
    display: block !important;
  }
}

.group-2 {
  display: grid;
  grid-template-columns: 1fr 1fr !important;
  column-gap: 1rem !important;
}
.group-3 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  column-gap: 1rem !important;
}
.group-4 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  column-gap: 1rem !important;
}
.group-5 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  column-gap: 1rem !important;
}

.d-flex {
  display: flex;
  column-gap: 5px;
}
.blue {
  color: $blue;
}

.add-btn {
  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: $blue;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    i {
      font-size: 20px;
      color: $blue;
      margin-right: 5px;
    }
    span {
      font-size: 1.2rem;
      font-weight: 400;
      color: white;
    }
    i {
      color: white;
    }
    &:hover {
      background-color: darken($color: $blue, $amount: 10%);
      i {
        color: #fff;
      }
      span {
        color: #fff;
      }
    }

    &.lg {
      padding: 10px 30px;
      font-size: 1.5rem;
      @media screen and (max-width: 1500px) {
        padding: 10px 20px;
        font-size: 1.2rem;
      }
    }
    &.wd {
      padding: 10px 40px;
      font-size: 1.2rem;
      @media screen and (max-width: 1500px) {
        padding: 10px 25px;
        font-size: 1rem;
      }
    }
  }
}

.articles-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 350px));
  column-gap: 2rem;
  row-gap: 4rem;
  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .item {
    display: flex;
    flex-direction: column;
    .item-img {
      width: 100%;
      height: 200px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      .tag {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0.2rem 1rem;
        color: #fff;
        background: rgba(255, 255, 255, 0.259);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        border-radius: 500px;
        font-size: 1rem;
        @media screen and (max-width: 1500px) {
          font-size: 0.7rem;
        }
      }
    }
    .item-content {
      margin-top: 1rem;
      .item-header {
        .item-date {
          font-size: 1rem !important;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: left;
          margin-right: 1rem;
          color: $blue;
          margin-bottom: 0.7rem;
          width: 100%;
          @media screen and (max-width: 1500px) {
            font-size: 1rem !important;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.6rem !important;
          }
          p {
            font-size: 1rem !important;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: right;
            color: #646464;
            @media screen and (max-width: 1500px) {
              font-size: 1rem !important;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.6rem !important;
            }
          }
        }
        .item-title {
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700 !important;
          line-height: normal;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
      .item-description {
        font-size: 1rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: left;
        color: #333;
        margin-top: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 0.7rem;
        }
      }
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  img {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    width: 50px;
    @media screen and (max-width: 1500px) {
    }
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.d-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.my-filters {
  // position: fixed;
  right: 3rem;
  top: 150px;
  background: #fff;
  max-height: calc(100vh - 200px);
  padding-top: 0;
  margin-top: 2rem;
  overflow-y: auto;
  margin-left: 1rem;
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
    }
  }
}

.one-l{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.two-l{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>