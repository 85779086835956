<template>
  <div class="top-section">
    <div class="desc">
      <h3>{{ item.auction_house_name_eng }}</h3>
    </div>
  </div>
  <div class="images-section">
    <div class="image">
      <img :src="$uploads(item.auction_house_image)" @error="$imageurlalt()" />
    </div>
  </div>
  <div class="description-section">
    <p>{{ item.auction_house_website }}</p>
    <p>{{ item.auction_house_email }}</p>
    <p>{{ item.auction_house_tel }}</p>
    <div class="location">
      <h5>{{ item.auction_house_location_eng }}</h5>
    </div>
  </div>
  <div class="bottom-section">
    <router-link
      :to="{
        name: 'ViewAuctionHouses',
        params: {
          id: item.auction_house_id,
          name: $urlCompatible(item.auction_house_name_eng),
        },
      }"
      >View Auctions</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>