<template>
  <div class="Home">
    <site-navbar />
    <div class="page-content">
      <div class="page-body">
        <div class="artworks-images-container">
          <div class="images-header">
            <h3>Explore</h3>
            <p>Unique artworks available for acquisition.</p>
          </div>
          <div class="image-list">
            <router-link
              class="image-item"
              v-for="item in items"
              :key="item.artwork_id"
              :to="{
                name: `ViewArtWork`,
                params: {
                  id: item.artwork_id,
                  name: $urlCompatible(item.title_eng),
                },
              }"
            >
              <artwork-card :item="item" />
            </router-link>
            <div class="loader" v-if="isLoading">
              <img :src="$img('loader.png')" alt="" class="load-img" />
            </div>
          </div>
          <pagination
            class="pagination"
            :totalItems="allResults.length"
            :itemsPerPage="itemsPerPage"
            :itemCount="false"
            @pageChanged="
              (page) => {
                changePage(page);
              }
            "
          />
        </div>
      </div>
      <artworks-filter
        class="on-lg my-filters"
        data-id="1"
        @filterData="
          (data) => {
            filterData = data;
          }
        "
      />
    </div>
    <site-footer style="margin-top: 1rem" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      allItems: [],
      items: [],
      current_index: 0,
      isLoading: false,
      filterData: {
        title_eng: "",
        creation_year: "",
        category_eng: "",
        materials_eng: [],
        mediums_eng: [],
        mediums_techniques_eng: "",
        genres_eng: "",
        signiture_location_eng: "",
        artwork_location_eng: "",
        artist_name_eng: "",
        sale_price: "",
        hammer_price: "",
        selectedCategory: "All",
      },
      currentPage: 1,
      itemsPerPage: 42,
      allResults: [],
    };
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >=
          document.documentElement.offsetHeight - 800;

        if (bottomOfWindow) {
          if (this.allItems.length != 0) {
            setTimeout(() => {
              this.images.push(
                ...this.allItems.splice(0, this.current_index + 10)
              );
              this.current_index = this.current_index + 10;
            }, 2000);
          } else {
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          }
        }
      };
    },
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    openArtWork(item) {
      this.$router.push({
        name: "ViewArtWork",
        params: { id: item.artwork_id, name: $urlCompatible(item.title_eng) },
      });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTWORKS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allItems = res.data;
          this.allResults = res.data;
          this.changePage(1);
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    filterNow() {
      let results1 = [...this.allItems];
      let results2 = [];
      if (this.filterData.title_eng != "") {
        results2 = results1.filter((item) => {
          return (
            item.title_eng
              .toLowerCase()
              .includes(this.filterData.title_eng.toLowerCase()) ||
            item.title_kor
              .toLowerCase()
              .includes(this.filterData.title_eng.toLowerCase())
          );
        });
      } else {
        results2 = results1;
      }

      let results3 = [];
      if (this.filterData.creation_year != "") {
        results3 = results2.filter((item) => {
          return item.creation_year
            .toLowerCase()
            .includes(this.filterData.creation_year.toLowerCase());
        });
      } else {
        results3 = results2;
      }
      let results4 = [];
      if (this.filterData.category_eng != "") {
        results4 = results3.filter((item) => {
          return (
            item.category_eng
              .toLowerCase()
              .includes(this.filterData.category_eng.toLowerCase()) ||
            item.category_kor
              .toLowerCase()
              .includes(this.filterData.category_eng.toLowerCase())
          );
        });
      } else {
        results4 = results3;
      }

      let results5 = [];
      if (this.filterData.materials_eng.length > 0) {
        results4.forEach((item) => {
          this.filterData.materials_eng.forEach((material) => {
            if (item.materials_eng == material) {
              results5.push(item);
            }
          });
        });
      } else {
        results5 = results4;
      }

      let results6 = [];
      if (this.filterData.mediums_eng.length > 0) {
        results5.forEach((item) => {
          this.filterData.mediums_eng.forEach((medium) => {
            if (item.mediums_eng == medium) {
              results6.push(item);
            }
          });
        });
      } else {
        results6 = results5;
      }

      let results7 = [];
      if (this.filterData.mediums_techniques_eng != "") {
        results7 = results6.filter((item) => {
          return (
            item.mediums_techniques_eng
              .toLowerCase()
              .includes(this.filterData.mediums_techniques_eng.toLowerCase()) ||
            item.mediums_techniques_kor
              .toLowerCase()
              .includes(this.filterData.mediums_techniques_eng.toLowerCase())
          );
        });
      } else {
        results7 = results6;
      }

      let results8 = [];
      if (this.filterData.genres_eng != "") {
        results8 = results7.filter((item) => {
          return (
            item.genres_eng
              .toLowerCase()
              .includes(this.filterData.genres_eng.toLowerCase()) ||
            item.genres_kor
              .toLowerCase()
              .includes(this.filterData.genres_eng.toLowerCase())
          );
        });
      } else {
        results8 = results7;
      }

      let results9 = [];
      if (this.filterData.signiture_location_eng != "") {
        results9 = results8.filter((item) => {
          return (
            item.signiture_location_eng
              .toLowerCase()
              .includes(this.filterData.signiture_location_eng.toLowerCase()) ||
            item.signiture_location_kor
              .toLowerCase()
              .includes(this.filterData.signiture_location_eng.toLowerCase())
          );
        });
      } else {
        results9 = results8;
      }

      let results10 = [];
      if (this.filterData.artwork_location_eng != "") {
        results10 = results9.filter((item) => {
          return (
            item.artwork_location_eng
              .toLowerCase()
              .includes(this.filterData.artwork_location_eng.toLowerCase()) ||
            item.artwork_location_kor
              .toLowerCase()
              .includes(this.filterData.artwork_location_eng.toLowerCase())
          );
        });
      } else {
        results10 = results9;
      }

      let results11 = [];
      if (this.filterData.artist_name_eng != "") {
        results11 = results10.filter((item) => {
          if (item.is_art_artist == 1) {
            return (
              item.artist.artist_name
                .toLowerCase()
                .includes(this.filterData.artist_name_eng.toLowerCase()) ||
              item.artist.artist_name_kor
                .toLowerCase()
                .includes(this.filterData.artist_name_eng.toLowerCase())
            );
          } else {
            return (
              item.artist_name_eng
                .toLowerCase()
                .includes(this.filterData.artist_name_eng.toLowerCase()) ||
              item.artist_name_kor
                .toLowerCase()
                .includes(this.filterData.artist_name_eng.toLowerCase())
            );
          }
        });
      } else {
        results11 = results10;
      }

      let results12 = [];
      if (this.filterData.sale_price != "") {
        results12 = results11.filter((item) => {
          if (item.sale_price_currency == "USD") {
            if (this.filterData.sale_price == "Under $500") {
              return (
                item.sale_price < this.$exchange(500, "USD") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "$500 - $1000") {
              return (
                item.sale_price >= this.$exchange(500, "USD") &&
                item.sale_price <= this.$exchange(1000, "USD") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "$1000 - $2000") {
              return (
                item.sale_price >= this.$exchange(1000, "USD") &&
                item.sale_price <= this.$exchange(2000, "USD") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "$2000 - $5000") {
              return (
                item.sale_price >= this.$exchange(2000, "USD") &&
                item.sale_price <= this.$exchange(5000, "USD") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "$5000 - $10000") {
              return (
                item.sale_price >= this.$exchange(5000, "USD") &&
                item.sale_price <= this.$exchange(10000, "USD") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "Over $10000") {
              return (
                item.sale_price > this.$exchange(10000, "USD") &&
                item.hammer_price == ""
              );
            }
          }

          if (item.sale_price_currency == "KRW") {
            if (this.filterData.sale_price == "Under $500") {
              return (
                item.sale_price < this.$exchange(500, "KRW") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "$500 - $1000") {
              return (
                item.sale_price >= this.$exchange(500, "KRW") &&
                item.sale_price <= this.$exchange(1000, "KRW") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "$1000 - $2000") {
              return (
                item.sale_price >= this.$exchange(1000, "KRW") &&
                item.sale_price <= this.$exchange(2000, "KRW") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "$2000 - $5000") {
              return (
                item.sale_price >= this.$exchange(2000, "KRW") &&
                item.sale_price <= this.$exchange(5000, "KRW") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "$5000 - $10000") {
              return (
                item.sale_price >= this.$exchange(5000, "KRW") &&
                item.sale_price <= this.$exchange(10000, "KRW") &&
                item.hammer_price == ""
              );
            }
            if (this.filterData.sale_price == "Over $10000") {
              return (
                item.sale_price > this.$exchange(10000, "KRW") &&
                item.hammer_price == ""
              );
            }
          }
        });
      } else {
        results12 = results11;
      }

      let results13 = [];
      if (this.filterData.hammer_price != "") {
        results13 = results12.filter((item) => {
          if (item.hammer_price_currency == "USD") {
            if (this.filterData.hammer_price == "Under $500") {
              return item.hammer_price < this.$exchange(500, "USD");
            }
            if (this.filterData.hammer_price == "$500 - $1000") {
              return (
                item.hammer_price >= this.$exchange(500, "USD") &&
                item.hammer_price <= this.$exchange(1000, "USD") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "$1000 - $2000") {
              return (
                item.hammer_price >= this.$exchange(1000, "USD") &&
                item.hammer_price <= this.$exchange(2000, "USD") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "$2000 - $5000") {
              return (
                item.hammer_price >= this.$exchange(2000, "USD") &&
                item.hammer_price <= this.$exchange(5000, "USD") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "$5000 - $10000") {
              return (
                item.hammer_price >= this.$exchange(5000, "USD") &&
                item.hammer_price <= this.$exchange(10000, "USD") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "Over $10000") {
              return (
                item.hammer_price > this.$exchange(10000, "USD") &&
                item.sale_price == ""
              );
            }
          }

          if (item.hammer_price_currency == "KRW") {
            if (this.filterData.hammer_price == "Under $500") {
              return (
                item.hammer_price < this.$exchange(500, "KRW") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "$500 - $1000") {
              return (
                item.hammer_price >= this.$exchange(500, "KRW") &&
                item.hammer_price <= this.$exchange(1000, "KRW") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "$1000 - $2000") {
              return (
                item.hammer_price >= this.$exchange(1000, "KRW") &&
                item.hammer_price <= this.$exchange(2000, "KRW") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "$2000 - $5000") {
              return (
                item.hammer_price >= this.$exchange(2000, "KRW") &&
                item.hammer_price <= this.$exchange(5000, "KRW") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "$5000 - $10000") {
              return (
                item.hammer_price >= this.$exchange(5000, "KRW") &&
                item.hammer_price <= this.$exchange(10000, "KRW") &&
                item.sale_price == ""
              );
            }
            if (this.filterData.hammer_price == "Over $10000") {
              return (
                item.hammer_price > this.$exchange(10000, "KRW") &&
                item.sale_price == ""
              );
            }
          }
        });
      } else {
        results13 = results12;
      }

      this.allResults = results13;

      this.currentPage = 1;
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.items = this.$changePage(this.allResults, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getItems();
    // this.current_index = 10;
    // this.scroll();
  },
  watch: {
    filterData: {
      handler: function (val) {
        this.filterNow();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    position: relative;
    display: flex;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .page-body {
      width: calc(100% - 300px);
      padding-top: 2rem;
      height: calc(100vh - 200px);
      overflow-y: auto;
      padding-right: 1rem;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 250px);
      }
      @media screen and (max-width: 850px) {
        width: calc(100%);
        padding-top: 0rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: #b6b6b6;
        }
      }
    }
  }
  .model-content {
    @media screen and (max-width: 850px) {
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .model-head {
      @media screen and (max-width: 1100px) {
        padding: 0 1rem;
      }
      @media screen and (max-width: 850px) {
        padding: 0 1rem;
      }
      label {
        font-size: 1.5rem;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        color: #333333d2;
        font-weight: 600;
        @media screen and (max-width: 1100px) {
          font-size: 1.25rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 1rem;
        }
      }
      p {
        color: #333;
        font-weight: 500;
        font-size: 0.9rem;
        margin-bottom: 1rem;
        @media screen and (max-width: 1100px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 0.75rem;
        }
      }
    }
    .model-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @media screen and (max-width: 850px) {
        padding-bottom: 10rem;
      }
      .img-widget {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
        max-height: 50vh;
        @media screen and (max-width: 850px) {
          display: block;
          height: auto;
          width: 100vw;
          max-height: fit-content;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          margin-bottom: 2rem;
          @media screen and (max-width: 850px) {
            height: auto;
            overflow-y: auto;
            overflow-x: auto;
          }
        }
      }
      .profile-widget {
        display: flex;
        justify-content: space-between;
        margin: 2rem 1rem;
        @media screen and (max-width: 850px) {
          flex-direction: column;
          margin: 1rem 0.5rem;
        }
        .profile-img {
          display: flex;
          align-items: center;
          @media screen and (max-width: 850px) {
            // margin: 2rem 0;
          }
          img.user {
            height: 80px;
            width: 80px;
            object-fit: cover;
            border: 5px $blue solid;
            border-radius: 50%;
            @media screen and (max-width: 1100px) {
              height: 70px;
              width: 70px;
              border: 4px $blue solid;
            }
            @media screen and (max-width: 850px) {
              height: 50px;
              width: 50px;
              border: 2px $blue solid;
            }
          }
          .profile-desc {
            margin: 0 2rem;
            @media screen and (max-width: 850px) {
              margin: 0 1rem;
            }
            label {
              font-size: 1.5rem;
              font-weight: 700;
              text-transform: uppercase;
              color: black;
              @media screen and (max-width: 1100px) {
                font-size: 1.25rem;
                font-weight: 700;
              }
              @media screen and (max-width: 850px) {
                font-size: 1rem;
                font-weight: 600;
              }
            }
            p {
              display: flex;
              align-items: center;
              column-gap: 10px;
              color: #333;
              @media screen and (max-width: 1100px) {
                font-size: 0.9rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.7rem;
                img {
                  width: 15px;
                }
              }
            }
          }
        }
        .purchase {
          button {
            padding: 1rem 3rem;
            background: $blue;
            color: white;
            font-size: 1.35rem;
            border-radius: 10px;
            @media screen and (max-width: 1100px) {
              margin: 1rem 0;
              padding: 0.7rem 2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 850px) {
              padding: 0.5rem 2rem;
              font-size: 1rem;
              width: 100%;
              border-radius: 3px;
            }
          }
        }
      }
    }
    .model-footer {
      position: fixed;
      background: white;
      padding: 1rem;
      display: flex;
      align-items: center;
      bottom: 0;
      left: 0;
      width: 100%;
      justify-content: space-between;
      box-shadow: 1px 0px 10px 5px #00000038;
      button {
        padding: 0.5rem 1rem;
        background: $blue;
        color: white;
        font-size: 0.9rem;
        border-radius: 3px;
        width: 45%;
      }
    }
  }
}
</style>