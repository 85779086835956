import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const ADD_AUCTIONHOUSE_URL = API_URL + '/auctionhouses/createAuctionHouse';
const GET_AUCTIONHOUSES_URL = API_URL + '/auctionhouses/all';
const GET_AUCTIONHOUSE_BYID_URL = API_URL + '/auctionhouses/';
const UPDATE_AUCTIONHOUSE_URL = API_URL + '/auctionhouses/updateAuctionHouse';
const DELETE_AUCTIONHOUSE_URL = API_URL + '/auctionhouses/deleteAuctionHouse';


const auctionhouses = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_AUCTIONHOUSE']: ({ commit }, payload) => {
            return axios({
                url: ADD_AUCTIONHOUSE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_AUCTIONHOUSES']: ({ commit }, payload) => {
            return axios({
                url: GET_AUCTIONHOUSES_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_AUCTIONHOUSE_BYID']: ({ commit }, payload) => {
            return axios({
                url: GET_AUCTIONHOUSE_BYID_URL + payload.id,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['UPDATE_AUCTIONHOUSE']: ({ commit }, payload) => {
            return axios({
                url: UPDATE_AUCTIONHOUSE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_AUCTIONHOUSE']: ({ commit }, payload) => {
            return axios({
                url: DELETE_AUCTIONHOUSE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default auctionhouses;