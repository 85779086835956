<template>
  <div class="Login">
    <div class="page-content2">
      <div class="left">
        <div class="left-content">
          <router-link :to="{ name: 'Home' }" class="logo">
            <img
              :src="$img('logobst.png')"
              alt="Art Ocean Logo"
              @error="$imageurlalt()"
            />
          </router-link>
          <div class="form-container">
            <form>
              <div class="form-header">
                <h4 class="form-title">{{ $txt(`Sign in`) }}</h4>
                <div class="google">
                  <button>
                    <img :src="$img(`google.png`)" alt="" />
                    <p>{{ $txt(`Continue with Google`) }}</p>
                  </button>
                </div>
              </div>
              <div class="form-body">
                <div class="form-group group-1">
                  <div class="input-group">
                    <label for="email">{{ $txt(`Email`) }}</label>
                    <input type="text" />
                  </div>
                  <div class="input-group">
                    <label for="password" class="password"
                      >{{ $txt(`Password`) }}
                      <span
                        v-if="viewPass == 'text'"
                        @click="viewPass = 'password'"
                        ><i class="fa-regular fa-eye-slash"></i>
                        {{ $txt(`Hide`) }}</span
                      ><span
                        v-if="viewPass == 'password'"
                        @click="viewPass = 'text'"
                        ><i class="fa-regular fa-eye"></i>
                        {{ $txt(`Show`) }}</span
                      ></label
                    >
                    <input :type="viewPass" />
                  </div>
                  <div class="forgot">
                    <route-link :to="{ name: `Login` }">{{
                      $txt(`Forgot your password?`)
                    }}</route-link>
                  </div>
                  <div class="button">
                    <input type="submit" :value="$txt(`Sign In`)" />
                  </div>
                </div>
              </div>
              <div class="form-bottom">
                <p>
                  Don't have an account?
                  <router-link :to="{ name: `Signup` }">Sign up</router-link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      viewPass: "password",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Login {
  .page-content2 {
    display: grid;
    grid-template-columns: 50% 50%;
    @media screen and (max-width: 1500px) {
      grid-template-columns: 40% 60%;
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: 70% 30%;
    }
    @media screen and (max-width: 650px) {
      display: flex;
      flex-direction: column;
    }
    .left {
      display: flex;
      justify-content: center;
      .left-content {
        width: 80%;
        @media screen and (max-width: 1100px) {
          width: 90%;
        }
        .logo {
          width: 300px;
          margin: 3rem;
          display: block;
          @media screen and (max-width: 1500px) {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            width: 200px;
          }
          @media screen and (max-width: 1100px) {
            width: 200px;
          }
          img {
            width: 100%;
          }
        }
        .form-container {
          display: flex;
          justify-content: center;
          form {
            width: 80%;
            @media screen and (max-width: 1100px) {
              width: 90%;
            }
            .form-header {
              margin-bottom: 2rem;
              @media screen and (max-width: 1500px) {
                margin-bottom: 1rem;
              }
              .form-title {
                color: #333;
                font-size: 1.5rem;
                font-weight: 800;
                margin-bottom: 2rem;
                @media screen and (max-width: 1500px) {
                  font-size: 1.25rem;
                  margin-bottom: 1rem;
                }
                @media screen and (max-width: 1100px) {
                  font-size: 1.25rem;
                  margin-bottom: 0.75rem;
                }
              }
              .google {
                button {
                  display: flex;
                  justify-content: center;
                  width: 100%;
                  padding: 10px;
                  column-gap: 1rem;
                  border: 1px #000 solid;
                  border-radius: 1000px;
                  align-items: center;
                  @media screen and (max-width: 1500px) {
                    padding: 5px 10px;
                  }
                  &:hover {
                    background: #eee;
                  }
                  img {
                    width: 30px;
                    color: #333;
                    font-size: 1rem;
                    @media screen and (max-width: 1500px) {
                      width: 25px;
                    }
                    @media screen and (max-width: 1100px) {
                      width: 25px;
                    }
                  }
                  p {
                    font-size: 1.1rem;
                    @media screen and (max-width: 1500px) {
                      font-size: 1rem;
                    }
                    @media screen and (max-width: 1100px) {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }
            .forgot {
              display: flex;
              width: 100%;
              justify-content: flex-end;
              color: $blue;
              text-decoration: underline;
              margin-top: -1rem;
              @media screen and (max-width: 1500px) {
                font-size: 0.9rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 0.8rem;
              }
            }
            .button {
              padding: 1rem 4rem;
              background: $blue;
              color: white;
              border-radius: 10px;
              display: inline-block;
              margin-top: 1rem;
              @media screen and (max-width: 1500px) {
                padding: 0.5rem 3rem;
              }
              @media screen and (max-width: 1100px) {
                padding: 0.5rem 3rem;
              }
            }
            .form-bottom {
              margin: 2rem 0;
              @media screen and (max-width: 1500px) {
                margin: 1rem 0;
              }
              @media screen and (max-width: 1100px) {
                padding: 1.25;
              }
              p {
                @media screen and (max-width: 1500px) {
                  font-size: 0.8rem;
                }
                a {
                  color: $blue;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    .right {
      background-image: url("@/assets/images/art1.png");
      background-size: cover;
      height: 100%;
      min-height: 100vh;
      width: 100%;
      top: 0;
      right: 0;
      @media screen and (max-width: 650px) {
        height: 200px;
      }
    }
  }
}
</style>