<template>
  <div class="Home" v-scroll="handleScroll">
    <site-navbar />
    <div class="page-content">
      <div class="page-body">
        <div class="artists-container">
          <div class="artists-header">
            <h3>Artists</h3>
            <p>
              Embark on a worldwide journey through diverse artistic
              expressions.
            </p>
          </div>
          <div class="on-phone-search on-sm">
            <div class="search">
              <div class="form">
                <i class="fa-solid fa-search"></i>
                <input type="text" placeholder="Artist Name" />
              </div>
              <div class="filter">
                <i class="fa-solid fa-filter"></i>
                <p>Filter</p>
              </div>
            </div>
          </div>
          <div class="artists-items-list">
            <router-link
              :to="{
                name: 'ViewArtist',
                params: {
                  id: artist.artist_id,
                  name: $urlCompatible(artist.artist_name),
                },
              }"
              class="artist-item"
              v-for="artist in artists"
              :key="artist.artist_id"
            >
              <artist-card :artist="artist" />
            </router-link>
          </div>
          <div class="loader" v-if="isLoading">
            <img :src="$img('loader.png')" alt="" class="load-img" />
          </div>
        </div>

        <pagination
          class="pagination"
          :totalItems="allResults.length"
          :itemsPerPage="itemsPerPage"
          :itemCount="false"
          :position="'center'"
          @pageChanged="
            (page) => {
              changePage(page);
            }
          "
        />
      </div>
      <artists-filter
        class="on-lg my-filters"
        @filterData="
          (data) => {
            filterData = data;
          }
        "
        :nationalities="nationalities"
        :activityAreas="activityAreas"
      />
    </div>
    <site-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      viewPass: "password",
      hooperSettings: {
        itemsToShow: 1.3,
        itemsToScroll: 1,
        wrapAround: true,
        snapAlign: "center",
        transition: 300,
        autoplay: true,
        touchDrag: true,
      },
      allArtists: [],
      allResults: [],
      artists: [],
      current_index: 0,
      isLoading: false,
      viewPopup: false,
      selectedItem: null,
      categories: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      selectedCategory: "All",
      filters: {
        subjects: false,
        styles: false,
        orientation: false,
        materials: false,
        sizes: false,
        prices: false,
        countries: false,
      },
      filterData: {
        genre: "",
        nationality: "",
        gender: "",
        activityAreas: "",
        Exhibition: "",
        awards: "",
        selectedCategory: "All",
        artist_name: "",
      },
      nationalities: [],
      activityAreas: [],
      currentPage: 1,
      itemsPerPage: 21,
    };
  },
  methods: {
    scroll() {
      if (
        this.filterData ==
        {
          genre: "",
          nationality: "",
          gender: "",
          activityAreas: "",
          Exhibition: "",
          awards: "",
          selectedCategory: "All",
        }
      ) {
        window.onscroll = () => {
          let bottomOfWindow =
            Math.max(
              window.pageYOffset,
              document.documentElement.scrollTop,
              document.body.scrollTop
            ) +
              window.innerHeight >=
            document.documentElement.offsetHeight - 600;

          if (bottomOfWindow) {
            if (this.allArtists.length != 0) {
              setTimeout(() => {
                const allArtists = [...this.allArtists];
                this.artists.push(
                  ...allArtists.splice(0, this.current_index + 10)
                );
                this.current_index = this.current_index + 10;
              }, 2000);
            } else {
              setTimeout(() => {
                console.log("done");
                this.isLoading = false;
              }, 2000);
            }
          }
        };
      } else {
        this.isLoading = false;
      }
    },
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    openArtWork(item) {
      this.$router.push({
        name: "ViewArtWork",
        params: { id: item.id, name: item.name },
      });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTISTS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allArtists = res.data;
          this.allResults = res.data;
          // const allArtists = [...this.allArtists];
          // this.artists.push(...allArtists.splice(0, 10));
          // this.current_index = 10;
          this.changePage(1);
          this.getNationalities();
          if (this.$route.query.artist) {
            this.filterData.artist_name = decodeURIComponent(
              this.$route.query.artist
            );
            this.filterNow();
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    getNationalities() {
      const nationalities = [];
      const activityAreas = [];
      this.allArtists.forEach((artist) => {
        if (
          !nationalities.includes(artist.artist_nationality_eng) &&
          artist.artist_nationality_eng != ""
        )
          nationalities.push(artist.artist_nationality_eng);

        if (
          !activityAreas.includes(artist.artist_activity_area_eng) &&
          artist.artist_activity_area_eng != ""
        )
          activityAreas.push(artist.artist_activity_area_eng);
      });

      this.nationalities = nationalities;
      this.activityAreas = activityAreas;
    },
    filterNow() {
      let results1 = [...this.allArtists];
      let results2 = [];
      if (this.filterData.genre != "") {
        results2 = results1.filter((item) => {
          return item.artist_genre_eng
            .toLowerCase()
            .includes(this.filterData.genre.toLowerCase());
        });
      } else {
        results2 = results1;
      }

      let results3 = [];
      if (this.filterData.nationality != "") {
        results3 = results2.filter((item) => {
          return item.artist_nationality_eng
            .toLowerCase()
            .includes(this.filterData.nationality.toLowerCase());
        });
      } else {
        results3 = results2;
      }

      let results4 = [];
      // gender
      if (this.filterData.gender != "") {
        results4 = results3.filter((item) => {
          return item.artist_gender_eng
            .toLowerCase()
            .includes(this.filterData.gender.toLowerCase());
        });
      } else {
        results4 = results3;
      }

      let results5 = [];
      // activity area
      if (this.filterData.activityAreas != "") {
        results5 = results4.filter((item) => {
          return item.artist_activity_area_eng
            .toLowerCase()
            .includes(this.filterData.activityArea.toLowerCase());
        });
      } else {
        results5 = results4;
      }

      let results6 = [];
      // exhibition
      if (this.filterData.Exhibition != "") {
        results6 = results5.filter((item) => {
          return (
            item.artist_exhibitions_eng
              .toLowerCase()
              .includes(this.filterData.Exhibition.toLowerCase()) ||
            item.artist_exhibitions_kor
              .toLowerCase()
              .includes(this.filterData.Exhibition.toLowerCase()) ||
            item.artist_solo_exhibition_eng
              .toLowerCase()
              .includes(this.filterData.Exhibition.toLowerCase()) ||
            item.artist_solo_exhibition_kor
              .toLowerCase()
              .includes(this.filterData.Exhibition.toLowerCase()) ||
            item.artist_group_exhibition_eng
              .toLowerCase()
              .includes(this.filterData.Exhibition.toLowerCase()) ||
            item.artist_group_exhibition_kor
              .toLowerCase()
              .includes(this.filterData.Exhibition.toLowerCase())
          );
        });
      } else {
        results6 = results5;
      }

      let results7 = [];
      // awards
      if (this.filterData.awards != "") {
        results7 = results6.filter((item) => {
          return (
            item.artist_awards_eng
              .toLowerCase()
              .includes(this.filterData.awards.toLowerCase()) ||
            item.artist_awards_kor
              .toLowerCase()
              .includes(this.filterData.awards.toLowerCase())
          );
        });
      } else {
        results7 = results6;
      }

      let results8 = [];
      // category
      if (this.filterData.selectedCategory != "All") {
        results8 = results7.filter((item) => {
          return (
            item.artist_name
              .toLowerCase()
              .startsWith(this.filterData.selectedCategory.toLowerCase()) ||
            item.artist_name_kor
              .toLowerCase()
              .startsWith(this.filterData.selectedCategory.toLowerCase())
          );
        });
      } else {
        results8 = results7;
      }

      // artist name
      let results9 = [];
      if (this.filterData.artist_name != "") {
        results9 = results8.filter((item) => {
          return (
            item.artist_name
              .toLowerCase()
              .includes(this.filterData.artist_name.toLowerCase()) ||
            item.artist_name_kor
              .toLowerCase()
              .includes(this.filterData.artist_name.toLowerCase())
          );
        });
      } else {
        results9 = results8;
      }

      this.allResults = results9;

      this.currentPage = 1;
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.artists = this.$changePage(this.allResults, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getItems();
  },
  watch: {
    filterData: {
      handler: function (val) {
        this.filterNow();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    display: flex;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .page-body {
      width: calc(100% - 300px);
      padding-top: 2rem;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 250px);
      }
      @media screen and (max-width: 850px) {
        width: 100%;
        padding-top: 0rem;
      }
      .artists-container {
        .artists-header {
          h3 {
            color: #000000;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
          p {
            color: #333;
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            @media screen and (max-width: 1500px) {
              margin-top: 0.2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
        .on-phone-search {
          display: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          column-gap: 10px;
          @media screen and (max-width: 850px) {
            padding: 1rem;
            padding-left: 0px;
          }
          .search {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .form {
              input {
                width: 100%;
                padding: 0.5rem 1rem;
                border: 1px #adadad solid;
                border-radius: 5px;
                font-size: 1.25rem;
                padding-left: 2.5rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
              i {
                color: #adadad;
                font-size: 1.25rem;
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);

                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .filter {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 5px;
            background: $blue;
            color: #fff;
            padding: 10px 1.5rem;
            border-radius: 500px;
            position: relative;
            i {
              color: #fff;
              position: relative;
              font-size: 1.25rem;
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>