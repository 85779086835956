<template>
  <div class="Home">
    <site-navbar />
    <div class="page-content">
      <div class="item-widget">
        <div
          class="profile"
          :style="{
            'background-image': `url('${$uploads(item.gallery_image)}')`,
          }"
        >
          <div class="profile-img">
            <img
              :src="$uploads(item.gallery_image)"
              alt=""
              @error="$imageurlalt()"
            />
          </div>
        </div>
        <div class="profile-info">
          <h3>{{ item.gallery_name_eng }}</h3>
          <h5>{{ item.address_eng }}</h5>
        </div>
      </div>
      <div class="item-description">
        <div class="description-tab-navs">
          <ul>
            <li
              @click="activeTab = 'about'"
              :class="activeTab == 'about' ? 'active' : ''"
            >
              About
            </li>
            <!-- <li
              @click="activeTab = 'events'"
              :class="activeTab == 'events' ? 'active' : ''"
            >
              Events
            </li>
            <li
              @click="activeTab = 'artists'"
              :class="activeTab == 'artists' ? 'active' : ''"
            >
              Artists
            </li>
            <li
              @click="activeTab = 'artworks'"
              :class="activeTab == 'artworks' ? 'active' : ''"
            >
              Artworks
            </li> -->
          </ul>
        </div>
        <div class="description-tab-content">
          <div class="tab-content about" v-if="activeTab == 'about'">
            <ol>
              <li>Gallery Name:</li>
              <p>{{ item.gallery_name_eng }} - {{ item.gallery_name_kor }}</p>
              <li
                v-if="
                  (item.about_the_gallery_eng &&
                    item.about_the_gallery_eng != '') ||
                  (item.about_the_gallery_kor &&
                    item.about_the_gallery_kor != '')
                "
              >
                About the gallery:
              </li>
              <p v-html="item.about_the_gallery_eng"></p>
              <p v-html="item.about_the_gallery_kor"></p>
              <li>Gallery viewing time, closed days</li>
              <p>{{ item.gallery_viewing_time }}</p>
              <p>{{ item.closed_days_eng }}</p>
            </ol>
            <div
              class="about-bottom"
              v-if="item.social_link && item.social_link != ''"
            >
              <h4 class="web">On the web</h4>
              <!-- <div class="socials">
                <i class="fa-solid fa-globe"></i>
                <i class="fa-brands fa-instagram"></i>
                <i class="fa-brands fa-facebook"></i>
                <i class="fa-brands fa-x-twitter"></i>
              </div> -->
              <p>{{ item.social_link }}</p>
            </div>
          </div>
          <div class="tab-content" v-if="activeTab == 'artworks'">
            <div class="images-tab-content">
              <div class="images-tab-body">
                <div class="artworks-images-container">
                  <div class="image-list">
                    <div
                      class="image-item"
                      v-for="(item, i) in images"
                      :key="i"
                      :class="item.size"
                      @click="openArtWork(item)"
                    >
                      <artwork-card :item="item" />
                    </div>
                  </div>
                </div>
              </div>
              <artworks-filter />
            </div>
          </div>
          <div class="tab-content" v-if="activeTab == 'artists'">
            <div class="item-tab-content">
              <div class="item-tab-body">
                <div class="items-container">
                  <div class="on-phone-search on-sm">
                    <div class="search">
                      <div class="form">
                        <i class="fa-solid fa-search"></i>
                        <input type="text" placeholder="item Name" />
                      </div>
                      <div class="filter">
                        <i class="fa-solid fa-filter"></i>
                        <p>Filter</p>
                      </div>
                    </div>
                  </div>
                  <div class="items-items-list">
                    <router-link
                      :to="{
                        name: 'Viewitem',
                        params: {
                          id: item.id,
                          name: $urlCompatible(item.name),
                        },
                      }"
                      class="item-item"
                      v-for="item in items"
                      :key="item.id"
                    >
                      <item-card :item="item" />
                    </router-link>
                  </div>
                </div>
              </div>
              <items-filter />
            </div>
          </div>
          <div class="tab-content" v-if="activeTab == 'events'">
            <div class="event-list">
              <router-link
                :to="{ name: 'Home' }"
                class="event-item"
                v-for="event in events"
                :key="event.id"
              >
                <div class="image left">
                  <img :src="event.image" alt="" @error="$imageurlalt()" />
                </div>
                <div class="content right">
                  <div class="title">
                    <h3>{{ event.name }}</h3>
                  </div>
                  <div class="date">
                    <label>Date: </label>
                    <p>{{ event.date }}</p>
                  </div>
                  <div class="time">
                    <label>Time: </label>
                    <p>{{ event.timeRange }}</p>
                  </div>
                  <div class="location">
                    <label>Location: </label>
                    <p>{{ event.location }}</p>
                  </div>
                  <div class="description">
                    <p>{{ event.description }}</p>
                  </div>
                  <div class="price">
                    <label v-if="event.price != 'Free'">Price: </label>
                    <p>{{ $price(event.ticketPrice) }}</p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="page-body">
        <div class="articles-container" v-if="activeTab != 'events'">
          <div class="articles-header">
            <h3>FEATURED ARTICLES</h3>
          </div>
          <div class="articles-items-list">
            <div
              class="article-item"
              v-for="article in articles"
              :key="article.id"
            >
              <div class="img">
                <img :src="article.image" alt="" @error="$imageurlalt()" />
              </div>
              <div class="content">
                <div class="title">
                  <h3>{{ article.title }}</h3>
                </div>
                <div class="author">
                  <h5>{{ article.author.name }}</h5>
                  <p>{{ article.date }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="related-events-container" v-if="activeTab == 'events'">
          <div class="articles-header">
            <h3>PAST EVENTS</h3>
          </div>
          <div class="event-list">
            <router-link
              :to="{ name: 'Home' }"
              class="event-item"
              v-for="event in events"
              :key="event.id"
            >
              <div class="image left">
                <img :src="event.image" alt="" @error="$imageurlalt()" />
              </div>
              <div class="content right">
                <div class="title">
                  <h3>{{ event.name }}</h3>
                </div>
                <div class="date">
                  <label>Date: </label>
                  <p>{{ event.date }}</p>
                </div>
                <div class="time">
                  <label>Time: </label>
                  <p>{{ event.timeRange }}</p>
                </div>
                <div class="location">
                  <label>Location: </label>
                  <p>{{ event.location }}</p>
                </div>
                <div class="description">
                  <p>{{ event.description }}</p>
                </div>
                <div class="price">
                  <label v-if="event.price != 'Free'">Price: </label>
                  <p>{{ $price(event.ticketPrice) }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
    <site-footer />
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      viewPass: "password",
      hooperSettings: {
        itemsToShow: 1.3,
        itemsToScroll: 1,
        wrapAround: true,
        snapAlign: "center",
        transition: 300,
        autoplay: true,
        touchDrag: true,
      },
      allItems: [
        {
          id: 1,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/300/300",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/600/600",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/600/600",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/300/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/400/400",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/400/400",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/500/500",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/500/500",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/100/100",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/100/100",
              },
            },
          ],
        },
        {
          id: 2,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/200/200",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/300/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/100/100",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/400/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/500/400",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/100/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
          ],
        },
        {
          id: 3,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/200/300",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/700/700",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/100/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/500/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/300/500",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/600/500",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
          ],
        },
        {
          id: 4,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/200/300",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/300/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/100/100",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/400/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/500/400",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/100/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/200/300",
              },
            },
          ],
        },
        {
          id: 5,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/200/300",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/300/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/100/100",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/400/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/500/400",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/100/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
          ],
        },
        {
          id: 6,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/200/300",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/300/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/100/100",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/400/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/500/400",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/100/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
          ],
        },
        {
          id: 7,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/200/300",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/300/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/100/100",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/400/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/500/400",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/100/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
          ],
        },
        {
          id: 8,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/200/300",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/300/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/100/100",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/400/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/500/400",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/100/200",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
          ],
        },
        {
          id: 9,
          name: "John Doe",
          category: ["Photography", "Drawings"],
          image: "https://picsum.photos/300/300",
          location: "Rwanda",
          art: [
            {
              id: 1,
              name: "Art 1",
              image: "https://picsum.photos/600/600",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/600/600",
              },
            },
            {
              id: 2,
              name: "Art 2",
              image: "https://picsum.photos/300/300",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/300/300",
              },
            },
            {
              id: 3,
              name: "Art 3",
              image: "https://picsum.photos/400/400",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/400/400",
              },
            },
            {
              id: 4,
              name: "Art 4",
              image: "https://picsum.photos/500/500",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/500/500",
              },
            },
            {
              id: 5,
              name: "Art 5",
              image: "https://picsum.photos/100/100",
              price: 1000,
              owner: {
                name: "John Doe",
                image: "https://picsum.photos/100/100",
              },
            },
          ],
        },
      ],
      items: [],
      item: {
        id: 1,
        name: "Indabo Gallery",
        category: ["Photography", "Drawings"],
        image: "https://picsum.photos/300/300",
        cover: "cover1.png",
        location: "Kigali, Rwanda",
        art: [
          {
            id: 1,
            name: "Art 1",
            image: "https://picsum.photos/600/600",
            price: 1000,
            owner: {
              name: "John Doe",
              image: "https://picsum.photos/600/600",
            },
          },
          {
            id: 2,
            name: "Art 2",
            image: "https://picsum.photos/300/300",
            price: 1000,
            owner: {
              name: "John Doe",
              image: "https://picsum.photos/300/300",
            },
          },
          {
            id: 3,
            name: "Art 3",
            image: "https://picsum.photos/400/400",
            price: 1000,
            owner: {
              name: "John Doe",
              image: "https://picsum.photos/400/400",
            },
          },
          {
            id: 4,
            name: "Art 4",
            image: "https://picsum.photos/500/500",
            price: 1000,
            owner: {
              name: "John Doe",
              image: "https://picsum.photos/500/500",
            },
          },
          {
            id: 5,
            name: "Art 5",
            image: "https://picsum.photos/100/100",
            price: 1000,
            owner: {
              name: "John Doe",
              image: "https://picsum.photos/100/100",
            },
          },
        ],
      },
      articles: [
        {
          id: 1,
          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          image: "https://picsum.photos/600/600",
          date: "2021-09-01",
          author: {
            name: "John Doe",
            image: "https://picsum.photos/600/600",
          },
        },
        {
          id: 2,
          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          image: "https://picsum.photos/300/300",
          date: "2021-09-01",
          author: {
            name: "John Doe",
            image: "https://picsum.photos/300/300",
          },
        },
        {
          id: 3,
          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          image: "https://picsum.photos/400/400",
          date: "2021-09-01",
          author: {
            name: "John Doe",
            image: "https://picsum.photos/400/400",
          },
        },
        {
          id: 4,
          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          image: "https://picsum.photos/400/400",
          date: "2021-09-01",
          author: {
            name: "John Doe",
            image: "https://picsum.photos/400/400",
          },
        },
      ],
      current_index: 0,
      isLoading: true,
      viewPopup: false,
      selectedItem: null,

      categories: [
        {
          id: 1,
          name: "Photography",
        },
        {
          id: 2,
          name: "Drawings",
        },
        {
          id: 3,
          name: "Mixed Media",
        },
        {
          id: 4,
          name: "Sculpture",
        },
        {
          id: 5,
          name: "Digital",
        },
        {
          id: 6,
          name: "Installation",
        },
        {
          id: 7,
          name: "Collage",
        },
        {
          id: 8,
          name: "Printmaking",
        },
      ],
      itemcategories: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      selectedCategory: "All",
      filters: {
        subjects: false,
        styles: false,
        orientation: false,
        materials: false,
        sizes: false,
        prices: false,
        countries: false,
      },
      activeTab: "about",
      images: [
        {
          id: 1,
          image: "/arts/1.jpg",
          price: 3000,
          name: "Monalisa",
          dimensions: "200 W * 160 H * 2 D cm",
          description:
            "This is a beautiful artwork that captures the essence of nature with vibrant colors and intricate details. The item skillfully brings to life a mesmerizing scene that invites viewers to explore the wonders of the natural world.",
          item: {
            name: "item Name 1",
            location: "Korea",
            image: "/items/prof1.png",
          },
        },
        {
          id: 4,
          image: "/arts/4.webp",

          name: "Monalisa",
          price: 4000,
          description:
            "This digital creation showcases the item's innovative approach to art. The use of webp format enhances the visual experience, adding a modern touch to the piece. The intricate details and textures draw the viewer into a world of digital exploration.",
          item: {
            name: "item Name 4",
            location: "Korea",
            image: "/items/prof2.jpg",
          },
        },
        {
          id: 5,
          image: "/arts/5.jpg",

          name: "Monalisa",
          price: 4000,
          description:
            "A serene landscape that captures the beauty of a tranquil moment. The soft hues and delicate brushstrokes convey a sense of peace, inviting viewers to immerse themselves in the serenity of the natural world.",
          item: {
            name: "item Name 5",
            location: "Korea",
            image: "/items/prof3.jpg",
          },
        },
        {
          id: 6,
          image: "/arts/6.jpg",

          name: "Monalisa",
          price: 4000,
          description:
            "A thought-provoking piece that explores the complexities of the human experience. The item uses symbolism and metaphor to convey deep emotions and invite contemplation on the intricate tapestry of life.",
          item: {
            name: "item Name 6",
            location: "Korea",
            image: "/items/prof4.jpg",
          },
        },
        {
          id: 7,
          image: "/arts/7.webp",

          name: "Monalisa",
          price: 4000,
          description:
            "This avant-garde creation pushes the boundaries of traditional art. The item experiments with digital media and webp format to create a visually stunning and dynamic piece that challenges preconceived notions of itemic expression.",
          item: {
            name: "item Name 7",
            location: "Korea",
            image: "/items/prof5.jpg",
          },
        },
        {
          id: 8,
          image: "/arts/8.jpg",

          name: "Monalisa",
          price: 4000,
          description:
            "A captivating portrait that captures the essence of the subject with remarkable detail. The item skillfully uses light and shadow to evoke a sense of depth and personality, creating a connection between the viewer and the portrayed individual.",
          item: {
            name: "item Name 8",
            location: "Korea",
            image: "/items/prof6.jpg",
          },
        },
        {
          id: 9,
          image: "/arts/9.jpg",

          name: "Monalisa",
          price: 4000,
          description:
            "An energetic and dynamic composition that reflects the item's passion for movement and rhythm. The vibrant colors and bold strokes convey a sense of vitality, inviting viewers to experience the excitement and energy of the depicted scene.",
          item: {
            name: "item Name 9",
            location: "Korea",
            image: "/items/prof7.jpg",
          },
        },
        {
          id: 10,
          image: "/arts/10.jpg",

          name: "Monalisa",
          price: 4000,
          description:
            "A surreal and dreamlike artwork that transports the viewer to a fantastical realm. The item employs imaginative elements and symbolic imagery to create a narrative that unfolds in the viewer's imagination, sparking a sense of wonder and curiosity.",
          item: {
            name: "item Name 10",
            location: "Korea",
            image: "/items/prof8.jpg",
          },
        },
      ],
      events: [
        // name, date, description, location, days, nOfitems, ticketPrice, timeRange, image
        {
          id: 1,
          name: "Art Exhibition",
          date: "2021-09-01",
          description:
            "This is a beautiful artwork that captures the essence of nature with vibrant colors and intricate details. The item skillfully brings to life a mesmerizing scene that invites viewers to explore the wonders of the natural world.",
          location: "Kigali, Rwanda",
          days: 3,
          nOfitems: 10,
          ticketPrice: 1000,
          timeRange: "10:00 AM - 6:00 PM",
          image: "https://picsum.photos/600/600",
        },
        {
          id: 2,
          name: "Art Exhibition",
          date: "2021-09-01",
          description:
            "This is a beautiful artwork that captures the essence of nature with vibrant colors and intricate details. The item skillfully brings to life a mesmerizing scene that invites viewers to explore the wonders of the natural world.",
          location: "Kigali, Rwanda",
          days: 3,
          nOfitems: 10,
          ticketPrice: 1000,
          timeRange: "10:00 AM - 6:00 PM",
          image: "https://picsum.photos/600/500",
        },
        {
          id: 3,
          name: "Art Exhibition",
          date: "2021-09-01",
          description:
            "This is a beautiful artwork that captures the essence of nature with vibrant colors and intricate details. The item skillfully brings to life a mesmerizing scene that invites viewers to explore the wonders of the natural world.",
          location: "Kigali, Rwanda",
          days: 3,
          nOfitems: 10,
          ticketPrice: 1000,
          timeRange: "10:00 AM - 6:00 PM",
          image: "https://picsum.photos/600/400",
        },
        {
          id: 4,
          name: "Art Exhibition",
          date: "2021-09-01",
          description:
            "This is a beautiful artwork that captures the essence of nature with vibrant colors and intricate details. The item skillfully brings to life a mesmerizing scene that invites viewers to explore the wonders of the natural world.",
          location: "Kigali, Rwanda",
          days: 3,
          nOfitems: 10,
          ticketPrice: 1000,
          timeRange: "10:00 AM - 6:00 PM",
          image: "https://picsum.photos/600/300",
        },
      ],
    };
  },
  methods: {
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    openArtWork(item) {
      this.$router.push({
        name: "ViewArtWork",
        params: { id: item.id, name: item.name },
      });
    },
    getSingleItem() {
      this.$startLoader();
      this.$store
        .dispatch("GET_GALLERY_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.item = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.getSingleItem();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    display: flex;
    flex-direction: column;
    padding: 0rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 0rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 0rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 0rem 1rem;
    }
    .item-widget {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
      @media screen and (max-width: 850px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .profile {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        position: relative;
        @media screen and (max-width: 850px) {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        .profile-img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0px 0px 10px 10px #00000042;
          position: absolute;
          bottom: -50px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .profile-info {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3 {
          color: #000000;
          font-size: 2rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 1.5rem;
          @media screen and (max-width: 1100px) {
            font-size: 27px;
          }
          @media screen and (max-width: 850px) {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 1rem;
          }
        }
        h5 {
          color: #969696;
          font-size: 1.1rem;
          font-weight: 300;
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.8rem;
          }
        }
      }
    }
    .item-description {
      border-top: 1px #0000005d solid;
      width: 100%;
      padding-top: 2rem;
      margin-bottom: 5rem;
    }
    .page-body {
      width: 100%;
      @media screen and (max-width: 850px) {
        width: 100%;
      }
      .articles-container {
        .articles-header {
          h3 {
            color: #000000;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 1rem;
            @media screen and (max-width: 1500px) {
              font-size: 1rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
          p {
            color: #333;
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            @media screen and (max-width: 1100px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

.images-tab-content {
  display: flex;
}
.event-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding-top: 2rem;
  row-gap: 3rem;
  @media screen and (max-width: 1500px) {
    row-gap: 2rem;
  }
  .event-item {
    width: 100%;
    display: flex;
    box-shadow: 0px 0px 10px 3px #00000028;
    @media screen and (max-width: 850px) {
      flex-direction: column;
    }
    .left {
      width: 50%;
      height: 100%;
      background: #000;
      max-height: 300px;
      @media screen and (max-width: 1500px) {
        max-height: 250px;
      }
      @media screen and (max-width: 850px) {
        width: 100%;
        max-height: 150px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      width: 50%;
      padding: 1rem;
      @media screen and (max-width: 850px) {
        width: 100%;
      }
      .title {
        margin-bottom: 1rem;
        h3 {
          font-size: 1.75rem;
          font-weight: 500;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 1.35rem;
          }
        }
        &::after {
          content: "";
          display: block;
          width: 50px;
          height: 5px;
          background: $blue;
          margin-bottom: 1rem;
          border-radius: 100px;
          @media screen and (max-width: 1500px) {
            width: 35px;
            height: 3px;
          }
        }
      }
      label {
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
        }
      }
      .date,
      .location,
      .time {
        margin-right: 1rem;
        display: flex;
        column-gap: 0.5rem;
        @media screen and (max-width: 1500px) {
          column-gap: 0.25rem;
        }
        p {
          font-size: 1rem;
          font-weight: 300;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
      }
      .price {
        margin-top: 2rem;
        display: flex;
        column-gap: 0.5rem;
        font-size: 1.5rem;
        @media screen and (max-width: 1500px) {
          margin-top: 1rem;
          font-size: 1.25rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 1.25rem;
          margin-top: 1rem;
        }
        label {
          @media screen and (max-width: 1500px) {
            font-size: 1.25rem;
          }
        }
        p {
          font-size: 1.5rem;
          font-weight: 500;
          color: $blue;
          @media screen and (max-width: 1500px) {
            font-size: 1.25rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 1.25rem;
          }
        }
      }
      .description {
        margin-top: 1rem;
        @media screen and (max-width: 1500px) {
          margin-top: 0.5rem;
        }
        p {
          font-size: 1rem;
          font-weight: 300;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

.related-events-container {
  .event-list {
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 850px) {
      grid-template-columns: 1fr;
    }
  }
}
.about {
  ol {
    list-style: decimal;
    padding-left: 1rem;
    margin-top: 2rem;
    li {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 1rem;
      @media screen and (max-width: 1100px) {
        font-size: 1rem;
      }
      @media screen and (max-width: 850px) {
        font-size: 1rem;
      }
    }
  }
}
</style>