import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// plugs
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/css/tailwind.css';
import VueMultiselect from 'vue-multiselect'
import Notifications from '@kyvg/vue3-notification'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// mixin
import CanvasJSChart from '@canvasjs/vue-charts';
import Mixin from './mixins/mixin.vue'
import VueApexCharts from "vue3-apexcharts";
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
// components
import SiteNavbar from './views/components/SiteNavbar.vue'
import SiteFooter from './views/components/SiteFooter.vue'
import ChangeLang from './views/components/ChangeLang.vue'
import loggedUser from './views/components/loggedUser.vue'
import Model from './views/components/Model.vue'
import confirmModel from './views/components/confirmModel.vue'
import OUpload from './components/OUpload.vue'
import Pagination from './components/Pagination.vue'
import ArtworksFilter from './views/site/components/Filters/ArtworksFilter.vue'
import ArtistsFilter from './views/site/components/Filters/ArtistsFilter.vue'
import GalleryFilter from './views/site/components/Filters/GalleryFilter.vue'
import ExhibitFilter from './views/site/components/Filters/ExhibitFilter.vue'
import AuctionHouseFilter from './views/site/components/Filters/AuctionHouseFilter.vue'
import AuctionFilter from './views/site/components/Filters/AuctionFilter.vue'
import ArticleFilter from './views/site/components/Filters/ArticleFilter.vue'
import ArtWorkCard from './views/site/components/ArtWorkCard.vue'
import ArtistCard from './views/site/components/ArtistCard.vue'
import GalleryCard from './views/site/components/GalleryCard.vue'
import ExhibitCard from './views/site/components/ExhibitCard.vue'
import AuctionHouseCard from './views/site/components/AuctionHouseCard.vue'
import AuctionCard from './views/site/components/AuctionCard.vue'
import ArticleCard from './views/site/components/ArticleCard.vue'

import myCheckbox from './components/myCheckbox.vue'

const vue = createApp(App)
vue.component('site-navbar', SiteNavbar)
vue.component('site-footer', SiteFooter)
vue.component('change-lang', ChangeLang)
vue.component('logged-user', loggedUser)
vue.component('VueMultiselect', VueMultiselect)
vue.component('model', Model)
vue.component('confirm-model', confirmModel)
vue.component('o-upload', OUpload)
vue.component('pagination', Pagination)
vue.component('artworks-filter', ArtworksFilter)
vue.component('artists-filter', ArtistsFilter)
vue.component('gallery-filter', GalleryFilter)
vue.component('exhibit-filter', ExhibitFilter)
vue.component('auction-house-filter', AuctionHouseFilter)
vue.component('auction-filter', AuctionFilter)
vue.component('article-filter', ArticleFilter)
vue.component('artwork-card', ArtWorkCard)
vue.component('artist-card', ArtistCard)
vue.component('gallery-card', GalleryCard)
vue.component('exhibit-card', ExhibitCard)
vue.component('auction-house-card', AuctionHouseCard)
vue.component('auction-card', AuctionCard)
vue.component('article-card', ArticleCard)


vue.component('my-checkbox', myCheckbox)
vue.component('quill-editor', QuillEditor)

vue.use(Notifications)
vue.use(CanvasJSChart)
vue.use(VueApexCharts)
vue.use(VCalendar, {});

vue.use(VueAxios, axios)
vue.mixin(Mixin)
vue.use(store)
vue.use(router)
vue.mount('#app')