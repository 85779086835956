<template>
  <div class="filters on-lg">
    <div class="filter-header">
      <h3 class="header">Filters</h3>
      <div class="sorts">
        <i class="fa-solid fa-search"></i>
        <input
          type="text"
          placeholder="Event Name"
          v-model="filterData.exhibition_name_eng"
        />
      </div>
    </div>
    <div class="filter-categories">
      <div
        class="category-item"
        :class="filterData.selectedCategory == 'All' ? 'active' : ''"
        @click="filterData.selectedCategory = 'All'"
      >
        All
      </div>
      <div
        class="category-item"
        v-for="category in categories"
        :key="category.id"
        :class="filterData.selectedCategory == category ? 'active' : ''"
        @click="filterData.selectedCategory = category"
      >
        {{ category }}
      </div>
    </div>
    <div class="filter-expands">
      <div class="expand-item">
        <div class="top" @click="filters.gallery = !filters.gallery">
          <i class="fa-solid fa-plus" v-if="!filters.gallery"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>GALLERY</p>
        </div>
        <div class="bottom" :class="filters.gallery ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by Gallery"
            v-model="filterData.exhibition_gallery_name_eng"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.artists = !filters.artists">
          <i class="fa-solid fa-plus" v-if="!filters.artists"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>ARTISTS</p>
        </div>
        <div class="bottom" :class="filters.artists ? 'active' : ''">
          <input
            type="text"
            placeholder="Participating artists"
            v-model="filterData.exhibition_artists_eng"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.date = !filters.date">
          <i class="fa-solid fa-plus" v-if="!filters.date"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>START DATE</p>
        </div>
        <div class="bottom" :class="filters.date ? 'active' : ''">
          <input
            type="date"
            placeholder="Search by date"
            v-model="filterData.startDate"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.location = !filters.location">
          <i class="fa-solid fa-plus" v-if="!filters.location"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>LOCATION</p>
        </div>
        <div class="bottom" :class="filters.location ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by location"
            v-model="filterData.exhibition_location_eng"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {
        location: false,
        artists: false,
        date: false,  
        gallery: false,
      },
      categories: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      filterData: {
        exhibition_name_eng: "",
        exhibition_location_eng: "",
        exhibition_artists_eng: "",
        startDate: "",
        exhibition_gallery_name_eng: "",
        selectedCategory: "All",
      },
    };
  },
  methods: {},
  watch: {
    filterData: {
      handler() {
        this.$emit("filterData", this.filterData);
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>