<template>
  <div class="dashboard">
    <div class="page-top-section">
      <div class="left">
        <h2>ARTICLES</h2>
        <div class="page-indication">
          <p>Articles</p>
          <i class="fa-solid fa-caret-right"></i>
          <p>Articles List</p>
          <i class="fa-solid fa-caret-right"></i>
          <p class="active">Add Article</p>
        </div>
      </div>
      <div class="right">
        <div class="export-btn">
          <router-link :to="{ name: 'AdminArticles' }" class="btn">
            <i class="fa-solid fa-times"></i>
            <span>Cancel</span>
          </router-link>
        </div>
        <div class="add-btn">
          <button class="btn" @click="saveItem()" v-if="actionType == 'Add'">
            <i class="fa-solid fa-plus"></i>
            <span>Save</span>
          </button>
          <button class="btn" @click="updateItem()" v-else>
            <i class="fa-solid fa-save"></i>
            <span>Update</span>
          </button>
        </div>
      </div>
    </div>
    <div class="page-content-section">
      <div class="left">
        <div class="form">
          <div class="form-body">
            <div iv class="form-group">
              <div class="form-header">
                <h3>General Information</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artwork-name">Article Name (ENG)</label>
                  <input
                    type="text"
                    id="artwork-name"
                    placeholder="Type Article Name (ENG) here. . ."
                    v-model="newItem.article_name_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article Name (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="기사 이름"
                    v-model="newItem.article_name_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article Source (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Article Source (ENG)"
                    v-model="newItem.article_source_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article Source (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="기사 출처"
                    v-model="newItem.article_source_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article Writer (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Article Writer (ENG)"
                    v-model="newItem.article_writer_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article Writer (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="기사 작성자"
                    v-model="newItem.article_writer_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article Date (Global)</label>
                  <input
                    type="date"
                    id="artwork-location"
                    placeholder="Article Date (Global)"
                    v-model="newItem.article_date"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article URL (Global)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Article URL (Global)"
                    v-model="newItem.article_url"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article Text (ENG)</label>
                  <quill-editor
                    toolbar="minimal"
                    placeholder="Article Text (ENG)"
                    v-model:content="newItem.article_text_eng"
                    contentType="html"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Article Text (KOR)</label>
                  <quill-editor
                    toolbar="minimal"
                    contentType="html"
                    placeholder="Article Text (KOR)"
                    v-model:content="newItem.article_text_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Crawling Channel</label>
                  <input
                    type="text"
                    placeholder="Crawling Channel"
                    v-model="newItem.crawling_channel"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-header">
                <h3>Article thumbnail Image</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artist-name">Photo</label>
                  <o-upload
                    :multiple="false"
                    :uploadUrl="'files/upload'"
                    @imagesUploaded="
                      (data) => {
                        newItem.article_image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.article_image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-header">
                <h3>Article Images</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artist-name">Photo</label>
                  <o-upload
                    :multiple="true"
                    :uploadUrl="'files/upload'"
                    @imagesUploaded="
                      (data) => {
                        newItem.article_images_initial = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.article_images_initial"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allartWorks: [
        {
          id: 1,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "red",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 2,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "pink",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 3,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "yellow",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 4,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Green",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 5,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Orange",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
      ],
      artWorks: [],
      checkedItems: [],
      artists: [
        {
          id: 1,
          name: "Leo Da Vinci",
        },
        {
          id: 2,
          name: "Leo Da Vinci",
        },
        {
          id: 3,
          name: "Leo Da Vinci",
        },
        {
          id: 4,
          name: "Leo Da Vinci",
        },
        {
          id: 5,
          name: "Leo Da Vinci",
        },
        {
          id: 6,
          name: "Leo Da Vinci",
        },
        {
          id: 7,
          name: "Leo Da Vinci",
        },
        {
          id: 8,
          name: "Leo Da Vinci",
        },
        {
          id: 9,
          name: "Leo Da Vinci",
        },
        {
          id: 10,
          name: "Leo Da Vinci",
        },
        {
          id: 11,
          name: "Leo Da Vinci",
        },
        {
          id: 12,
          name: "Leo Da Vinci",
        },
        {
          id: 13,
          name: "Leo Da Vinci",
        },
        {
          id: 14,
          name: "Leo Da Vinci",
        },
        {
          id: 15,
          name: "Leo Da Vinci",
        },
        {
          id: 16,
          name: "Leo Da Vinci",
        },
        {
          id: 17,
          name: "Leo Da Vinci",
        },
        {
          id: 18,
          name: "Leo Da Vinci",
        },
        {
          id: 19,
          name: "Leo Da Vinci",
        },
        {
          id: 20,
          name: "Leo Da Vinci",
        },
        {
          id: 21,
          name: "Leo Da Vinci",
        },
        {
          id: 22,
          name: "Leo Da Vinci",
        },
        {
          id: 23,
          name: "Leo Da Vinci",
        },
        {
          id: 24,
          name: "Leo Da Vinci",
        },
        {
          id: 25,
          name: "Leo Da Vinci",
        },
        {
          id: 26,
          name: "Leo Da Vinci",
        },
        {
          id: 27,
          name: "Leo Da Vinci",
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      newItem: {
        article_name_eng: "",
        article_name_kor: "",
        article_source_eng: "",
        article_source_kor: "",
        article_writer_eng: "",
        article_writer_kor: "",
        article_date: "",
        article_url: "",
        article_text_eng: "",
        article_text_kor: "",
        article_image: "",
        crawling_channel: "",
        article_images: "",
        article_images_initial: "",
      },

      actionType: "Add",
    };
  },
  methods: {
    checkedContains(id) {
      return this.checkedItems.includes(id);
    },
    checkNow(id) {
      if (this.checkedItems.includes(id)) {
        this.checkedItems = this.checkedItems.filter((item) => item !== id);
      } else {
        this.checkedItems.push(id);
      }
    },
    checkAllNow() {
      this.checkedItems = [];
      this.artWorks.forEach((artwork) => {
        this.checkedItems.push(artwork.id);
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.artWorks = this.$changePage(
        this.allartWorks,
        page,
        this.itemsPerPage
      );
      window.scrollTo(0, 0);
    },
    saveItem() {
      this.$startLoader();
      this.newItem.article_images = JSON.stringify([
        ...this.newItem.article_images_initial,
      ]);
      this.$store
        .dispatch("ADD_ARTICLE", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminArticles" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_ARTICLE", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminArticles" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },

    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTICLE_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.newItem = res.data;
          this.newItem.article_images_initial = JSON.parse(
            this.newItem.article_images
          );
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.actionType = this.$route.params.actionType;
    if (this.actionType == "Edit") {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/dashboard.scss";
</style>