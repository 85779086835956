<template>
  <div class="image left">
    <img :src="$uploads(item.auction_image)" alt="" @error="$imageurlalt()" />
  </div>
  <div class="content right">
    <div class="title">
      <h3>{{ item.auction_name_eng }}</h3>
    </div>
    <div class="date">
      <label>Date: </label>
      <p>{{ item.auction_date }}</p>
    </div>
    <!-- <div class="date">
      <label>Time: </label>
      <p>{{ item.auction_time }}</p>
    </div>
    <div class="date">
      <label>Location: </label>
      <p>{{ item.auction_location }}</p>
    </div> -->
    <div class="time">
      <label>Lots: </label>
      <p>{{ item.slots }}</p>
    </div>
    <div class="location">
      <label>Website: </label>
      <p>{{ item.auction_website }}</p>
    </div>
    <div class="location">
      <label>Auction House: </label>
      <p v-if="item.is_art_auction_house">
        {{ item.auction_house.auction_house_name_eng }}
      </p>
      <p v-else>{{ item.auction_house_name_eng }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>