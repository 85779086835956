<template>
  <div class="Home" v-scroll="handleScroll">
    <site-navbar />
    <div class="page-content" v-if="artist">
      <div class="artist-widget">
        <div
          class="profile"
          :style="{
            'background-image': `url('${$uploads(artist.artist_image)}')`,
          }"
        >
          <div class="profile-img">
            <img
              :src="$uploads(artist.artist_image)"
              alt=""
              @error="$imageurlalt()"
            />
          </div>
        </div>
        <div class="profile-info">
          <h3>{{ artist.name }}</h3>
          <h5>{{ artist.location }}</h5>
        </div>
      </div>
      <div class="artist-description">
        <div class="description-tab-navs">
          <ul>
            <li
              @click="activeTab = 'about'"
              :class="activeTab == 'about' ? 'active' : ''"
            >
              About
            </li>
            <li
              @click="activeTab = 'artworks'"
              :class="activeTab == 'artworks' ? 'active' : ''"
              v-if="artist.artworks.length > 0"
            >
              Artworks
            </li>
          </ul>
        </div>
        <div class="description-tab-content">
          <div class="tab-content about" v-if="activeTab == 'about'">
            <ol>
              <li
                v-if="
                  artist.artist_description_eng != ''
                "
              >
                Introduction:
              </li>
              <p v-html="artist.artist_description_eng"></p>
              <li v-if="artist.artist_educational_background_eng != ''">
                Early Years and Education:
              </li>
              <p v-html="artist.artist_educational_background_eng"></p>
              <li v-if="artist.artist_awards_eng != ''">
                Awards and recognition:
              </li>
              <p v-html="artist.artist_awards_eng"></p>
              <li
                v-if="
                  artist.artist_exhibitions_eng != '' ||
                  artist.artist_group_exhibition_eng != '' ||
                  artist.artist_solo_exhibition_eng != ''
                "
              >
                Exhibition Highlights
              </li>
              <p v-html="artist.artist_exhibitions_eng"></p>
              <p v-html="artist.artist_group_exhibition_eng"></p>
              <p v-html="artist.artist_solo_exhibition_eng"></p>
              <!-- <li>Artistic residencies</li>
              <p>
                Participation in [Artistic Residencies] enriching
                [his/her/their] perspective.
              </p> -->
              <li v-if="artist.artist_belonging_gallery_eng != ''">
                Gallery Affiliation
              </li>
              <p>
                {{ artist.artist_belonging_gallery_eng }}
              </p>
            </ol>
          </div>
          <div class="tab-content" v-if="activeTab == 'artworks'">
            <div class="images-tab-content">
              <div class="images-tab-body">
                <div class="artworks-images-container">
                  <div class="image-list">
                    <div
                      class="image-item"
                      v-for="(item, i) in artist.artworks"
                      :key="i"
                      @click="openArtWork(item)"
                    >
                      <artwork-card :item="item" />
                    </div>
                  </div>
                </div>
              </div>
              <artworks-filter />
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="artists-container">
          <div class="artists-header">
            <h3>Related Artists</h3>
          </div>
          <div class="artists-items-list">
            <router-link
              :to="{
                name: 'ViewArtist',
                params: {
                  id: artist.artist_id,
                  name: $urlCompatible(artist.artist_name),
                },
              }"
              class="artist-item"
              v-for="artist in artist.related"
              :key="artist.id"
            >
              <artist-card :artist="artist" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <site-footer />
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      allArtists: [],
      artists: [],
      artist: null,
      current_index: 0,
      isLoading: true,
      viewPopup: false,
      selectedItem: null,
      activeTab: "about",
    };
  },
  methods: {
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    openArtWork(item) {
      this.$router.push({
        name: "ViewArtWork",
        params: { id: item.id, name: item.name },
      });
    },
    getSingleItem() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTIST_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.artist = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTISTS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allArtists = res.data;
          this.artists.push(...this.allArtists.splice(0, 4));
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.getItems();
    this.getSingleItem();
  },
  watch: {
    $route(to, from) {
      this.getSingleItem();
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    display: flex;
    flex-direction: column;
    padding: 0rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 0rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 0rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 0rem 1rem;
    }
    .artist-widget {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
      @media screen and (max-width: 850px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .profile {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        position: relative;
        @media screen and (max-width: 1500px) {
          height: 120px;
        }
        @media screen and (max-width: 850px) {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        .profile-img {
          width: 170px;
          height: 170px;
          border-radius: 50%;
          border: 7px $blue solid;
          overflow: hidden;
          box-shadow: 0px 0px 10px 10px #00000042;
          position: absolute;
          bottom: -80px;
          @media screen and (max-width: 1500px) {
            border: 4px $blue solid;
            width: 120px;
            height: 120px;
            bottom: -60px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .profile-info {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1500px) {
          margin-top: 50px;
        }
        h3 {
          color: #000000;
          font-size: 2rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 1.5rem;
          @media screen and (max-width: 1500px) {
            font-size: 27px;
          }
          @media screen and (max-width: 1100px) {
            font-size: 27px;
          }
          @media screen and (max-width: 850px) {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 1rem;
          }
        }
        h5 {
          color: #969696;
          font-size: 1.35rem;
          font-weight: 300;
          @media screen and (max-width: 1500px) {
            font-size: 0.9rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 1rem;
          }
        }
      }
    }
    .artist-description {
      border-top: 1px #0000005d solid;
      width: 100%;
      padding-top: 2rem;
    }
    .page-body {
      margin-top: 5rem;
      width: 100%;
      @media screen and (max-width: 850px) {
        width: 100%;
      }
      .artists-container {
        .artists-header {
          h3 {
            color: #000000;
            font-size: 2rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-bottom: 1rem;
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
          p {
            color: #333;
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            @media screen and (max-width: 1100px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

.images-tab-content {
  display: flex;
  .images-tab-body {
    width: calc(100% - 300px);
    padding-top: 2rem;
    @media screen and (max-width: 1500px) {
      width: calc(100% - 200px);
    }
    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }
}
.about {
  ol {
    list-style: decimal;
    padding-left: 1rem;
    margin-top: 2rem;
    li {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 1rem;
      @media screen and (max-width: 1100px) {
        font-size: 1rem;
      }
      @media screen and (max-width: 850px) {
        font-size: 1rem;
      }
    }
  }
}
</style>