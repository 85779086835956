import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const ADD_EXHIBITION_URL = API_URL + '/exhibitions/createExhibition';
const GET_EXHIBITIONS_URL = API_URL + '/exhibitions/all';
const GET_EXHIBITION_BYID_URL = API_URL + '/exhibitions/';
const UPDATE_EXHIBITION_URL = API_URL + '/exhibitions/updateExhibition';
const DELETE_EXHIBITION_URL = API_URL + '/exhibitions/deleteExhibition';


const exhibitions = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_EXHIBITION']: ({ commit }, payload) => {
            return axios({
                url: ADD_EXHIBITION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_EXHIBITIONS']: ({ commit }, payload) => {
            return axios({
                url: GET_EXHIBITIONS_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_EXHIBITION_BYID']: ({ commit }, payload) => {
            return axios({
                url: GET_EXHIBITION_BYID_URL + payload.id,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['UPDATE_EXHIBITION']: ({ commit }, payload) => {
            return axios({
                url: UPDATE_EXHIBITION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_EXHIBITION']: ({ commit }, payload) => {
            return axios({
                url: DELETE_EXHIBITION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default exhibitions;