<template>
  <div
    class="Home"
    v-scroll="handleScroll"
    v-if="selectedItem && selectedItem != null"
  >
    <site-navbar />
    <div class="slider-container">
      <div class="hooper">
        <div class="myslide">
          <div
            class="slide-img"
            :style="{
              backgroundImage:
                'url(' + $uploads(selectedItem.exhibition_image) + ')',
            }"
          >
            <div class="slide-content">
              <div class="left-bottom">
                <h3>{{ selectedItem.exhibition_name_eng }}</h3>
                <div class="description">
                  <p v-html="selectedItem.exhibition_description_eng"></p>
                </div>
                <div
                  class="item-date"
                  v-if="selectedItem.exhibition_gallery_name_eng"
                >
                  <h4>By {{ selectedItem.exhibition_gallery_name_eng }}</h4>
                </div>
              </div>
              <div
                class="featured"
                v-if="selectedItem.exhibition_gallery_name_eng"
              >
                {{ selectedItem.exhibition_gallery_name_eng }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="about-event">
        <div class="top">
          <!-- <div class="left">
            <div class="images">
              <img src="https://picsum.photos/400/400" alt="" />
              <img src="https://picsum.photos/400/500" alt="" />
            </div>
          </div> -->
          <div class="right">
            <div class="title">About Event</div>
            <div class="date">Date: {{ selectedItem.startDate }}</div>
            <p>
              {{ selectedItem.exhibition_description_eng }}
            </p>
            <p>
              {{ selectedItem.exhibition_description_kor }}
            </p>
            <div class="location">
              {{ selectedItem.exhibition_location_eng }}
            </div>
            <!-- <div class="nums">
              <div class="num-item">
                <h1>Schedule</h1>
                <h4>{{ selectedItem.exhibition_schedule_eng }}</h4>
              </div>
            </div> -->
            <div class="ticket">
              <!-- <label>Free</label> -->
              <button @click="$redirect(selectedItem.gallery_url)" target="_blank"
                >Visit exhibition website</button
              >
            </div>
          </div>

          <div class="bottom">
            <ol>
              <li>Participating Artists</li>
              <p>
                {{ selectedItem.exhibition_artists_eng }}
              </p>
              <p>
                {{ selectedItem.exhibition_artists_kor }}
              </p>
              <li>Exhibition Schedule</li>
              <p>
                {{ selectedItem.exhibition_schedule_eng }}
              </p>
              <p>
                {{ selectedItem.exhibition_schedule_kor }}
              </p>
              <li>Gallery Information</li>
              <p>{{ selectedItem.gallery_information_eng }}</p>
              <p>{{ selectedItem.gallery_information_kor }}</p>
              <li>Gallery viewing time, closed days</li>
              <p>{{ selectedItem.viewing_time_eng }}</p>
              <p>{{ selectedItem.viewing_time_kor }}</p>
            </ol>
          </div>
        </div>
      </div>
      <div class="populars-items-widget">
        <div class="popular-header">
          <h3>RELATED EVENTS</h3>
          <!-- search -->
          <!-- <div class="search">
            <input type="text" placeholder="Search here" />
            <i class="fas fa-search"></i>
          </div> -->
        </div>
        <div class="exhibit-list-items all">
          <router-link
            :to="{
              name: 'ViewExhibit',
              params: {
                id: article.exhibition_id,
                name: $urlCompatible(article.exhibition_name_eng),
              },
            }"
            class="item"
            v-for="article in [...items].splice(0, 4)"
            :key="article.exhibition_id"
          >
            <exhibit-card :item="article" />
          </router-link>
        </div>
      </div>
    </div>
    <site-footer />
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      isLoading: true,
      categories: [
        {
          id: 1,
          name: "Photography",
        },
        {
          id: 2,
          name: "Drawings",
        },
        {
          id: 3,
          name: "Mixed Media",
        },
        {
          id: 4,
          name: "Sculpture",
        },
        {
          id: 5,
          name: "Digital",
        },
        {
          id: 6,
          name: "Installation",
        },
        {
          id: 7,
          name: "Collage",
        },
        {
          id: 8,
          name: "Printmaking",
        },
      ],
      selectedCategory: "All",
      filters: {
        subjects: false,
        styles: false,
        orientation: false,
        materials: false,
        sizes: false,
        prices: false,
        countries: false,
      },
      items: [],
      AllItems: [
        {
          id: 1,
          article_image: "article2.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART MOVEMENT",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 2,
          article_image: "article1.jpeg",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART TUTORIALS",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 3,
          article_image: "article3.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "BUYING GUIDES",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 4,
          article_image: "article4.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART TUTORIALS",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 5,
          article_image: "article5.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "BUYING GUIDES",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 6,
          article_image: "article2.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART MOVEMENT",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 7,
          article_image: "article1.jpeg",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART TUTORIALS",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 8,
          article_image: "article3.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "BUYING GUIDES",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 9,
          article_image: "article4.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART TUTORIALS",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 10,
          article_image: "article5.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "BUYING GUIDES",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 11,
          article_image: "article1.jpeg",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART MOVEMENT",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
        {
          id: 12,
          article_image: "article4.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART MOVEMENT",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            '<p>Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces with premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance-based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidly monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just-in-time customer service without real-time scenarios.</p><p><br></p><p>Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimized enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidly generate backend experiences vis-a-vis long-term high-impact relationships.</p><p><br></p><p><em>Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.</em></p><p><br></p><h2><span style="color: rgb(0, 102, 204);">“ Art and Architecture: Architect\'s Name on the Intersection of Form and Function. ” </span></h2><p><br></p><p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>',
          article_featured: true,
        },
      ],
      slides: [
        {
          id: 1,
          article_image: "exb1.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART MOVEMENT",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            "Progressively incentivize cooperDive into 'The Art of Color': an exploration of vibrant masterpieces where artists harness the power of color to evoke emotion and captivate the senses.ative systems through technically sound functionalities. The credibly productivate seamless data.",
          article_featured: true,
        },
        {
          id: 1,
          article_image: "cover1.png",
          article_title: "ART FOR CHANGE EXHIBITION - KIGALI - RWANDA",
          article_tag: "ART MOVEMENT",
          article_date: "08.08.2023",
          article_author: "Indabo Gallery",
          article_content:
            "Progressively incentivize cooperDive into 'The Art of Color': an exploration of vibrant masterpieces where artists harness the power of color to evoke emotion and captivate the senses.ative systems through technically sound functionalities. The credibly productivate seamless data.",
          article_featured: true,
        },
      ],
      selectedItem: null,
    };
  },
  methods: {
    filteritems() {
      if (this.selectedCategory == "All") {
        this.items = this.AllItems;
      } else {
        this.items = this.AllItems.filter((article) => {
          return (
            article.article_tag.toLocaleLowerCase() ==
            this.selectedCategory.toLocaleLowerCase()
          );
        });
      }
    },
    getSingleItem() {
      this.$startLoader();
      this.$store
        .dispatch("GET_EXHIBITION_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.selectedItem = res.data;
          this.items = res.data.related;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.getSingleItem();
  },
  watch: {
    "$route.params.id": function (id) {
      this.getSingleItem();
      scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  background: #fcfcfc;
  .slider-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 180px;
    margin-bottom: 50px;
    @media screen and (max-width: 1500px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 850px) {
      // height: 300px;
    }
    .slide-img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .slide-content {
        min-height: 800px;
        background: rgba(0, 0, 0, 0.664);
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 1500px) {
          min-height: 350px;
        }
        .left-bottom {
          padding: 5rem;
          padding-bottom: 2rem;
          color: #fff;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          row-gap: 2rem;
          @media screen and (max-width: 1500px) {
          }
          h6 {
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            background: rgba(109, 109, 109, 0.397);
            padding: 0.5rem 1rem;
            border-radius: 500px;
            @media screen and (max-width: 1500px) {
              font-size: 0.7rem;
              padding: 0.2rem 0.5rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
          h3 {
            font-size: 2.7rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            width: 50%;
            @media screen and (max-width: 1500px) {
              width: 60%;
              font-size: 1.8rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1.5rem;
            }
          }
          .item-date {
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            margin-right: 1rem;
            margin-bottom: 0.7rem;
            color: #fff;
            @media screen and (max-width: 1500px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.8rem;
            }
          }
          .description {
            display: flex;
            align-items: flex-start;
            .date {
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              text-align: left;
              margin-right: 1rem;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
            hr {
              width: 40px;
              height: 2px;
              background: #fff;
              margin-top: 0.7rem;
              margin-right: 1rem;
            }
            p {
              font-size: 1.25rem;
              text-align: left;
              color: #ececec;
              width: 50%;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              @media screen and (max-width: 1500px) {
                font-size: 0.9rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 0.9rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
        .featured {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          padding: 0.2rem 1rem;
          color: #fff;
          background: rgba(255, 255, 255, 0.259);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          font-size: 1rem;
          border-radius: 500px;
          display: none;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  .populars-items-widget {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    .popular-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0rem;
      margin-bottom: 50px;
      @media screen and (max-width: 850px) {
        padding: 1rem;
        padding-left: 0px;
      }
      h3 {
        color: #000000;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        @media screen and (max-width: 1500px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 27px;
        }
        @media screen and (max-width: 850px) {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 1rem;
        }
      }
      .search {
        width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        input {
          width: 100%;
          padding: 0.5rem 1rem;
          border: 1px #adadad solid;
          border-radius: 5px;
          padding-left: 2.5rem;
          font-size: 1rem;
          outline: none;
          @media screen and (max-width: 1500px) {
            font-size: 0.9rem;
            padding: 0.25rem 0.5rem;
            padding-left: 1.8rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
        i {
          color: #adadad;
          font-size: 1.25rem;
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-50%);
          @media screen and (max-width: 1500px) {
            left: 0.5rem;
            font-size: 1rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .page-content {
    margin-top: 0;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .about-event {
      display: flex;
      flex-direction: column;
      .top {
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 2rem;
        @media screen and (max-width: 1100px) {
          grid-template-columns: 100%;
        }
        .left {
          .images {
            display: flex;
            flex-direction: column;
            height: 600px;
            position: relative;
            img {
              height: 300px;
              width: 50%;
              object-fit: cover;
              position: absolute;
              &:nth-child(1) {
                top: 2rem;
                height: 330px;
                width: 55%;
              }
              &:nth-child(2) {
                bottom: 4rem;
                right: 6rem;
                height: 330px;
                width: 55%;
              }
            }
          }
        }
        .right {
          .title {
            font-size: 2rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: left;
            color: #000000;
            margin-top: 0rem;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1.2rem;
            }
          }
          .date {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: left;
            color: $blue;
            margin-top: 0rem;
            @media screen and (max-width: 1500px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
          p {
            font-size: 1.15rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-align: left;
            color: #333;
            margin: 3rem 0;
            @media screen and (max-width: 1500px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.9rem;
            }
          }
          .location {
            font-size: 1.15rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            color: #000;
            margin-bottom: 1rem;
            @media screen and (max-width: 1500px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
          .nums {
            display: flex;
            column-gap: 4rem;
            margin: 2.5rem 0;
            .num-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: left;
              background: #fff;
              padding: 1rem 2rem;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
              width: 100%;
              border-radius: 5px;
              h1 {
                font-size: 2rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
                color: $blue;
                margin-top: 0rem;
                @media screen and (max-width: 1500px) {
                  font-size: 1.5rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 1.2rem;
                }
              }
              h4 {
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
                color: #333;
                margin-top: 0rem;
                @media screen and (max-width: 1500px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .ticket {
            width: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 0.5rem;
            // border: 1px #d6d6d6 solid;
            border-radius: 5px;
            label {
              width: 100%;
              text-align: center;
              font-size: 1.5rem;
              text-transform: uppercase;
            }
            button,
            a {
              display: block;
              padding: 0.5rem 1rem;
              background: $blue;
              color: #fff;
              border: none;
              border-radius: 5px;
              font-size: 1rem;
              // margin-bottom: 1rem;
              // margin: 0.5rem;
              text-align: center;
              cursor: pointer;
              &:hover {
                background: darken($blue, 10%);
              }
            }
          }
        }
      }
      .bottom {
        margin: 3rem 0;
        margin-bottom: 5rem;
        ol {
          list-style: decimal;
          padding-left: 1rem;
          margin-top: 2rem;
          li {
            margin-top: 2rem;
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 1rem;
            @media screen and (max-width: 1100px) {
              font-size: 1rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
</style>