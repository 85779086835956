import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const GET_CATEGORIES_URL = API_URL + '/categories';
const GET_MATERIALS_URL = API_URL + '/materials';
const GET_MEDIUMS_URL = API_URL + '/mediums';
const SEARCH_ITEMS_URL = API_URL + '/search';
const GET_DASHBOARD_URL = API_URL + '/dashboard';
const SAVE_IP_URL = API_URL + '/si';


const artists = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_CATEGORIES']: ({ commit }, payload) => {
            return axios({
                url: GET_CATEGORIES_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_MATERIALS']: ({ commit }, payload) => {
            return axios({
                url: GET_MATERIALS_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_MEDIUMS']: ({ commit }, payload) => {
            return axios({
                url: GET_MEDIUMS_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['SEARCH_ITEMS']: ({ commit }, payload) => {
            return axios({
                url: SEARCH_ITEMS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_DASHBOARD']: ({ commit }, payload) => {
            return axios({
                url: GET_DASHBOARD_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['SAVE_IP']: ({ commit }) => {
            return axios({
                url: SAVE_IP_URL,
                method: "GET",
            })
        },
    },
    modules: {},
}
export default artists;