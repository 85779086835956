<template>
  <div class="Home" v-scroll="handleScroll">
    <site-navbar />
    <div class="slider-container">
      <carousel
        :autoplay="5000"
        :wrap-around="true"
        :items-to-show="1"
        class="hooper"
      >
        <Slide
          v-for="myslide in slides"
          :key="myslide.article_id"
          class="myslide"
        >
          <div
            class="slide-img"
            :style="{
              backgroundImage: 'url(' + $uploads(myslide.article_image) + ')',
            }"
          >
            <div class="slide-content">
              <div class="left-bottom">
                <h6>{{ myslide.article_source_eng }}</h6>
                <h3>{{ myslide.article_name_eng }}</h3>
                <div class="description">
                  <div class="date">{{ myslide.article_date }}</div>
                  <hr />
                  <p v-html="myslide.article_text_eng"></p>
                </div>
                <div class="featured">New</div>
              </div>
            </div>
          </div>
        </Slide>
      </carousel>
    </div>
    <div class="page-content">
      <div class="d-grid">
        <div class="page-body">
          <div class="populars-items-widget">
            <div class="popular-header">
              <h3>Popular Topics</h3>
            </div>
            <!-- <div class="description-tab-navs">
              <ul>
                <li
                  class="tab-item"
                  @click="
                    selectedCategory = 'All';
                    filteritems();
                  "
                  :class="{ active: selectedCategory == 'All' }"
                >
                  All
                </li>
              </ul>
            </div> -->
            <div class="articles-items">
              <router-link
                :to="{
                  name: 'ViewArticle',
                  params: {
                    id: item.article_id,
                    name: $urlCompatible(item.article_name_eng),
                  },
                }"
                class="item"
                v-for="item in items"
                :key="item.id"
              >
                <article-card :item="item" />
              </router-link>
            </div>
          </div>
        </div>
        <article-filter
          class="on-lg"
          @filterData="
            (data) => {
              filterData = data;
            }
          "
        />
      </div>
      <pagination
        class="pagination"
        :totalItems="allResults.length"
        :itemsPerPage="itemsPerPage"
        :itemCount="false"
        @pageChanged="
          (page) => {
            changePage(page);
          }
        "
      />
    </div>
    <!-- <div class="art-ocean">
      <div class="title-header">
        <h3>FROM ART OCEAN</h3>
      </div>
      <div class="art-ocean-container">
        <div
          :autoplay="5000"
          :wrap-around="true"
          :items-to-show="1"
          class="hooper"
        >
          <div v-for="myslide in slides" :key="myslide.id" class="myslide">
            <div
              class="slide-img"
              :style="{
                backgroundImage: 'url(' + $img(myslide.article_image) + ')',
              }"
            >
              <div class="slide-content">
                <div class="left-bottom">
                  <div class="date">{{ myslide.article_date }}</div>
                  <h3>
                    The Art of Color: Exploring Vibrancy in Contemporary
                    Masterpieces
                  </h3>
                  <div class="description">
                    <p>
                      Progressively incentivize cooperative systems through
                      technically sound functionalities. The credibly
                      productivate seamless data.
                    </p>
                  </div>
                </div>
                <div class="featured">ART OCEAN</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <site-footer />
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1.3,
        itemsToScroll: 1,
        wrapAround: true,
        snapAlign: "center",
        transition: 300,
        autoplay: true,
        touchDrag: true,
      },
      allItems: [],
      items: [],
      current_index: 12,
      isLoading: false,
      items: [],
      allItems: [],
      slides: [],
      currentPage: 1,
      itemsPerPage: 21,
      filterData: {
        article_name_eng: "",
        article_writer_eng: "",
        article_date: "",
        selectedCategory: "All",
      },
      allResults: [],
    };
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >=
          document.documentElement.offsetHeight - 600;

        if (bottomOfWindow) {
          if (this.allItems.length != 0) {
            setTimeout(() => {
              this.items.push(
                ...this.allItems.splice(0, this.current_index + 10)
              );
              this.current_index = this.current_index + 10;
            }, 2000);
          } else {
            setTimeout(() => {
              console.log("done");
              this.isLoading = false;
            }, 2000);
          }
        }
      };
    },
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    filteritems() {
      if (this.selectedCategory == "All") {
        this.items = this.allItems;
      } else {
        this.items = this.allItems.filter((article) => {
          return (
            item.article_tag.toLocaleLowerCase() ==
            this.selectedCategory.toLocaleLowerCase()
          );
        });
      }
    },

    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTICLES", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allItems = res.data;
          this.allResults = res.data;
          const slides = [...res.data];
          this.slides = slides.splice(0, 3);
          this.changePage(1);
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.items = this.$changePage(this.allResults, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
    filterNow() {
      let results1 = [...this.allItems];
      let results2 = [];
      if (this.filterData.article_name_eng != "") {
        results2 = results1.filter((item) => {
          return (
            item.article_name_eng
              .toLowerCase()
              .includes(this.filterData.article_name_eng.toLowerCase()) ||
            item.article_name_kor
              .toLowerCase()
              .includes(this.filterData.article_name_eng.toLowerCase())
          );
        });
      } else {
        results2 = results1;
      }

      // article_writer_eng

      let results3 = [];
      if (this.filterData.article_writer_eng != "") {
        results3 = results2.filter((item) => {
          return (
            item.article_writer_eng
              .toLowerCase()
              .includes(this.filterData.article_writer_eng.toLowerCase()) ||
            item.article_writer_kor
              .toLowerCase()
              .includes(this.filterData.article_writer_eng.toLowerCase())
          );
        });
      } else {
        results3 = results2;
      }
      let results4 = [];
      if (this.filterData.selectedCategory != "All") {
        results4 = results3.filter((item) => {
          return item.article_name_eng
            .toLowerCase()
            .startsWith(this.filterData.selectedCategory.toLowerCase());
        });
      } else {
        results4 = results3;
      }

      let results5 = [];
      if (this.filterData.article_date != "") {
        results5 = results4.filter((item) => {
          return (
            item.article_date
              .toLowerCase()
              .includes(this.filterData.article_date.toLowerCase()) ||
            item.article_date
              .toLowerCase()
              .includes(this.filterData.article_date.toLowerCase())
          );
        });
      } else {
        results5 = results4;
      }


      this.allResults = results5;

      this.currentPage = 1;
      this.changePage(1);
    },
  },
  mounted() {
    this.getItems();
    // this.scroll();
  },
  watch: {
    filterData: {
      handler: function (val) {
        this.filterNow();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .slider-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 180px;
    margin-bottom: 50px;
    @media screen and (max-width: 1500px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 850px) {
      // height: 300px;
      margin-top: 60px;
    }
    .slide-img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .slide-content {
        min-height: 800px;
        background: rgba(0, 0, 0, 0.562);
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 1500px) {
          min-height: 500px;
        }
        @media screen and (max-width: 850px) {
          min-height: 400px;
        }
        .left-bottom {
          margin-top: 5%;
          padding: 5rem;
          padding-bottom: 2rem;
          color: #fff;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          row-gap: 2rem;
          @media screen and (max-width: 1500px) {
            margin-top: 5%;
          }
          @media screen and (max-width: 850px) {
            width: 100%;
            padding: 1rem;
            padding-bottom: 2rem;
          }
          h6 {
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            background: rgba(23, 177, 204, 0.592);
            padding: 0.5rem 1rem;
            border-radius: 500px;
            @media screen and (max-width: 1500px) {
              font-size: 0.8rem;
              padding: 0.2rem 0.5rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
          h3 {
            font-size: 2.7rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            width: 50%;
            @media screen and (max-width: 1500px) {
              width: 60%;
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1.5rem;
              width: 100%;
            }
          }
          .description {
            display: flex;
            align-items: flex-start;
            @media screen and (max-width: 850px) {
              flex-direction: column;
            }
            .date {
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              text-align: left;
              margin-right: 1rem;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
            hr {
              width: 40px;
              height: 2px;
              background: #fff;
              margin-top: 0.7rem;
              margin-right: 1rem;
              @media screen and (max-width: 850px) {
                margin: 0.5rem 0;
                background: #ffffff10;
              }
            }
            p {
              font-size: 1.25rem;
              text-align: left;
              color: #ececec;
              width: 50%;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
                width: 100%;
              }
            }
          }
        }
        .featured {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          padding: 0.2rem 1rem;
          color: #fff;
          background: rgba(255, 255, 255, 0.259);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          font-size: 1.2rem;
          border-radius: 500px;
        }
      }
    }
  }
  .populars-items-widget {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    .popular-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0rem;
      margin-bottom: 50px;
      @media screen and (max-width: 850px) {
        padding: 1rem;
        padding-left: 0px;
      }
      h3 {
        color: #000000;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        @media screen and (max-width: 1500px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 27px;
        }
        @media screen and (max-width: 850px) {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 1rem;
        }
      }
      .search {
        width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        input {
          width: 100%;
          padding: 0.5rem 1rem;
          border: 1px #adadad solid;
          border-radius: 5px;
          padding-left: 2.5rem;
          font-size: 1rem;
          outline: none;
          @media screen and (max-width: 1500px) {
            font-size: 0.9rem;
            padding: 0.25rem 0.5rem;
            padding-left: 1.8rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
        i {
          color: #adadad;
          font-size: 1.25rem;
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-50%);
          @media screen and (max-width: 1500px) {
            left: 0.5rem;
            font-size: 1rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .page-content {
    margin-top: 0;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .d-grid {
      display: flex;
      align-items: flex-start;

      .page-body {
        width: calc(100% - 300px);
        padding-top: 2rem;
        @media screen and (max-width: 1500px) {
          width: calc(100% - 250px);
        }
        @media screen and (max-width: 850px) {
          width: 100%;
        }
        .items-container {
          .items-header {
            h3 {
              color: #000000;
              font-size: 3rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              @media screen and (max-width: 1500px) {
                font-size: 2rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 27px;
              }
              @media screen and (max-width: 850px) {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 1rem;
              }
            }
            p {
              color: #333;
              font-size: 1.6rem;
              margin-top: 1rem;
              margin-bottom: 2rem;
              @media screen and (max-width: 1500px) {
                margin-top: 0.2rem;
                font-size: 1rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 1.3rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 1rem;
              }
            }
          }
          .on-phone-search {
            display: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            column-gap: 10px;
            @media screen and (max-width: 850px) {
              padding: 1rem;
              padding-left: 0px;
            }
            .search {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              .form {
                input {
                  width: 100%;
                  padding: 0.5rem 1rem;
                  border: 1px #adadad solid;
                  border-radius: 5px;
                  font-size: 1.25rem;
                  padding-left: 2.5rem;
                  @media screen and (max-width: 1100px) {
                    font-size: 1rem;
                  }
                  @media screen and (max-width: 850px) {
                    font-size: 0.9rem;
                  }
                }
                i {
                  color: #adadad;
                  font-size: 1.25rem;
                  position: absolute;
                  top: 50%;
                  left: 1rem;
                  transform: translateY(-50%);

                  @media screen and (max-width: 1100px) {
                    font-size: 1rem;
                  }
                  @media screen and (max-width: 850px) {
                    font-size: 0.9rem;
                  }
                }
              }
            }
            .filter {
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 5px;
              background: $blue;
              color: #fff;
              padding: 10px 1.5rem;
              border-radius: 500px;
              position: relative;
              i {
                color: #fff;
                position: relative;
                font-size: 1.25rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .items-list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
            column-gap: 2rem;
            row-gap: 2rem;
            @media screen and (max-width: 1500px) {
              grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
            @media screen and (max-width: 400px) {
              grid-template-columns: 1fr;
            }
            .artist-item {
              width: 100%;
              border-radius: 10px;
              overflow: hidden;
              box-shadow: 0px 0px 10px 1px #0000001f;
              cursor: pointer;
              @media screen and (max-width: 1100px) {
              }
              @media screen and (max-width: 850px) {
                width: 100%;
              }
              .top-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
                @media screen and (max-width: 1500px) {
                  padding: 0.5rem;
                }
                .desc {
                  padding-left: 1rem;
                  @media screen and (max-width: 1500px) {
                    padding-left: 0.5rem;
                  }
                  h3 {
                    color: #000000;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    @media screen and (max-width: 1500px) {
                      font-size: 1rem;
                    }
                    @media screen and (max-width: 1100px) {
                      font-size: 1.5rem;
                    }
                    @media screen and (max-width: 850px) {
                      font-size: 1.25rem;
                    }
                  }
                  h5 {
                    color: #969696;
                    font-size: 1rem;
                    @media screen and (max-width: 1500px) {
                      font-size: 0.7rem;
                    }
                    @media screen and (max-width: 1100px) {
                      font-size: 1rem;
                    }
                    @media screen and (max-width: 850px) {
                      font-size: 0.9rem;
                    }
                  }
                  p {
                    color: #333;
                    font-size: 1rem;
                    margin-top: 0.3rem;
                    @media screen and (max-width: 1100px) {
                      font-size: 1rem;
                    }
                    @media screen and (max-width: 850px) {
                      font-size: 0.9rem;
                    }
                  }
                }
                a {
                  color: #5f5f5f;
                  font-size: 1.25rem;
                  font-weight: 500;
                  border: 1px $blue solid;
                  padding: 0.5rem 1rem;
                  border-radius: 5px;
                  @media screen and (max-width: 1500px) {
                    padding: 0.2rem 1rem;
                    font-size: 0.9rem;
                  }
                  @media screen and (max-width: 1100px) {
                    font-size: 1rem;
                  }
                  @media screen and (max-width: 850px) {
                    font-size: 0.9rem;
                  }
                  &:hover {
                    background: $blue;
                    color: #fff;
                  }
                }
              }
              .images-section {
                // one big image and 4 small images
                display: grid;
                grid-template-columns: 1fr;
                column-gap: 5px;
                row-gap: 5px;
                padding: 1rem;
                .image {
                  width: 100%;
                  height: fit-content;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                    height: 200px;
                    @media screen and (max-width: 1500px) {
                      height: 150px;
                    }
                  }
                }
              }
              .bottom-section {
                padding: 1rem;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                  padding: 0.7rem 2rem;
                  background: white;
                  color: #000;
                  font-size: 1.25rem;
                  border-radius: 5px;
                  border: 1px #000 solid;
                  &:hover {
                    background: #000;
                    color: white;
                  }
                  @media screen and (max-width: 1100px) {
                    padding: 0.7rem 2rem;
                    font-size: 1rem;
                  }
                  @media screen and (max-width: 850px) {
                    padding: 0.5rem 2rem;
                    font-size: 1rem;
                  }
                }
              }
            }
          }
          .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3rem 0;
            img {
              -webkit-animation: spin 1s linear infinite;
              -moz-animation: spin 1s linear infinite;
              animation: spin 1s linear infinite;
              @media screen and (max-width: 1500px) {
                width: 50px;
              }
            }
            @-moz-keyframes spin {
              100% {
                -moz-transform: rotate(360deg);
              }
            }
            @-webkit-keyframes spin {
              100% {
                -webkit-transform: rotate(360deg);
              }
            }
            @keyframes spin {
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
  }
}

.art-ocean {
  padding: 1rem 5rem;
  @media screen and (max-width: 1500px) {
    padding: 1rem 5rem;
  }
  @media screen and (max-width: 1100px) {
    padding: 1rem 5rem;
  }
  @media screen and (max-width: 850px) {
    padding: 1rem 1rem;
  }
  .title-header {
    h3 {
      color: #000000;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 3rem 0;
      @media screen and (max-width: 1500px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 1100px) {
        font-size: 27px;
      }
      @media screen and (max-width: 850px) {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 1rem;
      }
    }
  }

  .art-ocean-container {
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 1500px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 850px) {
      // height: 300px;
    }
    .hooper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      .slide-img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 10px;
        overflow: hidden;
        .slide-content {
          min-height: 450px;
          background: rgba(0, 0, 0, 0.269);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
          @media screen and (max-width: 1500px) {
            min-height: 400px;
          }
          .left-bottom {
            padding: 3rem;
            padding-bottom: 3rem;
            color: #fff;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            row-gap: 1rem;
            @media screen and (max-width: 1500px) {
              padding: 2rem;
              padding-bottom: 2rem;
            }
            .date {
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              text-align: left;
              margin-right: 1rem;
              color: $blue;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
            h6 {
              font-size: 1rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-align: left;
              background: rgba(23, 177, 204, 0.592);
              padding: 0.5rem 1rem;
              border-radius: 500px;
              @media screen and (max-width: 1500px) {
                font-size: 0.8rem;
                padding: 0.2rem 0.5rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 1.2rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 1rem;
              }
            }
            h3 {
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-align: left;
              width: 70%;
              @media screen and (max-width: 1500px) {
                width: 75%;
                font-size: 2rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 2rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 1.5rem;
              }
            }
            .description {
              hr {
                width: 40px;
                height: 2px;
                background: #fff;
                margin-top: 0.7rem;
                margin-right: 1rem;
              }
              p {
                font-size: 1.25rem;
                text-align: left;
                color: #ececec;
                width: 70%;
                @media screen and (max-width: 1500px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .featured {
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            padding: 0.2rem 1rem;
            color: #fff;
            background: rgba(255, 255, 255, 0.259);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            font-size: 1.2rem;
            border-radius: 500px;
          }
        }
      }
    }
  }
}
</style>