<template>
  <div class="Home">
    <site-navbar />
    <div class="page-content">
      <div class="page-body">
        <div class="artists-container">
          <form action="" class="search" @submit.prevent="searchNow()">
            <input
              type="text"
              placeholder="Search here..."
              v-model="newSearchQuery"
            />
            <button type="submit">
              <i class="fas fa-search"></i>
            </button>
          </form>
          <div class="artists-header">
            <p v-if="searchQuery != ''">
              Showing search results for <b>"{{ searchQuery }}"</b>
            </p>
          </div>

          <div class="result-tabs">
            <div class="tab-item" :class="{ active: activeTab == 'artworks' }">
              <button @click="activeTab = 'artworks'">Artworks</button>
            </div>
            <div class="tab-item" :class="{ active: activeTab == 'artists' }" v-if="artists.length > 0">
              <button @click="activeTab = 'artists'">Artists</button>
            </div>
            <!-- galleries,  Auction houses, Auctions, Exhibitions, Articles-->
            <div class="tab-item" :class="{ active: activeTab == 'galleries' }" v-if="galleries.length > 0">
              <button @click="activeTab = 'galleries'">Galleries</button>
            </div>
            <div
              class="tab-item"
              :class="{ active: activeTab == 'auctionHouses' }"
              v-if="auctionHouses.length > 0"
            >
              <button @click="activeTab = 'auctionHouses'">
                Auction&nbsp;Houses
              </button>
            </div>
            <div class="tab-item" :class="{ active: activeTab == 'auctions' }" 
            v-if="auctions.length > 0">
              <button @click="activeTab = 'auctions'">Auctions</button>
            </div>
            <div
              class="tab-item"
              :class="{ active: activeTab == 'exhibitions' }"
              v-if="exhibitions.length > 0"
            >
              <button @click="activeTab = 'exhibitions'">Exhibitions</button>
            </div>
            <div class="tab-item" :class="{ active: activeTab == 'articles' }"
            v-if="articles.length > 0">
              <button @click="activeTab = 'articles'">Articles</button>
            </div>
          </div>
          <div class="artists-items-list" v-if="activeTab == 'artists'">
            <router-link
              :to="{
                name: 'ViewArtist',
                params: {
                  id: artist.artist_id,
                  name: $urlCompatible(artist.artist_name),
                },
              }"
              class="artist-item"
              v-for="artist in artists"
              :key="artist.artist_id"
            >
              <artist-card :artist="artist" />
            </router-link>
          </div>

          <div class="gallery-items-list" v-if="activeTab == 'galleries'">
            <router-link
              :to="{
                name: 'ViewGallery',
                params: {
                  id: item.gallery_id,
                  name: $urlCompatible(item.gallery_name_eng),
                },
              }"
              class="artist-item"
              v-for="item in galleries"
              :key="item.gallery_id"
            >
              <gallery-card :item="item" />
            </router-link>
          </div>
          <div
            class="actionhouse-items-list"
            v-if="activeTab == 'auctionHouses'"
          >
            <router-link
              :to="{
                name: 'ViewAuctionHouses',
                params: {
                  id: item.auction_house_id,
                  name: $urlCompatible(item.auction_house_name_eng),
                },
              }"
              class="artist-item"
              v-for="item in auctionHouses"
              :key="item.auction_house_id"
            >
              <auction-house-card :item="item" />
            </router-link>
          </div>
          <div class="actions-items-list" v-if="activeTab == 'auctions'">
            <router-link
              :to="{ name: 'Home' }"
              class="event-item"
              v-for="item in auctions"
              :key="item.id"
            >
              <auction-card :item="item" />
            </router-link>
          </div>

          <div class="exhibit-list-items all" v-if="activeTab == 'exhibitions'">
            <router-link
              :to="{
                name: 'ViewExhibit',
                params: {
                  id: item.exhibition_id,
                  name: $urlCompatible(item.exhibition_name_eng),
                },
              }"
              class="item"
              v-for="item in exhibitions"
              :key="item.exhibition_id"
            >
              <exhibit-card :item="item" />
            </router-link>
          </div>

          <div class="articles-items" v-if="activeTab == 'articles'">
            <router-link
              :to="{
                name: 'ViewArticle',
                params: {
                  id: item.article_id,
                  name: $urlCompatible(item.article_name_eng),
                },
              }"
              class="item"
              v-for="item in articles"
              :key="item.id"
            >
              <article-card :item="item" />
            </router-link>
          </div>

          <div class="artworks-images-container" v-if="activeTab == 'artworks'">
            <div class="image-list">
              <router-link
                class="image-item"
                v-for="(item, i) in artworks"
                :key="i"
                :to="{
                  name: `ViewArtWork`,
                  params: {
                    id: item.artwork_id,
                    name: $urlCompatible(item.title_eng),
                  },
                }"
              >
                <artwork-card :item="item" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer />
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      viewPass: "password",
      hooperSettings: {
        itemsToShow: 1.3,
        itemsToScroll: 1,
        wrapAround: true,
        snapAlign: "center",
        transition: 300,
        autoplay: true,
        touchDrag: true,
      },
      articles: [],
      artists: [],
      galleries: [],
      exhibitions: [],
      auctions: [],
      artworks: [],
      auctionHouses: [],
      current_index: 0,
      isLoading: true,
      viewPopup: false,
      selectedItem: null,
      categories: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      selectedCategory: "All",
      filters: {
        subjects: false,
        styles: false,
        orientation: false,
        materials: false,
        sizes: false,
        prices: false,
        countries: false,
      },
      searchQuery: "",
      newSearchQuery: "",
      activeTab: "artworks",
    };
  },
  methods: {
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    openArtWork(item) {
      this.$router.push({
        name: "ViewArtWork",
        params: { id: item.id, name: item.name },
      });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("SEARCH_ITEMS", {
          data: {
            searchQuery: this.searchQuery,
          },
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.artists = res.data.data.artists;
          this.artworks = res.data.data.artworks;
          this.galleries = res.data.data.galleries;
          this.exhibitions = res.data.data.exhibitions;
          this.auctions = res.data.data.auctions;
          this.auctionHouses = res.data.data.auctionHouses;
          this.articles = res.data.data.articles;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    searchNow() {
      if (this.newSearchQuery != "") {
        this.$router.push({
          name: "Search",
          query: { searchQuery: this.newSearchQuery },
        });
      }
    },
  },
  mounted() {
    if (!this.$route.query.searchQuery) {
      this.searchQuery = "";
    } else {
      this.searchQuery = this.$route.query.searchQuery;
      this.newSearchQuery = this.$route.query.searchQuery;
      this.getItems();
    }
  },
  // watch query string
  watch: {
    "$route.query.searchQuery": {
      handler: function (val, oldVal) {
        if (!val) {
          this.searchQuery = "";
        } else {
          this.searchQuery = val;
          this.getItems();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    display: flex;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
      margin-top: 120px;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
      margin-top: 0px;
    }
    .page-body {
      width: calc(100% - 0px);
      padding-top: 2rem;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 0px);
      }
      @media screen and (max-width: 850px) {
        width: 100%;
      }
      .artists-container {
        .search {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          margin-bottom: 1rem;
          input {
            width: 100%;
            padding: 0.5rem 1rem;
            border: 1px #adadad solid;
            border-radius: 5px;
            font-size: 1.25rem;
            padding-left: 2.5rem;
            @media screen and (max-width: 1100px) {
              font-size: 1rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.9rem;
            }
          }
          i {
            color: #adadad;
            font-size: 1.25rem;
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);

            @media screen and (max-width: 1100px) {
              font-size: 1rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 0.9rem;
            }
          }
        }
        .artists-header {
          h3 {
            color: #000000;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
          p {
            color: #333;
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            @media screen and (max-width: 1500px) {
              margin-top: 0.2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
        .on-phone-search {
          display: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          column-gap: 10px;
          @media screen and (max-width: 850px) {
            padding: 1rem;
            padding-left: 0px;
          }
          .search {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .form {
              input {
                width: 100%;
                padding: 0.5rem 1rem;
                border: 1px #adadad solid;
                border-radius: 5px;
                font-size: 1.25rem;
                padding-left: 2.5rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
              i {
                color: #adadad;
                font-size: 1.25rem;
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);

                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .filter {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 5px;
            background: $blue;
            color: #fff;
            padding: 10px 1.5rem;
            border-radius: 500px;
            position: relative;
            i {
              color: #fff;
              position: relative;
              font-size: 1.25rem;
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
        .loader {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3rem 0;
          img {
            -webkit-animation: spin 1s linear infinite;
            -moz-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
            @media screen and (max-width: 1500px) {
              width: 50px;
            }
          }
          @-moz-keyframes spin {
            100% {
              -moz-transform: rotate(360deg);
            }
          }
          @-webkit-keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }

        .result-tabs {
          display: flex;
          align-items: center;
          column-gap: 1rem;
          margin-bottom: 1rem;
          overflow-x: auto;

          @media screen and (max-width: 850px) {
            // display: none;
            padding-right: 1rem;
            column-gap: 0.25rem;
          }
          .tab-item {
            padding: 0.5rem 1rem;
            border-radius: 0px;
            cursor: pointer;
            font-size: 1.25rem;
            font-weight: 500;
            color: #adadad;
            transition: all 0.3s ease-in-out;
            border-bottom: 2px #fff solid;
            @media screen and (max-width: 850px) {
              padding: 0.5rem 0.5rem;
              font-size: 0.8rem;
            }
            &:hover {
              color: $blue;
              border-bottom: 2px $blue solid;
            }
            &.active {
              color: $blue;
              border-bottom: 2px $blue solid;
            }
          }
        }
      }
    }
  }
}
</style>