<template>
  <div class="Home">
    <site-navbar />
    <div class="page-content" v-if="selectedItem">
      <div class="page-body">
        <div class="left">
          <carousel
            :wrap-around="true"
            :items-to-show="1"
            v-if="selectedItem.artwork_images.length > 0"
            class="hooper"
          >
            <Slide
              v-for="(myslide, index) in selectedItem.artwork_images"
              :key="index"
              class="myslide"
              @click="viewPopup = true"
            >
              <div class="slide-img">
                <img :src="$uploads(myslide)" alt="" />
                <!-- <div class="content">
                  <h2>{{ myslide.title }}</h2>
                  <p>{{ myslide.description }}</p>
                </div> -->
              </div>
            </Slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
        <div class="right">
          <div class="top">
            <h2 v-if="selectedItem.is_art_artist">
              {{ selectedItem.title_eng }} -
              {{ selectedItem.artist.artist_name }}
            </h2>
            <h2 v-else>
              {{ selectedItem.title_eng }} - {{ selectedItem.artist_name_eng }}
            </h2>
            <p class="location">{{ selectedItem.artwork_location_eng }}</p>
          </div>
          <div class="middle">
            <p class="dime">{{ selectedItem.size }}</p>
          </div>
          <div class="description">
            Painting Details / {{ selectedItem.artwork_description_eng }}
          </div>
          <div class="date">
            Date Listed: <span>{{ $dateToDay(selectedItem.created_at) }}</span>
          </div>
          <div class="price" v-if="selectedItem.sale_price">
            {{ $comma(selectedItem.sale_price) }}
            {{ selectedItem.sale_price_currency }}
          </div>
          <div class="price" v-else>
            {{ $comma(selectedItem.hammer_price) }}
            {{ selectedItem.hammer_price_currency }}
          </div>
          <div
            class="bottom"
            v-if="selectedItem.artwork_purchase_site_link != ''"
          >
            <button
              @click="$redirect(selectedItem.artwork_purchase_site_link)"
              target="_blank"
              class="black"
            >
              Visit Seller
            </button>
            <button>Like</button>
          </div>
        </div>
      </div>
      <div class="history" v-if="selectedItem.artwork_sales_history_eng != ''">
        <p class="title">ATWORK SALES HISTORY</p>
        <div>
          <p>{{ selectedItem.artwork_sales_history_eng }}</p>
        </div>
        <!-- <div class="my-sm-table">
          <table>
            <thead>
              <tr>
                <th>SALES DATE</th>
                <th>CHANNEL</th>
                <th>RESULT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>13 - 10 - 2018</td>
                <td>Auction</td>
                <td>{{ $price(10000) }}</td>
              </tr>
              <tr>
                <td>13 - 10 - 2018</td>
                <td>Auction</td>
                <td>-</td>
              </tr>
              <tr>
                <td>13 - 10 - 2018</td>
                <td>Auction</td>
                <td>{{ $price(5000) }}</td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <div class="price-tag">
          <div class="price">
            {{ $price(selectedItem.price) }}
          </div>
        </div>
      </div>
      <div class="related">
        <h2 class="related-header">Related Artwork</h2>
        <div class="artworks-images-container">
          <div
            class="image-list"
            carousel
            :items-to-show="4"
            v-if="allItems.length > 0"
          >
            <router-link
              v-for="(item, i) in [...allItems].splice(0, 4)"
              :key="i"
              :class="item.size"
              class="slide-item"
              :to="{
                name: `ViewArtWork`,
                params: {
                  id: item.artwork_id,
                  name: $urlCompatible(item.title_eng),
                },
              }"
            >
              <div class="image-item">
                <artwork-card :item="item" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="my-modal enlarge-artwork" v-if="viewPopup">
      <div class="modal-content">
        <div class="modal-header">
          <h2>{{ selectedItem.title_eng }}</h2>
          <button class="close" @click="closeView">X</button>
        </div>
        <div class="modal-body">
          <div class="slider-container">
            <carousel
              :wrap-around="true"
              :items-to-show="1"
              v-if="selectedItem.artwork_images.length > 0"
              class="hooper"
            >
              <Slide
                v-for="(myslide, index) in selectedItem.artwork_images"
                :key="index"
                class="myslide"
              >
                <div class="slide-img">
                  <img :src="$uploads(myslide)" alt="" />
                </div>
              </Slide>
              <template #addons>
                <navigation />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      viewPass: "password",
      hooperSettings: {
        itemsToShow: 1.3,
        itemsToScroll: 1,
        wrapAround: true,
        snapAlign: "center",
        transition: 300,
        autoplay: true,
        touchDrag: true,
      },
      images: {},

      allItems: [],
      current_index: 0,
      isLoading: true,
      viewPopup: false,
      selectedItem: null,
      categories: [
        {
          id: 1,
          name: "Photography",
        },
        {
          id: 2,
          name: "Drawings",
        },
        {
          id: 3,
          name: "Mixed Media",
        },
        {
          id: 4,
          name: "Sculpture",
        },
        {
          id: 5,
          name: "Digital",
        },
        {
          id: 6,
          name: "Installation",
        },
        {
          id: 7,
          name: "Collage",
        },
        {
          id: 8,
          name: "Printmaking",
        },
      ],
      selectedCategory: "All",
      filters: {
        subjects: false,
        styles: false,
        orientation: false,
        materials: false,
        sizes: false,
        prices: false,
        countries: false,
      },
    };
  },
  methods: {
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    getSingleItem() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTWORK_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.selectedItem = res.data;
          this.selectedItem.artwork_images = JSON.parse(
            this.selectedItem.artwork_images
          );
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTWORKS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allItems = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.getSingleItem();
    this.getItems();
  },
  watch: {
    $route() {
      this.getSingleItem();
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .page-body {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      @media screen and (max-width: 1500px) {
        margin-top: 2rem;
      }
      .left {
        .carousel__track {
          align-items: center !important;
          li {
            align-self: center !important;
          }
        }
        .hooper {
          height: auto;
          background: #eee;
          .myslide {
            height: 100%;
            img {
              height: 100%;
              max-height: 500px;
              align-self: center;
              justify-self: center;
              @media screen and (max-width: 1500px) {
                max-height: 400px;
              }
            }
          }
        }
        .carousel__next {
          background: #000 !important;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        @media screen and (max-width: 1500px) {
          row-gap: 1rem;
        }
        .top {
          h2 {
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: 300;
            @media screen and (max-width: 1500px) {
              font-size: 1.5rem;
            }
          }
          .location {
            font-weight: 500;
            font-size: 1.2rem;

            @media screen and (max-width: 1500px) {
              font-size: 1rem;
            }
          }
        }
        .middle,
        .description {
          font-weight: 300;
          font-size: 1.5rem;
          line-height: 1.3;
          @media screen and (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
        .date {
          font-weight: 500;
          font-size: 1.2rem;
          @media screen and (max-width: 1500px) {
            font-size: 1rem;
          }
        }
        .price {
          font-weight: 500;
          font-size: 1.75rem;
          color: #333;

          @media screen and (max-width: 1500px) {
            font-size: 1.5rem;
          }
        }
        .bottom {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 2rem;
          @media screen and (max-width: 1500px) {
            column-gap: 1rem;
          }
          button,
          a {
            padding: 8px 10px;
            background: white;
            font-size: 1.5rem;
            border: 1px black solid;
            border-radius: 5px;
            width: 100%;
            text-align: center;
            @media screen and (max-width: 1500px) {
              font-size: 1rem;
            }
            &.black {
              background: black;
              color: white;
            }
          }
        }
      }
    }
    .history {
      margin: 3rem 0;
      .title {
        font-size: 1.8rem;
        font-weight: 300;
        margin-bottom: 1rem;
        @media screen and (max-width: 1500px) {
          font-size: 1.25rem;
        }
      }
      .my-sm-table {
        overflow-x: auto;
        margin-top: 1rem;
        table {
          min-width: 40%;
          border-collapse: collapse;
          thead {
            background: transparent;
            tr,
            th {
              background: transparent;
              border-top: 1px solid #ddd;
            }
          }
          th,
          td {
            border-bottom: 1px solid #ddd;
            padding: 1rem;
            text-align: center;
            @media screen and (max-width: 1500px) {
              padding: 0.5rem;
            }
          }
          th {
            background: #eee;
          }
        }
      }
      .price-tag {
        width: 40%;
        display: flex;
        justify-content: center;
        .price {
          font-size: 2rem;
          font-weight: 300;
          margin-top: 1rem;
          border: 1px solid #ddd;
          width: 75%;
          text-align: center;
          color: #616161;
          @media screen and (max-width: 1500px) {
            font-size: 1.25rem;
          }
        }
      }
    }
    .related {
      margin-top: 1rem;
      margin-bottom: 5rem;
      .related-header {
        font-size: 2rem;
        font-weight: 300;
        margin-bottom: 1rem;

        @media screen and (max-width: 1500px) {
          font-size: 1.25rem;
        }
      }
    }
  }
}
.my-modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0000008f;
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    width: 90%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      padding-bottom: 0;
      h2 {
        font-size: 2rem;
        font-weight: 500;
        color: #fff;
        @media screen and (max-width: 1500px) {
          font-size: 1.5rem;
        }
      }
      button {
        padding: 0.5rem 1rem;
        background: white;
        color: black;
        border: none;
        border-radius: 5px;
        font-size: 1.5rem;
        @media screen and (max-width: 1500px) {
          font-size: 1rem;
        }
      }
    }
    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      .slider-container {
        width: 100%;
        height: 100%;
        .hooper {
          height: 100%;
          .myslide {
            height: 100%;
            img {
              height: 78vh;
              align-self: center;
              justify-self: center;
            }
          }
        }
      }
    }
  }
}
</style>