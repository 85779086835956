<template>
  <div class="dashboard">
    <div class="page-top-section">
      <div class="left">
        <h2>ARTWORKS</h2>
        <div class="page-indication">
          <p>Artworks</p>
          <i class="fa-solid fa-caret-right"></i>
          <p>Artworks List</p>
          <i class="fa-solid fa-caret-right"></i>
          <p class="active">{{ actionType }} Artworks</p>
        </div>
      </div>
      <div class="right">
        <div class="export-btn">
          <router-link :to="{ name: 'AdminArtworks' }" class="btn">
            <i class="fa-solid fa-times"></i>
            <span>Cancel</span>
          </router-link>
        </div>
        <div class="add-btn">
          <button class="btn" @click="saveItem()" v-if="actionType == 'Add'">
            <i class="fa-solid fa-plus"></i>
            <span>Save</span>
          </button>
          <button class="btn" @click="updateItem()" v-else>
            <i class="fa-solid fa-save"></i>
            <span>Update</span>
          </button>
        </div>
      </div>
    </div>
    <div class="page-content-section">
      <div class="left">
        <div class="form">
          <div class="form-body">
            <div iv class="form-group">
              <div class="form-header">
                <h3>General Information</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artwork-name">Title (ENG)</label>
                  <input
                    type="text"
                    id="artwork-name"
                    placeholder="Title (ENG)"
                    v-model="newItem.title_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Title (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="제목"
                    v-model="newItem.title_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Creation Year (Global)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Creation Year"
                    v-model="newItem.creation_year"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Size (Global)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Size"
                    v-model="newItem.size"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Ho Size (Only KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="호 사이즈"
                    v-model="newItem.ho_size_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artist-name">Category (ENG)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.category_eng"
                  >
                    <option value="">Select Category</option>
                    <option
                      v-for="category in allCategories"
                      :key="category.category_id"
                      :value="category.category_id"
                    >
                      {{ category.category_name_eng }}
                    </option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artist-name">Category (KOR)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.category_kor"
                  >
                    <option value="">카테고리 선택</option>
                    <option
                      v-for="category in allCategories"
                      :key="category.category_id"
                      :value="category.category_id"
                    >
                      {{ category.category_name_kor }}
                    </option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Materials (ENG)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.materials_eng"
                  >
                    <option value="">Select Material</option>
                    <option
                      v-for="material in allMaterials"
                      :key="material.material_id"
                      :value="material.material_id"
                    >
                      {{ material.material_name_eng }}
                    </option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Materials (KOR)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.materials_kor"
                  >
                    <option value="">재료 선택</option>
                    <option
                      v-for="material in allMaterials"
                      :key="material.material_id"
                      :value="material.material_id"
                    >
                      {{ material.material_name_kor }}
                    </option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Mediums (ENG)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.mediums_eng"
                  >
                    <option value="">Select Medium</option>
                    <option
                      v-for="medium in allMediums"
                      :key="medium.medium_id"
                      :value="medium.medium_id"
                    >
                      {{ medium.medium_name_eng }}
                    </option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Mediums (KOR)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.mediums_kor"
                  >
                    <option value="">매체 선택</option>
                    <option
                      v-for="medium in allMediums"
                      :key="medium.medium_id"
                      :value="medium.medium_id"
                    >
                      {{ medium.medium_name_kor }}
                    </option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Mediums&Techniques (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Mediums&Techniques"
                    v-model="newItem.mediums_techniques_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Mediums&Techniques (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="매체 및 기술"
                    v-model="newItem.mediums_techniques_kor"
                  />
                </div>

                <div class="input-group-item">
                  <label for="artwork-location">Frame (ENG)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.frame_eng"
                  >
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Frame (KOR)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.frame_kor"
                  >
                    <option>예</option>
                    <option>아니요</option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Rarity(edition) (Global)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Rarity(edition)"
                    v-model="newItem.rarity_edition"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Certificate of authenticity (ENG)</label
                  >
                  <textarea
                    type="text"
                    id="artwork-location"
                    placeholder="Certificate of authenticity"
                    rows="5"
                    v-model="newItem.certificate_of_authenticity_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Certificate of authenticity (KOR)</label
                  >
                  <textarea
                    type="text"
                    id="artwork-location"
                    placeholder="진위 여부 증명서"
                    rows="5"
                    v-model="newItem.certificate_of_authenticity_kor"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Appraisal Status (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Appraisal Status"
                    v-model="newItem.appraisal_status_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Appraisal Status (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="감정 상태"
                    v-model="newItem.appraisal_status_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Techniques (ENG)</label>
                  <textarea
                    type="text"
                    id="artwork-location"
                    placeholder="Techniques"
                    rows="5"
                    v-model="newItem.techniques_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Techniques (KOR)</label>
                  <textarea
                    type="text"
                    id="artwork-location"
                    placeholder="기술"
                    rows="5"
                    v-model="newItem.techniques_kor"
                  ></textarea>
                </div>
                <!-- Genre -->
                <div class="input-group-item">
                  <label for="artwork-location">Genre (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Genre"
                    v-model="newItem.genres_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Genre (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="장르"
                    v-model="newItem.genres_kor"
                  />
                </div>
                <!-- Last Updated -->
                <div class="input-group-item">
                  <label for="artwork-location">Last Updated</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Last Updated"
                    v-model="newItem.last_update"
                  />
                </div>
                <!-- Signiture Location (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Signiture Location (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Signiture Location"
                    v-model="newItem.signiture_location_eng"
                  />
                </div>
                <!-- Signiture Location (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location">Signiture Location (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="서명 위치"
                    v-model="newItem.signiture_location_kor"
                  />
                </div>
                <!-- Artwork Status (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Artwork Status (ENG)</label>
                  <select name="" id="" v-model="newItem.artwork_status_eng">
                    <option value="Available">Available</option>
                    <option value="Sold">Sold</option>
                  </select>
                </div>
                <!-- Artwork Status (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location">Artwork Status (KOR)</label>
                  <select name="" id="" v-model="newItem.artwork_status_kor">
                    <option value="사용 가능">사용 가능</option>
                    <option value="판매된">판매된</option>
                  </select>
                </div>
                <!-- Artwork Tags(Keyword) (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Artwork Tags(Keyword) (ENG)</label
                  >
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Artwork Tags(Keyword)"
                    v-model="newItem.artwork_tags_keyword_eng"
                  />
                </div>
                <!-- Artwork Tags(Keyword) (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Artwork Tags(Keyword) (KOR)</label
                  >
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="작품 태그 (키워드)"
                    v-model="newItem.artwork_tags_keyword_kor"
                  />
                </div>
                <!-- Artwork Location (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Artwork Location (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Artwork Location"
                    v-model="newItem.artwork_location_eng"
                  />
                </div>
                <!-- Artwork Location (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location">Artwork Location (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="작품 위치"
                    v-model="newItem.artwork_location_kor"
                  />
                </div>
                <!-- Artwork Sales History (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Artwork Sales History (ENG)</label
                  >
                  <textarea
                    rows="5"
                    type="text"
                    id="artwork-location"
                    placeholder="Artwork Sales History"
                    v-model="newItem.artwork_sales_history_eng"
                  ></textarea>
                </div>
                <!-- Artwork Sales History (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Artwork Sales History (KOR)</label
                  >
                  <textarea
                    rows="5"
                    type="text"
                    id="artwork-location"
                    placeholder="작품 판매 기록"
                    v-model="newItem.artwork_sales_history_kor"
                  ></textarea>
                </div>
                <!-- Sell by (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Sell by (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Sell by"
                    v-model="newItem.sell_by_eng"
                  />
                </div>
                <!-- Sell by (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location">Sell by (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="판매"
                    v-model="newItem.sell_by_kor"
                  />
                </div>
                <!-- Auction Channel (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Auction Channel (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Auction Channel"
                    v-model="newItem.auction_channel_eng"
                  />
                </div>
                <!-- Auction Channel (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location">Auction Channel (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="경매 채널"
                    v-model="newItem.auction_channel_kor"
                  />
                </div>
                <!-- Auction Name (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Auction Name (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Auction Name"
                    v-model="newItem.auction_name_eng"
                  />
                </div>
                <!-- Auction Name (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location">Auction Name (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="경매 이름"
                    v-model="newItem.auction_name_kor"
                  />
                </div>
                <!-- Auction Date (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Auction Date (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Auction Date"
                    v-model="newItem.auction_date_eng"
                  />
                </div>
                <!-- Auction Date (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location">Auction Date (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="경매 날짜"
                    v-model="newItem.auction_date_kor"
                  />
                </div>
                <!-- Hammer price(Winning Bid) (Global) -->
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Hammer price(Winning Bid) (Global)</label
                  >
                  <div class="double-input">
                    <select
                      name=""
                      id=""
                      v-model="newItem.hammer_price_currency"
                    >
                      <option value="KRW">KRW</option>
                    </select>
                    <input
                      type="number"
                      :placeholder="$comma(2000)"
                      v-model="newItem.hammer_price"
                    />
                  </div>
                </div>
                <!-- Sale Price (Global) -->
                <div class="input-group-item">
                  <label for="artwork-location">Sale Price (Global)</label>
                  <div class="double-input">
                    <select name="" id="" v-model="newItem.sale_price_currency">
                      <option value="KRW">KRW</option>
                    </select>
                    <input
                      type="number"
                      :placeholder="$comma(2000)"
                      v-model="newItem.sale_price"
                    />
                  </div>
                </div>
                <!-- Artwork purchasse site link (Global) -->
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Artwork purchasse site link (Global)</label
                  >
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Artwork purchasse site link"
                    v-model="newItem.artwork_purchase_site_link"
                  />
                </div>
                <!-- Artwork Description (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Artwork Description (ENG)</label
                  >
                  <textarea
                    rows="5"
                    type="text"
                    id="artwork-location"
                    placeholder="Artwork Description"
                    v-model="newItem.artwork_description_eng"
                  ></textarea>
                </div>
                <!-- Artwork Description (KOR) -->
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Artwork Description (KOR)</label
                  >
                  <textarea
                    rows="5"
                    type="text"
                    id="artwork-location"
                    placeholder="작품 설명"
                    v-model="newItem.artwork_description_kor"
                  ></textarea>
                </div>
                <!-- Artist -->
                <div class="input-group-item">
                  <label for="artist-name"
                    >Artist Member of <strong>Art Ocean</strong>?</label
                  ><select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.is_art_artist"
                  >
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
                <!-- Artist -->
                <div class="input-group-item" v-if="newItem.is_art_artist">
                  <label for="artist-name">Artist</label
                  ><select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.art_artist_id"
                  >
                    <option value="">Select Artist</option>
                    <option
                      v-for="artist in allArtists"
                      :key="artist.artist_id"
                      :value="artist.artist_id"
                    >
                      {{ artist.artist_name }}
                    </option>
                  </select>
                </div>
                <div v-if="!newItem.is_art_artist">
                  <div class="input-group-item">
                    <label for="artwork-location">Artist name (ENG)</label>
                    <input
                      type="text"
                      id="artwork-location"
                      placeholder="Artist name (ENG)"
                      v-model="newItem.artist_name_eng"
                    />
                  </div>
                  <div class="input-group-item">
                    <label for="artwork-location">Artist name (KOR)</label>
                    <input
                      type="text"
                      id="artwork-location"
                      placeholder="Artist name (KOR)"
                      v-model="newItem.artist_name_kor"
                    />
                  </div>
                  <div class="input-group-item">
                    <label for="artwork-location"
                      >Artist nationality (ENG)</label
                    >
                    <input
                      type="text"
                      id="artwork-location"
                      placeholder="Artwork nationality (ENG)"
                      v-model="newItem.nationality_eng"
                    />
                  </div>
                  <div class="input-group-item">
                    <label for="artwork-location"
                      >Artist nationality (KOR)</label
                    >
                    <input
                      type="text"
                      id="artwork-location"
                      placeholder="Artist nationality (KOR)"
                      v-model="newItem.nationality_kor"
                    />
                  </div>
                  <div class="input-group-item">
                    <label for="artwork-location"
                      >Artist Year of Birst & Death</label
                    >
                    <input
                      type="text"
                      id="artwork-location"
                      placeholder="Artist Year of Birst & Death"
                      v-model="newItem.year_of_birth_and_death"
                    />
                  </div>
                </div>

                <!-- Crawling channel (not display) -->
                <div class="input-group-item">
                  <label for="artwork-location">Crawling channel</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Crawling channel"
                    v-model="newItem.crawling_channel"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-header">
                <h3>Artwork Images (Global)</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artist-name">Images</label>
                  <o-upload
                    :multiple="true"
                    :uploadUrl="'files/upload'"
                    @imagesUploaded="
                      (data) => {
                        newItem.artwork_images_initial = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.artwork_images_initial"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allartWorks: [
        {
          id: 1,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "red",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 2,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "pink",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 3,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "yellow",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 4,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Green",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 5,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Orange",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 6,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Blue",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 7,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "yellow",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 8,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "black",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 9,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "green",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 10,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "red",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 11,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "#333",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 12,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 13,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200",
          color: "red",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 14,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "red",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
      ],
      artWorks: [],
      checkedItems: [],
      artists: [
        {
          id: 1,
          name: "Leo Da Vinci",
        },
        {
          id: 2,
          name: "Leo Da Vinci",
        },
        {
          id: 3,
          name: "Leo Da Vinci",
        },
        {
          id: 4,
          name: "Leo Da Vinci",
        },
        {
          id: 5,
          name: "Leo Da Vinci",
        },
        {
          id: 6,
          name: "Leo Da Vinci",
        },
        {
          id: 7,
          name: "Leo Da Vinci",
        },
        {
          id: 8,
          name: "Leo Da Vinci",
        },
        {
          id: 9,
          name: "Leo Da Vinci",
        },
        {
          id: 10,
          name: "Leo Da Vinci",
        },
        {
          id: 11,
          name: "Leo Da Vinci",
        },
        {
          id: 12,
          name: "Leo Da Vinci",
        },
        {
          id: 13,
          name: "Leo Da Vinci",
        },
        {
          id: 14,
          name: "Leo Da Vinci",
        },
        {
          id: 15,
          name: "Leo Da Vinci",
        },
        {
          id: 16,
          name: "Leo Da Vinci",
        },
        {
          id: 17,
          name: "Leo Da Vinci",
        },
        {
          id: 18,
          name: "Leo Da Vinci",
        },
        {
          id: 19,
          name: "Leo Da Vinci",
        },
        {
          id: 20,
          name: "Leo Da Vinci",
        },
        {
          id: 21,
          name: "Leo Da Vinci",
        },
        {
          id: 22,
          name: "Leo Da Vinci",
        },
        {
          id: 23,
          name: "Leo Da Vinci",
        },
        {
          id: 24,
          name: "Leo Da Vinci",
        },
        {
          id: 25,
          name: "Leo Da Vinci",
        },
        {
          id: 26,
          name: "Leo Da Vinci",
        },
        {
          id: 27,
          name: "Leo Da Vinci",
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      newItem: {
        title_eng: "",
        title_kor: "",
        artwork_images: "",
        artwork_images_initial: "",
        creation_year: "",
        size: "",
        ho_size_kor: "",
        category_eng: "",
        category_kor: "",
        materials_eng: "",
        materials_kor: "",
        mediums_eng: "",
        mediums_kor: "",
        mediums_techniques_eng: "",
        mediums_techniques_kor: "",
        frame_eng: "",
        frame_kor: "",
        rarity_edition: "",
        certificate_of_authenticity_eng: "",
        certificate_of_authenticity_kor: "",
        appraisal_status_eng: "",
        appraisal_status_kor: "",
        techniques_eng: "",
        techniques_kor: "",
        genres_eng: "",
        genres_kor: "",
        last_update: "",
        signiture_location_eng: "",
        signiture_location_kor: "",
        artwork_status_eng: "",
        artwork_status_kor: "",
        artwork_tags_keyword_eng: "",
        artwork_tags_keyword_kor: "",
        artwork_location_eng: "",
        artwork_location_kor: "",
        artwork_sales_history_eng: "",
        artwork_sales_history_kor: "",
        sell_by_eng: "",
        sell_by_kor: "",
        auction_channel_eng: "",
        auction_channel_kor: "",
        auction_name_eng: "",
        auction_name_kor: "",
        auction_date_eng: "",
        auction_date_kor: "",
        hammer_price: "",
        sale_price: "",
        artwork_purchase_site_link: "",
        artwork_description_eng: "",
        artwork_description_kor: "",
        artist_name_eng: "",
        artist_name_kor: "",
        nationality_eng: "",
        nationality_kor: "",
        year_of_birth_and_death: "",
        uci_code: "",
        crawling_channel: "",
        is_art_artist: true, // Assuming null for boolean values
        art_artist_id: "",
      },
      allCategories: [],
      allMaterials: [],
      allMediums: [],
      allArtists: [],
      actionType: "Add",
    };
  },
  methods: {
    checkedContains(id) {
      return this.checkedItems.includes(id);
    },
    checkNow(id) {
      if (this.checkedItems.includes(id)) {
        this.checkedItems = this.checkedItems.filter((item) => item !== id);
      } else {
        this.checkedItems.push(id);
      }
    },
    checkAllNow() {
      this.checkedItems = [];
      this.artWorks.forEach((artwork) => {
        this.checkedItems.push(artwork.id);
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.artWorks = this.$changePage(
        this.allartWorks,
        page,
        this.itemsPerPage
      );
      window.scrollTo(0, 0);
    },

    getAllCategories() {
      this.$store
        .dispatch("GET_CATEGORIES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allCategories = res.data;
        })
        .catch((err) => {});
    },
    getAllMaterials() {
      this.$store
        .dispatch("GET_MATERIALS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allMaterials = res.data;
        })
        .catch((err) => {});
    },
    getAllMediums() {
      this.$store
        .dispatch("GET_MEDIUMS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allMediums = res.data;
        })
        .catch((err) => {});
    },
    getArtists() {
      this.$store
        .dispatch("GET_ARTISTS", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.allArtists = res.data;
        })
        .catch((err) => {});
    },
    saveItem() {
      this.$startLoader();
      this.newItem.artwork_images = JSON.stringify([
        ...this.newItem.artwork_images_initial,
      ]);
      this.$store
        .dispatch("ADD_ARTWORK", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminArtworks" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    updateItem() {
      this.newItem.artwork_images = JSON.stringify([
        ...this.newItem.artwork_images_initial,
      ]);
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_ARTWORK", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminArtworks" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTWORK_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.newItem = res.data;
          this.newItem.artwork_images_initial = JSON.parse(
            this.newItem.artwork_images
          );
        })
        .catch((err) => {
          this.$stopLoader();
          // if (err.response.status == 400) {
          //   err.response.data.errors.forEach((error) => {
          //     this.$notify({
          //       type: "error",
          //       title: "Something went wrong",
          //       text: error.msg,
          //     });
          //   });
          // }
          // if (err.response.status == 401) {
          //   this.$notify({
          //     type: "error",
          //     title: "Authentication failed",
          //     text: err.response.data.message,
          //   });
          //   this.$router.push({ name: "AdminLogin" });
          // }
        });
    },
  },
  mounted() {
    this.getAllCategories();
    this.getAllMaterials();
    this.getAllMediums();
    this.getArtists();
    this.actionType = this.$route.params.actionType;
    if (this.actionType == "Edit") {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/dashboard.scss";
</style>