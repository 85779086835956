<template>
  <div class="dashboard">
    <div class="page-top-section">
      <div class="left">
        <h2>{{ actionType }} ARTIST</h2>
        <div class="page-indication">
          <p>Artist</p>
          <i class="fa-solid fa-caret-right"></i>
          <p>Artists List</p>
          <i class="fa-solid fa-caret-right"></i>
          <p class="active">{{ actionType }} Artist</p>
        </div>
      </div>
      <div class="right">
        <div class="export-btn">
          <router-link :to="{ name: 'AdminArtists' }" class="btn">
            <i class="fa-solid fa-times"></i>
            <span>Cancel</span>
          </router-link>
        </div>
        <div class="add-btn">
          <button class="btn" @click="saveItem()" v-if="actionType == 'Add'">
            <i class="fa-solid fa-plus"></i>
            <span>Save</span>
          </button>
          <button class="btn" @click="updateItem()" v-else>
            <i class="fa-solid fa-save"></i>
            <span>Update</span>
          </button>
        </div>
      </div>
    </div>
    <div class="page-content-section">
      <div class="left" :class="{ active: actionType == 'Add' }">
        <div class="form">
          <div class="form-body">
            <div iv class="form-group">
              <div class="form-header">
                <h3>General Information</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artwork-name">Artist Name (ENG)</label>
                  <input
                    type="text"
                    id="artwork-name"
                    placeholder="Type Artist Name (ENG) here. . ."
                    v-model="newItem.artist_name"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Artist Name (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="아티스트 이름. . ."
                    v-model="newItem.artist_name_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Birth & Death Year (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="1880 - 1950 / 1950 - Present"
                    v-model="newItem.artist_dob"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Nationality (ENG)</label>
                  <select
                    name=""
                    id=""
                    v-model="newItem.artist_nationality_eng"
                    @change="changeNationality('eng')"
                  >
                    <option value="">Select</option>

                    <option
                      v-for="(item, index) in $store.state.countries"
                      :key="index"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Nationality (KOR)</label>
                  <select
                    name=""
                    id=""
                    v-model="newItem.artist_nationality_kor"
                    @change="changeNationality('kor')"
                  >
                    <option value="">Select</option>

                    <option
                      v-for="(item, index) in $store.state.countries"
                      :key="index"
                    >
                      {{ item.korean_name }}
                    </option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artist-name">Gender (ENG)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.artist_gender_eng"
                  >
                    <option value="">Select Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artist-name">Gender (KOR)</label>
                  <select
                    name="artist-name"
                    id="artist-name"
                    v-model="newItem.artist_gender_kor"
                  >
                    <option value="">성별을 선택하세요</option>
                    <option>남성</option>
                    <option>여성</option>
                  </select>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Activity Area (ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Activity Area (ENG)"
                    v-model="newItem.artist_activity_area_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Activity Area (KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="활동 영역"
                    v-model="newItem.artist_activity_area_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Educational Background (ENG)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Educational Background (ENG)"
                    v-model="newItem.artist_educational_background_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Educational Background (KOR)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="교육 배경"
                    v-model="newItem.artist_educational_background_kor"
                  ></textarea>
                </div>
                <!-- Awards (ENG), Exhibitions (ENG), Solo Exhibitions (ENG), Group Exhibitions (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Awards (ENG)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Awards (ENG)"
                    v-model="newItem.artist_awards_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Awards (KOR)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="수상 내역"
                    v-model="newItem.artist_awards_kor"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Exhibitions (ENG)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Exhibitions (ENG)"
                    v-model="newItem.artist_exhibitions_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Exhibitions (KOR)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="전시 내역"
                    v-model="newItem.artist_exhibitions_kor"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Solo Exhibitions (ENG)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Solo Exhibitions (ENG)"
                    v-model="newItem.artist_solo_exhibition_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Solo Exhibitions (KOR)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="개인 전시"
                    v-model="newItem.artist_solo_exhibition_kor"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Group Exhibitions (ENG)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Group Exhibitions (ENG)"
                    v-model="newItem.artist_group_exhibition_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Group Exhibitions (KOR)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="단체 전시"
                    v-model="newItem.artist_group_exhibition_kor"
                  ></textarea>
                </div>
                <!--  Residency (ENG), Genre (ENG), Keywords (ENG), Transactions (ENG) -->
                <div class="input-group-item">
                  <label for="artwork-location">Residency (ENG)</label>
                  <input
                    type="text"
                    placeholder="Residency (ENG)"
                    v-model="newItem.artist_residency_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Residency (KOR)</label>
                  <input
                    type="text"
                    placeholder="레지던시"
                    v-model="newItem.artist_residency_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Genre (ENG)</label>
                  <input
                    type="text"
                    placeholder="Genre (ENG)"
                    v-model="newItem.artist_genre_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Genre (KOR)</label>
                  <input
                    type="text"
                    placeholder="장르"
                    v-model="newItem.artist_genre_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Keywords (ENG)</label>
                  <input
                    type="text"
                    placeholder="Keywords (ENG)"
                    v-model="newItem.artist_keywords_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Keywords (KOR)</label>
                  <input
                    type="text"
                    placeholder="키워드"
                    v-model="newItem.artist_keywords_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Transactions (ENG)</label>
                  <input
                    type="text"
                    placeholder="Transactions (ENG)"
                    v-model="newItem.artist_transactions_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Transactions (KOR)</label>
                  <input
                    type="text"
                    placeholder="거래 내역"
                    v-model="newItem.artist_transactions_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Artist Description (ENG)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Description (ENG)"
                    v-model="newItem.artist_description_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Description (KOR)</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="설명"
                    v-model="newItem.artist_description_kor"
                  ></textarea>
                  <div class="input-group-item">
                    <label for="artwork-location"
                      >Belonging Gallery (ENG)</label
                    >
                    <input
                      type="text"
                      placeholder="Belonging Gallery (ENG)"
                      v-model="newItem.artist_belonging_gallery_eng"
                    />
                  </div>
                  <div class="input-group-item">
                    <label for="artwork-location"
                      >Belonging Gallery (KOR)</label
                    >
                    <input
                      type="text"
                      placeholder="소속 갤러리"
                      v-model="newItem.artist_belonging_gallery_kor"
                    />
                  </div>
                  <div class="input-group-item">
                    <label for="artwork-location">Crawling Channel</label>
                    <input
                      type="text"
                      placeholder="Crawling Channel"
                      v-model="newItem.artist_crawling_channel"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-header">
                <h3>Artist Image</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artist-name">Photo</label>
                  <o-upload
                    :multiple="false"
                    :uploadUrl="'files/upload'"
                    @imagesUploaded="
                      (data) => {
                        newItem.artist_image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.artist_image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="right" v-if="actionType == 'Edit'">
        <div class="form">
          <div class="form-body">
            <div class="form-group">
              <div class="form-header">
                <h3>Artworks</h3>
              </div>
            </div>
            <div class="form-group">
              <div class="art-table2">
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Artwork</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="artwork in artWorks"
                      :key="artwork.id"
                      :class="{ active: checkedContains(artwork.id) }"
                    >
                      <td class="blue">{{ artwork.uciCode }}</td>
                      <td>
                        <div class="img">
                          <img :src="$img('arts/' + artwork.image)" alt="" />
                          <div class="right">
                            <p>{{ artwork.artwork }}</p>
                            <span>location</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <button class="btn-primary mt-5">
                <i class="fa-solid fa-plus"></i>
                <span>Add Artwok</span>
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allartWorks: [
        {
          id: 1,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "red",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 2,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "pink",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 3,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "yellow",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 4,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Green",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 5,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Orange",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
      ],
      artWorks: [],
      checkedItems: [],
      artists: [
        {
          id: 1,
          name: "Leo Da Vinci",
        },
        {
          id: 2,
          name: "Leo Da Vinci",
        },
        {
          id: 3,
          name: "Leo Da Vinci",
        },
        {
          id: 4,
          name: "Leo Da Vinci",
        },
        {
          id: 5,
          name: "Leo Da Vinci",
        },
        {
          id: 6,
          name: "Leo Da Vinci",
        },
        {
          id: 7,
          name: "Leo Da Vinci",
        },
        {
          id: 8,
          name: "Leo Da Vinci",
        },
        {
          id: 9,
          name: "Leo Da Vinci",
        },
        {
          id: 10,
          name: "Leo Da Vinci",
        },
        {
          id: 11,
          name: "Leo Da Vinci",
        },
        {
          id: 12,
          name: "Leo Da Vinci",
        },
        {
          id: 13,
          name: "Leo Da Vinci",
        },
        {
          id: 14,
          name: "Leo Da Vinci",
        },
        {
          id: 15,
          name: "Leo Da Vinci",
        },
        {
          id: 16,
          name: "Leo Da Vinci",
        },
        {
          id: 17,
          name: "Leo Da Vinci",
        },
        {
          id: 18,
          name: "Leo Da Vinci",
        },
        {
          id: 19,
          name: "Leo Da Vinci",
        },
        {
          id: 20,
          name: "Leo Da Vinci",
        },
        {
          id: 21,
          name: "Leo Da Vinci",
        },
        {
          id: 22,
          name: "Leo Da Vinci",
        },
        {
          id: 23,
          name: "Leo Da Vinci",
        },
        {
          id: 24,
          name: "Leo Da Vinci",
        },
        {
          id: 25,
          name: "Leo Da Vinci",
        },
        {
          id: 26,
          name: "Leo Da Vinci",
        },
        {
          id: 27,
          name: "Leo Da Vinci",
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      newItem: {
        artist_name: "",
        artist_name_kor: "",
        artist_image: "",
        artist_nationality_eng: "",
        artist_nationality_kor: "",
        artist_dob: "",
        artist_gender_eng: "",
        artist_gender_kor: "",
        artist_activity_area_eng: "",
        artist_activity_area_kor: "",
        artist_educational_background_eng: "",
        artist_educational_background_kor: "",
        artist_awards_eng: "",
        artist_awards_kor: "",
        artist_exhibitions_eng: "",
        artist_exhibitions_kor: "",
        artist_solo_exhibition_eng: "",
        artist_solo_exhibition_kor: "",
        artist_group_exhibition_eng: "",
        artist_group_exhibition_kor: "",
        artist_residency_eng: "",
        artist_residency_kor: "",
        artist_genre_eng: "",
        artist_genre_kor: "",
        artist_keywords_eng: "",
        artist_keywords_kor: "",
        artist_transactions_eng: "",
        artist_transactions_kor: "",
        artist_description_eng: "",
        artist_description_kor: "",
        artist_belonging_gallery_eng: "",
        artist_belonging_gallery_kor: "",
        artist_crawling_channel: "",
        artist_password: "",
      },
      actionType: "Add",
    };
  },
  methods: {
    checkedContains(id) {
      return this.checkedItems.includes(id);
    },
    checkNow(id) {
      if (this.checkedItems.includes(id)) {
        this.checkedItems = this.checkedItems.filter((item) => item !== id);
      } else {
        this.checkedItems.push(id);
      }
    },
    checkAllNow() {
      this.checkedItems = [];
      this.artWorks.forEach((artwork) => {
        this.checkedItems.push(artwork.id);
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.artWorks = this.$changePage(
        this.allartWorks,
        page,
        this.itemsPerPage
      );
      window.scrollTo(0, 0);
    },
    saveItem() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_ARTIST", { data: this.newItem, token: this.$getToken() })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminArtists" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_ARTIST", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminArtists" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },

    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTIST_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.newItem = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    changeNationality(type) {
      if (type == "eng") {
        const selectedCountry = this.$store.state.countries.find(
          (country) =>
            country.name === this.newItem.artist_nationality_eng
        );
        this.newItem.artist_nationality_kor = selectedCountry.korean_name;
      } else {
        const selectedCountry = this.$store.state.countries.find(
          (country) =>
            country.korean_name === this.newItem.artist_nationality_kor
        );
        this.newItem.artist_nationality_eng = selectedCountry.name;
      }
    },
  },
  mounted() {
    this.changePage(1);
    this.actionType = this.$route.params.actionType;
    if (this.actionType == "Edit") {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/dashboard.scss";
</style>