<template>
  <div class="Home">
    <site-navbar />
    <div class="page-content">
      <div class="page-body">
        <div class="items-container">
          <div class="items-header">
            <h3>Auction Houses</h3>
            <p>
              Connect with exclusive Auction Houses for extraordinary art finds.
            </p>
          </div>
          <div class="on-phone-search on-sm">
            <div class="search">
              <div class="form">
                <i class="fa-solid fa-search"></i>
                <input type="text" placeholder="Artist Name" />
              </div>
              <div class="filter">
                <i class="fa-solid fa-filter"></i>
                <p>Filter</p>
              </div>
            </div>
          </div>
          <div class="actionhouse-items-list">
            <router-link
              :to="{
                name: 'ViewAuctionHouses',
                params: {
                  id: item.auction_house_id,
                  name: $urlCompatible(item.auction_house_name_eng),
                },
              }"
              class="artist-item"
              v-for="item in items"
              :key="item.auction_house_id"
            >
              <auction-house-card :item="item" />
            </router-link>
          </div>
          <div class="loader" v-if="isLoading">
            <img :src="$img('loader.png')" alt="" class="load-img" />
          </div>
        </div>
        <pagination
          class="pagination"
          :totalItems="allResults.length"
          :itemsPerPage="itemsPerPage"
          :itemCount="false"
          @pageChanged="
            (page) => {
              changePage(page);
            }
          "
        />
      </div>
      <auction-house-filter
        class="on-lg"
        @filterData="
          (data) => {
            filterData = data;
          }
        "
      />
    </div>
    <site-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      allItems: [],
      items: [],
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 21,
      filterData: {
        auction_house_name_eng: "",
        auction_house_location_eng: "",
        selectedCategory: "All",
      },
      allResults: [],
    };
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >=
          document.documentElement.offsetHeight - 600;

        if (bottomOfWindow) {
          if (this.allItems.length != 0) {
            setTimeout(() => {
              this.items.push(
                ...this.allItems.splice(0, this.current_index + 10)
              );
              this.current_index = this.current_index + 10;
            }, 2000);
          } else {
            setTimeout(() => {
              console.log("done");
              this.isLoading = false;
            }, 2000);
          }
        }
      };
    },
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    openArtWork(item) {
      this.$router.push({
        name: "ViewArtWork",
        params: { id: item.id, name: item.name },
      });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AUCTIONHOUSES", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allItems = res.data;
          this.allResults = res.data;
          this.changePage(1);
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
        });
    },
    filterNow() {
      let results1 = [...this.allItems];
      let results2 = [];
      if (this.filterData.auction_house_name_eng != "") {
        results2 = results1.filter((item) => {
          return (
            item.auction_house_name_eng
              .toLowerCase()
              .includes(this.filterData.auction_house_name_eng.toLowerCase()) ||
            item.auction_house_name_kor
              .toLowerCase()
              .includes(this.filterData.auction_house_name_eng.toLowerCase())
          );
        });
      } else {
        results2 = results1;
      }

      // address_eng

      let results3 = [];
      if (this.filterData.auction_house_location_eng != "") {
        results3 = results2.filter((item) => {
          return (
            item.auction_house_location_eng
              .toLowerCase()
              .includes(
                this.filterData.auction_house_location_eng.toLowerCase()
              ) ||
            item.auction_house_location_kor
              .toLowerCase()
              .includes(
                this.filterData.auction_house_location_eng.toLowerCase()
              )
          );
        });
      } else {
        results3 = results2;
      }
      let results4 = [];
      if (this.filterData.selectedCategory != "All") {
        results4 = results3.filter((item) => {
          return item.auction_house_name_eng
            .toLowerCase()
            .startsWith(this.filterData.selectedCategory.toLowerCase());
        });
      } else {
        results4 = results3;
      }

      this.allResults = results4;

      this.currentPage = 1;
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.items = this.$changePage(this.allResults, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getItems();
    // this.scroll();
  },
  watch: {
    filterData: {
      handler: function (val) {
        this.filterNow();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    display: flex;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .page-body {
      width: calc(100% - 300px);
      padding-top: 2rem;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 250px);
      }
      @media screen and (max-width: 850px) {
        padding-top: 0rem;
        width: 100%;
      }
      .items-container {
        .items-header {
          h3 {
            color: #000000;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
          p {
            color: #333;
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            @media screen and (max-width: 1500px) {
              margin-top: 0.2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
        .on-phone-search {
          display: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          column-gap: 10px;
          @media screen and (max-width: 850px) {
            padding: 1rem;
            padding-left: 0px;
          }
          .search {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .form {
              input {
                width: 100%;
                padding: 0.5rem 1rem;
                border: 1px #adadad solid;
                border-radius: 5px;
                font-size: 1.25rem;
                padding-left: 2.5rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
              i {
                color: #adadad;
                font-size: 1.25rem;
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);

                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .filter {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 5px;
            background: $blue;
            color: #fff;
            padding: 10px 1.5rem;
            border-radius: 500px;
            position: relative;
            i {
              color: #fff;
              position: relative;
              font-size: 1.25rem;
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
        .loader {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3rem 0;
          img {
            -webkit-animation: spin 1s linear infinite;
            -moz-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
            @media screen and (max-width: 1500px) {
              width: 50px;
            }
          }
          @-moz-keyframes spin {
            100% {
              -moz-transform: rotate(360deg);
            }
          }
          @-webkit-keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}
</style>