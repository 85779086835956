<template>
  <div class="top-section">
    <div class="image">
      <img :src="$uploads(artist.artist_image)" alt="" @error="$imageurlalt('p')" />
    </div>
    <div class="desc">
      <h3>{{ artist.artist_name }}</h3>
      <h5 v-if="artist.artist_activity_area_eng || artist.artist_dob"><span v-if="artist.artist_activity_area_eng">{{ artist.artist_activity_area_eng }},</span> {{artist.artist_dob}}</h5>
      <h5 v-else>...</h5>
      <!-- <p v-if="artist.artist_gender_eng">Genre: {{ artist.artist_gender_eng }}</p> -->
    </div>
  </div>
  <div class="bottom-section">
    <router-link
      :to="{
        name: 'ViewArtist',
        params: {
          id: artist.artist_id,
          name: $urlCompatible(artist.artist_name),
        },
      }"
      >View Artist Info</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    artist: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>