<template>
  <div class="Home">
    <site-navbar />
    <div class="page-content">
      <div class="page-body">
        <div class="upload">
          <h2>Select any artwork/image to get results</h2>
          <div class="uploader-widget">
            <o-upload
              :multiple="false"
              :uploadUrl="'files/upload'"
              @imagesUploaded="
                (data) => {
                  newItem.imageUrl = data;
                  processImage();
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.imageUrl"
              @startLoader="() => $startLoader()"
            />
          </div>
        </div>
        <div class="image-analysis" v-if="visionResults">
          <div
            class="faces-section"
            v-if="
              visionResults.faceAnnotations &&
              visionResults.faceAnnotations.length
            "
          >
            <h2>Face Analysis</h2>
            <ul>
              <li>
                <strong>Face detected:</strong>
                {{
                  visionResults.faceAnnotations.length === 1
                    ? "A face"
                    : visionResults.faceAnnotations.length + " faces"
                }}
              </li>
              <li>
                <strong>Emotion:</strong>
                {{
                  getEmotionDescription(
                    visionResults.faceAnnotations[0].joyLikelihood
                  )
                }}
              </li>
              <!-- Add more face-related information as needed -->
            </ul>
          </div>

          <div class="label-section" v-if="labels && labels.length">
            <h2>Labels</h2>
            <ul>
              <li v-for="label in labels" :key="label.mid">
                <strong>{{ getLabelDescription(label.description) }}</strong>
                ({{ (label.score * 100).toFixed(2) }}%)
              </li>
            </ul>
          </div>

          <div
            class="colors-section"
            v-if="dominantColors && dominantColors.length"
          >
            <h2>Dominant Colors</h2>
            <div
              class="color-box"
              v-for="(color, index) in dominantColors"
              :key="index"
              :style="{ backgroundColor: getColorStyle(color) }"
            >
              {{ (color.score * 100).toFixed(2) }}%
            </div>
          </div>

          <div class="landmark-section" v-if="landmarks && landmarks.length">
            <h2>Landmarks</h2>
            <ul>
              <li v-for="landmark in landmarks" :key="landmark.mid">
                This appears to be <strong>{{ landmark.description }}</strong>
                <div class="map-location">
                  Latitude: {{ landmark.locations[0].latLng.latitude }},
                  Longitude: {{ landmark.locations[0].latLng.longitude }}
                </div>
              </li>
            </ul>
          </div>

          <div class="image-description">
            <h2>Image Description</h2>
            <p v-if="visionResults.faceAnnotations.length">
              This image appears to show
              {{
                getEmotionDescription(
                  visionResults.faceAnnotations[0].joyLikelihood
                )
              }}
            </p>
            <p v-if="labels.length">
              The image is likely to contain:
              <span v-for="label in labels" :key="label.mid">
                {{ getLabelDescription(label.description) }} ({{
                  (label.score * 100).toFixed(2)
                }}%),
              </span>
            </p>
            <!-- Add more conditions for other annotations -->
          </div>
        </div>
      </div>
    </div>
    <site-footer />
  </div>
</template>
<script>
export default {
  data() {
    return {
      visionResults: null,
      newItem: {
        imageUrl: "",
      },
    };
  },
  methods: {
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    openArtWork(item) {
      this.$router.push({
        name: "ViewArtWork",
        params: { id: item.id, name: item.name },
      });
    },
    processImage() {
      this.$startLoader();
      this.$store
        .dispatch("PROCESS_IMAGE", {
          token: this.$getToken(),
          data: {
            imageUrl: this.$uploads(this.newItem.imageUrl),
          },
        })
        .then((res) => {
          this.$stopLoader();
          this.visionResults = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
        });
    },
    getColorStyle(color) {
      return `rgba(${color.color.red}, ${color.color.green}, ${color.color.blue}, 1)`;
    },
    getEmotionDescription(likelihood) {
      switch (likelihood) {
        case "VERY_LIKELY":
          return "a happy person";
        case "LIKELY":
          return "a person likely to be happy";
        case "POSSIBLE":
          return "a person possibly happy";
        case "UNLIKELY":
          return "a person unlikely to be happy";
        case "VERY_UNLIKELY":
          return "a very unhappy person";
        default:
          return "a person";
      }
    },

    getLabelDescription(label) {
      return label.toLowerCase();
    },
  },
  mounted() {},
  computed: {
    labels() {
      return this.visionResults.labelAnnotations || [];
    },
    dominantColors() {
      return (
        this.visionResults.imagePropertiesAnnotation.dominantColors.colors || []
      );
    },
    landmarks() {
      return this.visionResults.landmarkAnnotations || [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    display: flex;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .page-body {
      width: calc(100%);
      padding-top: 2rem;
      @media screen and (max-width: 1500px) {
        width: calc(100%);
      }
      @media screen and (max-width: 850px) {
        width: 100%;
        padding-top: 0rem;
      }
      .upload {
        width: 100%;
        align-items: center;
        margin-bottom: 20px;
        h2 {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
      .image-analysis {
        display: flex;
        flex-direction: column;

        .faces-section {
          margin-bottom: 20px;

          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }

          ul {
            list-style: none;
            padding: 0;

            li {
              margin-bottom: 5px;
              font-size: 14px;

              strong {
                font-weight: bold;
              }
            }
          }
        }

        .label-section {
          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }

          ul {
            list-style: none;
            padding: 0;

            li {
              margin-bottom: 5px;
              font-size: 14px;

              strong {
                font-weight: bold;
              }
            }
          }
        }

        .colors-section {
          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }

          .color-box {
            width: 50px;
            height: 50px;
            margin-right: 10px;
            display: inline-block;
            border: 1px solid #ccc;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            font-size: 12px;
            text-align: center;
            line-height: 50px;
            color: #fff;
          }
        }
        .image-description {
          margin-top: 1rem;
        }
        .landmark-section {
          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }

          ul {
            list-style: none;
            padding: 0;

            li {
              margin-bottom: 10px;
              font-size: 14px;

              strong {
                font-weight: bold;
              }

              .map-location {
                font-size: 12px;
                color: #555;
              }
            }
          }
        }
      }
    }
  }
}
</style>
