<template>
  <div class="myCheckbox">
    <div class="checkbox-body">
      <div
        v-if="mainCheckbox"
        class="main"
        @click="UncheckAllNow()"
        :class="{ active: checked }"
      >
        <p v-show="checked"><i class="fa-solid fa-minus"></i></p>
      </div>
      <div
        v-else
        :class="{ active: checked }"
        @click="checkNow()"
        class="notMain"
      >
        <p v-show="checked"><i class="fa-solid fa-check"></i></p>
      </div>
    </div>
    <div class="text">
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    mainCheckbox: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },
  methods: {
    checkNow() {
      this.$emit("checkNow");
    },
    UncheckAllNow() {
      if (this.checked) {
        this.$emit("UncheckAllNow");
      } else {
        this.$emit("checkAllNow");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.myCheckbox {
  display: flex;
  align-items: center;
  .text {
    margin-left: 10px;
    p {
      font-size: 14px;
      color: #666;
    }
  }
}
.checkbox-body {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  .main,
  .notMain {
    width: 20px;
    height: 20px;
    // border: 1px solid #ccc;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
      font-size: 12px;
      color: #fff;
      i {
        font-size: 12px;
      }
    }
    &.active,
    &:hover {
      background-color: $blue;
      p {
        font-size: 12px;
        color: #fff;
        i {
          font-size: 12px;
        }
      }
    }
  }
}
</style>