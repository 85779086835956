import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const ADD_AUCTION_URL = API_URL + '/auctions/createAuction';
const GET_AUCTIONS_URL = API_URL + '/auctions/all';
const GET_AUCTION_BYID_URL = API_URL + '/auctions/';
const UPDATE_AUCTION_URL = API_URL + '/auctions/updateAuction';
const DELETE_AUCTION_URL = API_URL + '/auctions/deleteAuction';


const auctions = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_AUCTION']: ({ commit }, payload) => {
            return axios({
                url: ADD_AUCTION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_AUCTIONS']: ({ commit }, payload) => {
            return axios({
                url: GET_AUCTIONS_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_AUCTION_BYID']: ({ commit }, payload) => {
            return axios({
                url: GET_AUCTION_BYID_URL + payload.id,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['UPDATE_AUCTION']: ({ commit }, payload) => {
            return axios({
                url: UPDATE_AUCTION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_AUCTION']: ({ commit }, payload) => {
            return axios({
                url: DELETE_AUCTION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default auctions;