<template>
  <div class="Home">
    <site-navbar />
    <div class="page-content">
      <div class="page-body" v-if="selectedItem">
        <div class="auction-widget">
          <div class="auction-title">
            <h3>{{ selectedItem.auction_name_eng }}</h3>
          </div>
          <div class="auction-description-widget">
            <div class="labels">
              <h5>About Auction</h5>
              <div class="item-item" v-if="selectedItem.auction_date != ''">
                <span class="title">Date:</span>
                <span class="value">{{ selectedItem.auction_date }}</span>
              </div>
              <div class="item-item" v-if="selectedItem.auction_time != ''">
                <span class="title">Time:</span>
                <span class="value">{{ selectedItem.auction_time }}</span>
              </div>
              <div class="item-item" v-if="selectedItem.auction_location != ''">
                <span class="title">Location:</span>
                <span class="value">{{ selectedItem.auction_location }}</span>
              </div>
              <div class="item-item" v-if="selectedItem.slots != ''">
                <span class="title">Slots:</span>
                <span class="value">{{ selectedItem.slots }}</span>
              </div>
              <div class="item-item" v-if="selectedItem.auction_website != ''">
                <span class="title">Website:</span>
                <span class="value">{{ selectedItem.auction_website }}</span>
              </div>
              <div
                class="item-item"
                v-if="selectedItem.is_art_auction_house != ''"
              >
                <span class="title">Auction House:</span>
                <span class="value"
                  >{{ selectedItem.auction_house.auction_house_name_eng }},
                  {{ selectedItem.auction_house.auction_house_name_kor }}</span
                >
              </div>
              <div class="item-item" v-else>
                <span class="title">Auction House:</span>
                <span class="value"
                  >{{ selectedItem.auction_house_name_eng }},
                  {{ selectedItem.auction_house_name_kor }}
                </span>
              </div>

              <div class="rsvp">
                <bottun
                  @click="$redirect(selectedItem.auction_website)"
                  target="_blank"
                  class="btn btn-primary"
                  >RSVP</bottun
                >
              </div>
            </div>
            <div class="img">
              <img
                :src="$uploads(selectedItem.auction_image)"
                alt="auction house image"
              />
            </div>
          </div>
        </div>
        <div class="items-container">
          <div class="items-header">
            <p>RELATED AUCTIONS</p>
          </div>
          <div
            class="actions-items-list"
            v-if="selectedItem.related.length > 0"
          >
            <router-link
              :to="{
                name: 'ViewAuction',
                params: {
                  id: item.auction_id,
                  name: $urlCompatible(item.auction_name_eng),
                },
              }"
              class="event-item"
              v-for="item in selectedItem.related"
              :key="item.id"
            >
              <auction-card :item="item" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <site-footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      selectedItem: null,
    };
  },
  methods: {
    getSingleItem() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AUCTION_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.selectedItem = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
        });
    },
  },
  mounted() {
    this.getSingleItem();
  },
  watch: {
    $route() {
      this.getSingleItem();
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    display: flex;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
      margin-top: 100px;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
      margin-top: 80px;
    }
    .page-body {
      width: calc(100% - 300px);
      padding-top: 2rem;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 250px);
      }
      @media screen and (max-width: 850px) {
        padding-top: 0rem;
        width: 100%;
      }
      .auction-widget {
        background: #fff;
        border-radius: 10px;
        padding: 0 2rem;
        margin-bottom: 3rem;
        @media screen and (max-width: 1500px) {
          padding: 1rem;
        }
        @media screen and (max-width: 1100px) {
          padding: 1rem;
        }
        @media screen and (max-width: 850px) {
          padding: 1rem;
        }
        .auction-title {
          h3 {
            color: #000000;
            font-size: 3rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 2rem;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
        }
        .auction-description-widget {
          display: flex;
          justify-content: space-between;
          column-gap: 2rem;
          @media screen and (max-width: 1500px) {
            column-gap: 1rem;
          }
          @media screen and (max-width: 1100px) {
            column-gap: 1rem;
          }
          @media screen and (max-width: 850px) {
            flex-direction: column;
            column-gap: 0rem;
          }
          .labels {
            width: 50%;
            @media screen and (max-width: 850px) {
              width: 100%;
            }
            h5 {
              color: #000000;
              font-size: 1.1rem;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              margin-bottom: 1rem;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: .85rem;
              }
              @media screen and (max-width: 850px) {
                font-size: .75rem;
              }
            }
            .item-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 1rem;
              @media screen and (max-width: 1500px) {
                margin-bottom: 0.5rem;
              }
              @media screen and (max-width: 1100px) {
                margin-bottom: 0.5rem;
              }
              @media screen and (max-width: 850px) {
                margin-bottom: 0.5rem;
              }
              .title {
                color: #000000;
                font-size: 1.1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                @media screen and (max-width: 1500px) {
                  font-size: 1.25rem;
                }
                @media screen and (max-width: 1100px) {
                  font-size: 1.25rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 1.25rem;
                }
              }
              .value {
                color: #000000;
                font-size: 1.1rem;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                @media screen and (max-width: 1500px) {
                  font-size: 1.25rem;
                }
                @media screen and (max-width: 1100px) {
                  font-size: 1.25rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 1.25rem;
                }
              }
            }
          }
          .img {
            width: 50%;
            height: 300px;
            background: #f5f5f5;
            padding: 10px 0;
            @media screen and (max-width: 850px) {
              width: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 10px;
            }
          }
        }
      }
      .items-container {
        .items-header {
          h3 {
            color: #000000;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
          p {
            color: #333;
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            @media screen and (max-width: 1500px) {
              margin-top: 0.2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
        .on-phone-search {
          display: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          column-gap: 10px;
          @media screen and (max-width: 850px) {
            padding: 1rem;
            padding-left: 0px;
          }
          .search {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .form {
              input {
                width: 100%;
                padding: 0.5rem 1rem;
                border: 1px #adadad solid;
                border-radius: 5px;
                font-size: 1.25rem;
                padding-left: 2.5rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
              i {
                color: #adadad;
                font-size: 1.25rem;
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);

                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .filter {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 5px;
            background: $blue;
            color: #fff;
            padding: 10px 1.5rem;
            border-radius: 500px;
            position: relative;
            i {
              color: #fff;
              position: relative;
              font-size: 1.25rem;
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>