<template>
  <div class="dashboard">
    <div class="page-top-section">
      <div class="left">
        <h2>ADD EXHIBITION</h2>
        <div class="page-indication">
          <p>Exhibition</p>
          <i class="fa-solid fa-caret-right"></i>
          <p>Exhibition List</p>
          <i class="fa-solid fa-caret-right"></i>
          <p class="active">Add Exhibition</p>
        </div>
      </div>
      <div class="right">
        <div class="export-btn">
          <router-link :to="{ name: 'AdminExhibitions' }" class="btn">
            <i class="fa-solid fa-times"></i>
            <span>Cancel</span>
          </router-link>
        </div>
        <div class="add-btn">
          <button class="btn" @click="saveItem()" v-if="actionType == 'Add'">
            <i class="fa-solid fa-plus"></i>
            <span>Save</span>
          </button>
          <button class="btn" @click="updateItem()" v-else>
            <i class="fa-solid fa-save"></i>
            <span>Update</span>
          </button>
        </div>
      </div>
    </div>
    <div class="page-content-section">
      <div class="left">
        <div class="form">
          <div class="form-body">
            <div iv class="form-group">
              <div class="form-header">
                <h3>General Information</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artwork-name">Exhibition Name (ENG)</label>
                  <input
                    type="text"
                    id="artwork-name"
                    placeholder="Type Exhibition Name (ENG) here. . ."
                    v-model="newItem.exhibition_name_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-name">Exhibition Name (KOR)</label>
                  <input
                    type="text"
                    id="artwork-name"
                    placeholder="전시회 이름을 입력하세요. . ."
                    v-model="newItem.exhibition_name_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Participating Artists (ENG)</label
                  >
                  <textarea
                    type="text"
                    id="artwork-location"
                    placeholder="Participating Artists (ENG)"
                    rows="5"
                    v-model="newItem.exhibition_artists_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Participating Artists (KOR)</label
                  >
                  <textarea
                    type="text"
                    id="artwork-location"
                    placeholder="참여 작가"
                    rows="5"
                    v-model="newItem.exhibition_artists_kor"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Exhibition Gallery Name (ENG)</label
                  >
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Exhibition Gallery Name (ENG)"
                    v-model="newItem.exhibition_gallery_name_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Exhibition Gallery Name (KOR)</label
                  >
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="전시회 갤러리 이름"
                    v-model="newItem.exhibition_gallery_name_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Exhibition Location (ENG)</label
                  >
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Exhibition Location (ENG)"
                    v-model="newItem.exhibition_location_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Exhibition Location (KOR)</label
                  >
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="전시회 장소"
                    v-model="newItem.exhibition_location_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Exhibition Start Date</label>
                  <input
                    type="date"
                    id="artwork-location"
                    placeholder="Exhibition Start Date"
                    v-model="newItem.startDate"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Gallery Information (ENG)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Gallery Information (ENG)"
                    v-model="newItem.gallery_information_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Gallery Information (KOR)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="갤러리 정보"
                    v-model="newItem.gallery_information_kor"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Exhibition Schedule (ENG)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Exhibition Schedule (ENG)"
                    v-model="newItem.exhibition_schedule_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Exhibition Schedule (KOR)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="전시회 일정"
                    v-model="newItem.exhibition_schedule_kor"
                  ></textarea>
                </div>

                <div class="input-group-item">
                  <label for="artwork-location">Gallery URL</label>
                  <input
                    type="text"
                    placeholder="Gallery URL"
                    v-model="newItem.gallery_url"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Social Media Links</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Social Media Links"
                    v-model="newItem.social_media_links"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Participating Artists Information (ENG)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Participating Artists Information (ENG)"
                    v-model="newItem.artists_information_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Participating Artists Information (KOR)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="참여 작가 정보"
                    v-model="newItem.artists_information_kor"
                  ></textarea>
                </div>

                <div class="input-group-item">
                  <label for="artwork-location">Viewing Time (ENG)</label>
                  <input
                    type="text"
                    placeholder="Viewing Time (ENG)"
                    v-model="newItem.viewing_time_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Viewing Time (KOR)</label>
                  <input
                    type="text"
                    placeholder="전시회 관람 시간"
                    v-model="newItem.viewing_time_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Exhibition Description (ENG)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Exhibition Description (ENG)"
                    v-model="newItem.exhibition_description_eng"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Exhibition Description (KOR)</label
                  >
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="전시회 설명"
                    v-model="newItem.exhibition_description_kor"
                  ></textarea>
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Crawling Channel</label>
                  <input
                    type="text"
                    placeholder="Crawling Channel"
                    v-model="newItem.crawling_channel"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Closed Days (ENG)</label>
                  <input
                    type="text"
                    placeholder="Closed Days (ENG)"
                    v-model="newItem.closed_days_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Closed days (KOR)</label>
                  <input
                    type="text"
                    placeholder="휴관일"
                    v-model="newItem.closed_days_kor"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-header">
                <h3>Exhibition Image</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artist-name">Photo</label>
                  <o-upload
                    :multiple="false"
                    :uploadUrl="'files/upload'"
                    @imagesUploaded="
                      (data) => {
                        newItem.exhibition_image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.exhibition_image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allartWorks: [
        {
          id: 1,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "red",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Sold",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 2,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "pink",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 3,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "yellow",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 4,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Green",
          salesPrice: "590.00",
          hammerPrice: "N/A",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
        {
          id: 5,
          artwork: "Monalisa",
          uciCode: "302012",
          artistName: "Leo Da vinci",
          dimensions: "200 W * 160 H * 2 D cm",
          color: "Orange",
          salesPrice: "N/A",
          hammerPrice: "121.00",
          status: "Available",
          creationYear: "29 Dec 2000",
          image: "5.jpg",
          location: "Korea",
        },
      ],
      artWorks: [],
      checkedItems: [],
      artists: [
        {
          id: 1,
          name: "Leo Da Vinci",
        },
        {
          id: 2,
          name: "Leo Da Vinci",
        },
        {
          id: 3,
          name: "Leo Da Vinci",
        },
        {
          id: 4,
          name: "Leo Da Vinci",
        },
        {
          id: 5,
          name: "Leo Da Vinci",
        },
        {
          id: 6,
          name: "Leo Da Vinci",
        },
        {
          id: 7,
          name: "Leo Da Vinci",
        },
        {
          id: 8,
          name: "Leo Da Vinci",
        },
        {
          id: 9,
          name: "Leo Da Vinci",
        },
        {
          id: 10,
          name: "Leo Da Vinci",
        },
        {
          id: 11,
          name: "Leo Da Vinci",
        },
        {
          id: 12,
          name: "Leo Da Vinci",
        },
        {
          id: 13,
          name: "Leo Da Vinci",
        },
        {
          id: 14,
          name: "Leo Da Vinci",
        },
        {
          id: 15,
          name: "Leo Da Vinci",
        },
        {
          id: 16,
          name: "Leo Da Vinci",
        },
        {
          id: 17,
          name: "Leo Da Vinci",
        },
        {
          id: 18,
          name: "Leo Da Vinci",
        },
        {
          id: 19,
          name: "Leo Da Vinci",
        },
        {
          id: 20,
          name: "Leo Da Vinci",
        },
        {
          id: 21,
          name: "Leo Da Vinci",
        },
        {
          id: 22,
          name: "Leo Da Vinci",
        },
        {
          id: 23,
          name: "Leo Da Vinci",
        },
        {
          id: 24,
          name: "Leo Da Vinci",
        },
        {
          id: 25,
          name: "Leo Da Vinci",
        },
        {
          id: 26,
          name: "Leo Da Vinci",
        },
        {
          id: 27,
          name: "Leo Da Vinci",
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      newItem: {
        exhibition_name_eng: "",
        exhibition_name_kor: "",
        exhibition_artists_eng: "",
        exhibition_artists_kor: "",
        exhibition_gallery_name_eng: "",
        exhibition_gallery_name_kor: "",
        exhibition_location_eng: "",
        exhibition_location_kor: "",
        gallery_information_eng: "",
        gallery_information_kor: "",
        exhibition_schedule_eng: "",
        exhibition_schedule_kor: "",
        gallery_url: "",
        social_media_links: "",
        artists_information_eng: "",
        artists_information_kor: "",
        viewing_time_eng: "",
        viewing_time_kor: "",
        exhibition_description_eng: "",
        exhibition_description_kor: "",
        crawling_channel: "",
        closed_days_eng: "",
        closed_days_kor: "",
        exhibition_image: "",
      },
      actionType: "Add",
    };
  },
  methods: {
    checkedContains(id) {
      return this.checkedItems.includes(id);
    },
    checkNow(id) {
      if (this.checkedItems.includes(id)) {
        this.checkedItems = this.checkedItems.filter((item) => item !== id);
      } else {
        this.checkedItems.push(id);
      }
    },
    checkAllNow() {
      this.checkedItems = [];
      this.artWorks.forEach((artwork) => {
        this.checkedItems.push(artwork.id);
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.artWorks = this.$changePage(
        this.allartWorks,
        page,
        this.itemsPerPage
      );
      window.scrollTo(0, 0);
    },
    saveItem() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_EXHIBITION", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminExhibitions" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_EXHIBITION", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminExhibitions" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },

    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_EXHIBITION_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.newItem = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.actionType = this.$route.params.actionType;
    if (this.actionType == "Edit") {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/dashboard.scss";
</style>