<template>
  <div class="Home">
    <site-navbar />
    <div class="page-content">
      <div class="jumbotron">
        <div class="left">
          <h1>ART OCEAN</h1>
          <div class="ps">
            <p>{{ $txt("Where Art Unites.") }}</p>
            <p>{{ $txt("A Global Canvas Online.") }}</p>
          </div>
          <!-- <router-link :to="{ name: 'Signup' }">Join Us</router-link> -->
        </div>
        <div class="right">
          <div class="slides">
            <carousel
              :autoplay="2000"
              :wrap-around="true"
              :items-to-show="1.2"
              v-if="slides.length > 0"
              class="hooper"
            >
              <Slide
                v-for="myslide in slides"
                :key="myslide.id"
                class="myslide"
              >
                <div class="slide-img">
                  <img :src="$img(myslide.src)" alt="" />
                  <div class="content">
                    <h2>{{ myslide.title }}</h2>
                    <p>{{ myslide.description }}</p>
                  </div>
                </div>
              </Slide>
            </carousel>
          </div>
        </div>
      </div>
      <div class="images-container">
        <div class="images-header">
          <h3>Explore</h3>
        </div>
        <div class="image-list">
          <div
            class="image-item"
            v-for="(item, i) in images"
            :key="i"
            :class="item.resizes"
            @click="
              selectedItem = item;
              viewPopup = true;
            "
          >
            <img
              :src="$uploads(item.artwork_images[0])"
              alt=""
              class="art-img"
            />
            <!-- <div class="profile">
              <div class="img">
                <img :src="$uploads(item.artist.artist_image)" alt="" v-if="item.is_art_artist"  @error="$imageurlalt('p')"/>
                <img :src="'dfsd'" alt="" v-else @error="$imageurlalt('p')" />
              </div>
            </div> -->
          </div>
        </div>
        <div class="loader" v-if="isLoading">
          <img :src="$img('loader.png')" alt="" class="load-img" />
        </div>
      </div>
    </div>
    <model v-if="viewPopup" @closeModel="viewPopup = false" :size="'md'">
      <div class="model-content">
        <div class="model-head">
          <div class="left">
            <label for="Image details">{{ selectedItem.title_eng }}</label>
            <p>Creation year: {{ selectedItem.creation_year }}</p>
            <p>{{ selectedItem.size }}</p>
          </div>
          <div class="right">
            <label for="Image details" v-if="selectedItem.sale_price"
              >SALE PRICE</label
            >
            <label for="Image details" v-else>HAMMER PRICE</label>
            <p class="price" v-if="selectedItem.sale_price">
              {{ $comma(selectedItem.sale_price) }}
              {{ selectedItem.sale_price_currency }}
            </p>
            <p class="price" v-else>
              {{ $comma(selectedItem.hammer_price) }}
              {{ selectedItem.hammer_price_currency }}
            </p>
          </div>
        </div>
        <div class="model-body">
          <div class="img-widget">
            <img :src="$uploads(selectedItem.artwork_images[0])" alt="" />
          </div>
          <div class="profile-widget">
            <div class="profile-img">
              <img
                :src="$uploads(selectedItem.artist.artist_image)"
                alt=""
                class="user"
                v-if="selectedItem.is_art_artist"
                @error="$imageurlalt('p')"
              />
              <img
                :src="'dsf'"
                alt=""
                class="user"
                v-else
                @error="$imageurlalt('p')"
              />
              <div class="profile-desc">
                <router-link
                  :to="{
                    name: 'Artists',
                    query: {
                      artist: encodeURIComponent(
                        selectedItem.artist.artist_name
                      ),
                    },
                  }"
                  for="artist name"
                  v-if="selectedItem.is_art_artist"
                  >{{ selectedItem.artist.artist_name }}</router-link
                >
                <router-link
                  :to="{
                    name: 'Artists',
                    query: {
                      artist: encodeURIComponent(selectedItem.artist_name_eng),
                    },
                  }"
                  for="artist name"
                  v-else
                  >{{ selectedItem.artist_name_eng }}</router-link
                >
                <p>
                  <img :src="$img('loc.png')" alt="" />{{
                    selectedItem.artwork_location_eng
                  }}
                </p>
              </div>
            </div>
            <div class="purchase on-lg">
              <router-link
                :to="{
                  name: `ViewArtWork`,
                  params: {
                    id: selectedItem.artwork_id,
                    name: $urlCompatible(selectedItem.title_eng),
                  },
                }"
                >{{ $txt(`View Artwork`) }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="model-footer on-sm">
          <router-link
            :to="{
              name: `ViewArtWork`,
              params: {
                id: selectedItem.artwork_id,
                name: $urlCompatible(selectedItem.title_eng),
              },
            }"
          >
            {{ $txt(`View Artwork`) }}
          </router-link>
          <button class="danger" @click="closeView()">
            {{ $txt(`Close`) }}
          </button>
        </div>
      </div>
    </model>
    <site-footer />
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      viewPass: "password",
      slides: [
        {
          id: 1,
          src: "slide11.jpg",
          title: "Paintings",
          description: "Purchase and sell the best in contemporary art online.",
        },
        {
          id: 2,
          src: "slide22.jpg",
          title: "Canvas",
          description: "Explore the world's largest online art galleries.",
        },
        {
          id: 3,
          src: "slide33.jpg",
          title: "Wall Art",
          description: "Buy original art by emerging artists in Korea.",
        },
      ],
      hooperSettings: {
        itemsToShow: 1.3,
        itemsToScroll: 1,
        wrapAround: true,
        snapAlign: "center",
        transition: 300,
        autoplay: true,
        touchDrag: true,
      },
      allItems: [],
      images: [],
      current_index: 0,
      isLoading: true,
      viewPopup: false,
      selectedItem: null,
    };
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >=
          document.documentElement.offsetHeight - 600;

        if (bottomOfWindow) {
          if (this.allItems.length != 0) {
            setTimeout(() => {
              this.images.push(
                ...this.allItems.splice(0, this.current_index + 10)
              );
              this.current_index = this.current_index + 10;
            }, 2000);
          } else {
            setTimeout(() => {
              console.log("done");
              this.isLoading = false;
            }, 2000);
          }
        }
      };
    },
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTWORKS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allItems = res.data;
          this.resize();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    resize() {
      this.allItems.forEach((element) => {
        element.artwork_images = JSON.parse(element.artwork_images);
        const img = new Image();
        img.src = this.$uploads(element.artwork_images[0]);
        img.onload = function () {
          const height = img.height;
          const width = img.width;
          let ratio = height / width;
          if (ratio < 0.6) {
            element.resizes = "short";
          } else if (ratio < 1.3) {
            element.resizes = "square";
          } else {
            element.resizes = "tall";
          }
        };
      });

      this.images.push(...this.allItems.splice(0, 10));
      this.current_index = 10;
    },
  },
  mounted() {
    this.getItems();
    this.scroll();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  .page-content {
    .jumbotron {
      display: grid;
      grid-template-columns: 30% 70%;
      min-height: 700px;
      padding: 5rem 7rem;
      align-items: center;
      padding: 1rem 15rem;
      @media screen and (max-width: 1500px) {
        padding: 1rem 5rem;
        min-height: 500px;
      }
      @media screen and (max-width: 1100px) {
        padding: 7rem 5rem;
        min-height: auto;
      }
      @media screen and (max-width: 850px) {
        padding: 2rem 1rem;
        grid-template-columns: 100%;
      }
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        @media screen and (max-width: 850px) {
          align-items: center;
        }
        h1 {
          font-size: 5rem;
          font-weight: 300;
          line-height: 1;
          @media screen and (max-width: 1500px) {
            font-size: 3.5rem;
            font-weight: 400;
          }
          @media screen and (max-width: 1100px) {
            font-size: 3rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 2.5rem;
          }
        }
        .ps {
          margin: 2rem 0;
          p {
            font-size: 2rem;
            font-weight: 300;
            @media screen and (max-width: 1500px) {
              font-size: 1.4rem;
              font-weight: 300;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.5rem;
            }
            @media screen and (max-width: 850px) {
              text-align: center;
              font-size: 1.5rem;
            }
          }
        }
        a {
          padding: 1rem 4rem;
          color: #eeeeeedc;
          text-transform: uppercase;
          font-size: 1.5rem;
          background: $blue;
          border-radius: 10px;
          display: inline-block;
          font-weight: 600;
          @media screen and (max-width: 1500px) {
            font-size: 1.2rem;
            padding: 0.5rem 2rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1.25rem;
            padding: 0.9rem 3rem;
          }
          @media screen and (max-width: 850px) {
            padding: 0.5rem 3rem;
            margin-bottom: 2rem;
          }
        }
      }
      .right {
        .slides {
          width: 100%;
          .hooper {
            height: 500px;
            @media screen and (max-width: 1500px) {
              height: 300px;
            }
            @media screen and (max-width: 1100px) {
              height: 400px;
            }
            @media screen and (max-width: 850px) {
              height: 300px;
            }

            .myslide {
              border: none !important;
              background: none !important;
              height: 100%;
              position: relative;
              background: transparent !important;
              transform: scale(0.9);
              .slide-img {
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 10px !important;
                overflow: hidden !important;
                img {
                  object-fit: cover;
                  border: none;
                  height: 500px;
                  width: 100%;
                  object-fit: cover;
                  border-radius: 10px !important;
                  overflow: hidden !important;
                  @media screen and (max-width: 1500px) {
                    height: 300px;
                  }
                  @media screen and (max-width: 1100px) {
                    height: 400px;
                  }
                  @media screen and (max-width: 850px) {
                    height: 250px;
                  }
                }
                .content {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: calc(100% - 1rem);
                  padding: 2rem;
                  background: rgb(0, 0, 0);
                  background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.7287289915966386) 0%,
                    rgba(0, 0, 0, 0.4962359943977591) 30%,
                    rgba(0, 0, 0, 0.192) 50%,
                    rgba(0, 0, 0, 0) 100%
                  );
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-start;
                  flex-direction: column;
                  @media screen and (max-width: 1100px) {
                    padding: 1.5rem;
                  }
                  @media screen and (max-width: 850px) {
                    padding: 1.25rem;
                  }
                  h2 {
                    color: white;
                    font-size: 2.5rem;
                    font-weight: 600;
                    @media screen and (max-width: 1500px) {
                      font-size: 1.8rem;
                    }
                    @media screen and (max-width: 1100px) {
                      font-size: 2rem;
                    }
                    @media screen and (max-width: 850px) {
                      font-size: 1.5rem;
                    }
                  }
                  p {
                    color: #c7c7c7;
                    font-size: 1.75rem;
                    font-weight: 400;
                    text-align: left;
                    @media screen and (max-width: 1500px) {
                      font-size: 1rem;
                    }
                    @media screen and (max-width: 1100px) {
                      font-size: 1.25rem;
                    }
                    @media screen and (max-width: 850px) {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }

            .carousel__slide--active {
              .slide-img {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
    .images-container {
      padding: 1rem 15rem;
      @media screen and (max-width: 1500px) {
        padding: 1rem 5rem;
      }
      @media screen and (max-width: 1100px) {
        padding: 1rem 5rem;
      }
      @media screen and (max-width: 850px) {
        padding: 1rem 1rem;
      }
      .images-header {
        h3 {
          color: #00000099;
          font-size: 34px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          @media screen and (max-width: 1500px) {
            font-size: 30px;
          }
          @media screen and (max-width: 1100px) {
            font-size: 27px;
          }
          @media screen and (max-width: 850px) {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 1rem;
          }
        }
      }
      .image-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 2rem;
        overflow: auto;
        @media screen and (max-width: 1500px) {
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
        .image-item {
          width: 100%;
          position: relative;
          cursor: pointer;
          &.short {
            grid-column: span 1;
            // grid-row: span 1/2;
          }
          &.square {
            grid-row: span 1;
            grid-column: span 1;
          }
          &.tall {
            grid-row: span 2;
            grid-column: span 1;
          }
          .art-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .profile {
            position: absolute;
            bottom: 2rem;
            left: 1rem;
            img {
              width: 60px;
              height: 60px;
              border: 3px $blue solid;
              border-radius: 50%;
              object-fit: cover;
              box-shadow: 0px 0px 20px 10px #33333341;
              @media screen and (max-width: 1500px) {
                width: 60px;
                height: 60px;
              }
              @media screen and (max-width: 1100px) {
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width: 850px) {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
      .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem 0;
        img {
          -webkit-animation: spin 1s linear infinite;
          -moz-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
          @media screen and (max-width: 1500px) {
            width: 50px;
          }
        }
        @-moz-keyframes spin {
          100% {
            -moz-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }
  }
  .model-content {
    @media screen and (max-width: 850px) {
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .model-head {
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      @media screen and (max-width: 1100px) {
        padding: 0 1rem;
      }
      @media screen and (max-width: 850px) {
        padding: 0 1rem;
      }
      label {
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #333333d2;
        font-weight: 600;
        @media screen and (max-width: 1500px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 1.25rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 1rem;
        }
      }
      p {
        color: #333;
        font-weight: 500;
        font-size: 0.9rem;
        @media screen and (max-width: 1500px) {
          font-size: 0.75rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 0.75rem;
        }
      }
      .right {
        display: flex;
        text-align: right;
        flex-direction: column;
        .price {
          font-size: 1.5rem;
          text-align: right;
          font-weight: 300;
        }
      }
    }
    .model-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @media screen and (max-width: 1500px) {
        padding-bottom: 0rem;
      }
      @media screen and (max-width: 850px) {
        padding-bottom: 10rem;
      }
      .img-widget {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
        max-height: 50vh;
        @media screen and (max-width: 850px) {
          display: block;
          height: auto;
          width: 100vw;
          max-height: fit-content;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          margin-bottom: 0rem;
          @media screen and (max-width: 1500px) {
            margin-bottom: 0rem;
          }
          @media screen and (max-width: 850px) {
            height: auto;
            overflow-y: auto;
            overflow-x: auto;
          }
        }
      }
      .profile-widget {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2rem 1rem;
        @media screen and (max-width: 1500px) {
          margin-bottom: 0;
          margin-top: 1rem;
        }
        @media screen and (max-width: 850px) {
          flex-direction: column;
          margin: 1rem 0.5rem;
        }
        .profile-img {
          display: flex;
          align-items: center;
          @media screen and (max-width: 850px) {
            // margin: 2rem 0;
          }
          img.user {
            height: 80px;
            width: 80px;
            object-fit: cover;
            border: 5px $blue solid;
            border-radius: 50%;
            @media screen and (max-width: 1500px) {
              height: 60px;
              width: 60px;
              border: 3px $blue solid;
            }
            @media screen and (max-width: 1100px) {
              height: 70px;
              width: 70px;
              border: 4px $blue solid;
            }
            @media screen and (max-width: 850px) {
              height: 50px;
              width: 50px;
              border: 2px $blue solid;
            }
          }
          .profile-desc {
            margin: 0 2rem;
            @media screen and (max-width: 850px) {
              margin: 0 1rem;
            }
            label, a {
              font-size: 1.5rem;
              font-weight: 700;
              text-transform: uppercase;
              color: black;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 1.25rem;
                font-weight: 700;
              }
              @media screen and (max-width: 850px) {
                font-size: 1rem;
                font-weight: 600;
              }
            }
            p {
              display: flex;
              align-items: center;
              column-gap: 10px;
              color: #333;
              @media screen and (max-width: 1500px) {
                font-size: 0.75rem;
                img {
                  width: 20px;
                }
              }
              @media screen and (max-width: 1100px) {
                font-size: 0.9rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.7rem;
                img {
                  width: 15px;
                }
              }
            }
          }
        }
        .purchase {
          button,
          a {
            padding: 1rem 3rem;
            background: $blue;
            color: white;
            font-size: 1.35rem;
            border-radius: 10px;
            display: inline-block;
            @media screen and (max-width: 1500px) {
              padding: 0.7rem 2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 1100px) {
              margin: 1rem 0;
              padding: 0.5rem 2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 850px) {
              padding: 0.5rem 2rem;
              font-size: 1rem;
              width: 100%;
              border-radius: 3px;
            }
          }
        }
      }
    }
    .model-footer {
      position: fixed;
      background: white;
      padding: 1rem;
      display: flex;
      align-items: center;
      bottom: 0;
      left: 0;
      width: 100%;
      justify-content: space-between;
      box-shadow: 1px 0px 10px 5px #00000038;
      button,
      a {
        padding: 0.5rem 1rem;
        background: $blue;
        color: white;
        font-size: 0.9rem;
        border-radius: 3px;
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>