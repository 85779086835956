<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">ANALYTICS</h1>
      <div class="top-dash">
        <div class="arc dash-item">
          <div class="dash-item-header">
            <div class="left">
              <h2>Target</h2>
              <p>Visitors target</p>
            </div>
          </div>
          <div class="dash-item-body percentageage-arc">
            <div class="circular-progress">
              <div class="circular-progress-circle">
                <div
                  class="segment"
                  style="transform: rotate(0deg) skew(0deg)"
                ></div>
                <div
                  class="segment"
                  style="transform: rotate(90deg) skew(0deg)"
                ></div>
                <div
                  class="segment"
                  style="transform: rotate(180deg) skew(0deg)"
                ></div>
                <div
                  class="segment"
                  style="transform: rotate(270deg) skew(90deg)"
                ></div>
              </div>
              <div class="circular-progress-inner"></div>
              <div class="circular-progress-value">
                <p>75.55 %</p>
                <span>+10%</span>
              </div>
            </div>
            <div class="arc-text">
              <p>
                You succeed gain <b>28</b> visits today, its higher than
                yesterday
              </p>
            </div>
            <div class="arc-stats">
              <div class="stat-item">
                <div class="top">
                  <p>Target</p>
                </div>
                <div class="bottom">
                  <span>200</span>
                  <i class="fas fa-arrow-down"></i>
                </div>
              </div>
              <div class="stat-item">
                <div class="top">
                  <p>Resuts</p>
                </div>
                <div class="bottom">
                  <span>158</span>
                  <i class="fas fa-arrow-up"></i>
                </div>
              </div>
              <div class="stat-item">
                <div class="top">
                  <p>Today</p>
                </div>
                <div class="bottom">
                  <span>28</span>
                  <i class="fas fa-arrow-up"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dash-item">
          <div class="dash-item-header">
            <div class="left">
              <h2>STATISTICS</h2>
              <p>Visitor Insight</p>
            </div>
          </div>
          <div class="dash-item-body">
            <canvas id="myChart" width="400" height="400"></canvas>
          </div>
        </div>
        <div class="dash-item">
          <div class="dash-item-header">
            <div class="left">
              <h2>Calendar</h2>
            </div>
          </div>
          <div class="dash-item-body">
            <VDatePicker
              v-model="selectedDate"
              transparent
              borderless
              expanded
              :color="'sky-blue'"
            />
          </div>
        </div>
      </div>
      <h1 class="page-title mt-5">PAGES INSIGHTS</h1>
      <div class="bottom-dash">
        <div
          class="dash-item"
          v-for="(insight, index) in insights"
          :key="index"
        >
          <div class="left">
            <p class="title">{{ insight.title }}</p>
            <p class="count">{{ $comma(insight.count) }}</p>
            <div class="today">
              <div class="percentage">
                <span>{{ insight.percentage }}%</span>
                <i class="fas fa-carret-up"></i>
              </div>
              <div class="people">+ {{insight.today}} today</div>
            </div>
          </div>
          <div class="right">
            <div class="blue-dot">
              <div class="inner-dot"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  data() {
    return {
      selectedDate: new Date(),
      insights: [
        {
          title: "Artworks",
          count: 6780,
          today: 678,
          percentage: 10,
        },
        {
          title: "Artists",
          count: 6780,
          today: 678,
          percentage: 10,
        },
        // GALLERIES, AUCTION HOUSES, AUCTION, EXHIBIT, ARTICLES
        {
          title: "Galleries",
          count: 340,
          today: 678,
          percentage: 10,
        },
        {
          title: "Auction Houses",
          count: 84,
          today: 678,
          percentage: 10,
        },
        {
          title: "Auction",
          count: 6780,
          today: 678,
          percentage: 10,
        },
        {
          title: "Exhibit",
          count: 340,
          today: 678,
          percentage: 10,
        },
        {
          title: "Articles",
          count: 1200,
          today: 678,
          percentage: 10,
        },
      ],
    };
  },
  methods: {
    
    getDashes() {
      this.$startLoader();
      this.$store
        .dispatch("GET_DASHBOARD", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.insights = res.data.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    const ctx = document.getElementById("myChart").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Visitors",
            data: [200, 210, 195, 215, 120, 230, 140, 225, 210, 205, 210, 222],
            backgroundColor: ["rgba(36, 184, 204)"],
            borderColor: ["rgba(36, 184, 204)"],
            borderWidth: 2,
            tension: 0.5,
          },
          {
            label: "Views",
            data: [190, 200, 175, 200, 200, 212, 200, 110, 195, 202, 198, 203],
            backgroundColor: ["#FF0000"],
            borderColor: ["#FF0000"],
            borderWidth: 2,
            tension: 0.5,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    this.getDashes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    padding: 0 20px;
    .top-dash {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      column-gap: 2rem;
      .dash-item {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        .dash-item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 2rem;
          padding-bottom: 0;
          .left {
            h2 {
              font-size: 1.5rem;
              font-weight: 600;
            }
            p {
              font-size: 0.9rem;
              color: #888;
            }
          }
        }
        .dash-item-body {
          padding: 20px;
          border-radius: 10px;

          .circular-progress {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;

            .circular-progress-circle {
              position: relative;
              width: 170px;
              height: 170px;
              background: transparent;
              border-radius: 50%;
              overflow: hidden;
            }

            .segment {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100vw;
              height: 100vw;
              background: $blue;
              transform-origin: 0 0;
            }

            .circular-progress-inner {
              position: absolute;
              left: 50%;
              top: 50%;
              width: 150px;
              height: 150px;
              transform: translateX(-50%) translateY(-50%);
              background: #fff;
              border-radius: 50%;
            }

            .circular-progress-value {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              p {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 5px;
              }
              span {
                font-size: 0.9rem;
                color: $blue;
                background: rgba(36, 184, 204, 0.15) !important;
                padding: 3px 5px;
                display: inline-block;
                border-radius: 5px;
                font-weight: 600;
              }
            }
          }
          .arc-text {
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 0 2rem;
            p {
              font-size: 0.9rem;
              color: #000;
              text-align: center;
            }
          }
          .arc-stats {
            display: flex;
            justify-content: space-between;
            .stat-item {
              width: 30%;
              padding: 10px;
              border-radius: 5px;
              background-color: #fff;
              .top {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                p {
                  font-size: 0.9rem;
                  color: #646464;
                }
              }
              .bottom {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                column-gap: 5px;
                span {
                  font-size: 1.2rem;
                  font-weight: 600;
                }
                i {
                  font-size: 0.9rem;
                  color: $blue;
                }
              }
            }
          }

          #myChart {
            width: 100%;
            max-height: 300px;
          }
        }
      }
    }
    .bottom-dash {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
      margin-top: 20px;
      flex-wrap: wrap;
      .dash-item {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        border-radius: 10px;
        .left {
          .title {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 5px;
            text-transform: uppercase;
            color: #666;
          }
          .count {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 5px;
          }
          .today {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 10px;
            .percentage {
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 5px;
              span {
                font-size: 0.9rem;
                color: $blue;
                font-weight: 600;
              }
              i {
                font-size: 0.9rem;
                color: $blue;
              }
            }
            .people {
              font-size: 0.9rem;
              color: #888;
              font-weight: 500;
            }
          }
        }
        .right {
          .blue-dot {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: dot 1s infinite;
            @keyframes dot {
              0% {
                background: transparent;
              }
              50% {
              }
              100% {
                background-color: rgba(36, 184, 204, 0.24);
              }
            }
            .inner-dot {
              width: 17px;
              height: 17px;
              border-radius: 50%;
              background-color: $blue;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.137);
            }
          }
        }
      }
    }
  }
}
</style>