<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    $txt(txt) {
      const lang = localStorage.getItem("art_lang");
      if (!lang) {
        localStorage.setItem("art_lang", "en");
        const lang = "en";
      }
      if (lang === "en") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.en == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.en;
        }
      }
      if (lang === "kny") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.en == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.kny;
        }
      }
      if (lang === "fr") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.fr == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.fr;
        }
      }
    },
    $changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    $getCountries() {
      this.$store.dispatch("GET_COUNTRIES").then((res) => {
        this.$store.state.countries = res.data.data.data;
      });
    },
    $userLogin(user, token) {
      localStorage.setItem("artAd_user", JSON.stringify(user));
      localStorage.setItem("artAd_token", token);
      // this.$router.push({ name: "AdminDashboard" });
    },
    $userLogout() {
      // let userToken = this.$getToken();
      localStorage.removeItem("artAd_user");
      localStorage.removeItem("artAd_token");
      // this.$store
      //   .dispatch("LOGOUT_USER", { token: userToken })
      //   .then((res) => {
      //     if (res.data.status) {
      //       this.$notify({
      //         title: "Success",
      //         type: "success",
      //         text: res.data.data.message,
      //       });
      //       this.$router.push({ name: "AdminLogin" });
      //     } else {
      //       this.$notify({
      //         title: "Error",
      //         type: "error",
      //         text: res.data.error,
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     this.$router.push({ name: "AdminLogin" });
      //   });
      this.$router.push({ name: "AdminLogin" });
    },
    $getUser() {
      let user = localStorage.getItem("artAd_user");
      if (user != undefined && user != null) {
        return JSON.parse(user);
      } else {
        return false;
      }
    },
    $getToken() {
      return localStorage.getItem("artAd_token");
    },
    $isSuperAdmin() {
      let user = this.$getUser();
      if (user.userRole.roleName == "Super Administrator") {
        return true;
      } else {
        return false;
      }
    },
    $isAdmin() {
      let user = this.$getUser();
      if (user.userRole.roleName == "Administrator") {
        return true;
      } else {
        return false;
      }
    },
    onCancel() {
      let loader = useLoading();
      loader.hide();
    },
    $startLoader() {
      this.$store.state.isLoading = true;
    },
    $stopLoader() {
      this.$store.state.isLoading = false;
    },
    $urlCompatible(url) {
      if (!url) {
        return;
      }
      return url.replace(/\s/g, "-");
    },
    $urlCompatibleRollBack(url) {
      if (!url) {
        return;
      }
      return url.replace(/-/g, " ");
    },
    $toggleSANav() {
      this.$store.state.showSANav = !this.$store.state.showSANav;
      localStorage.setItem("showSANav", this.$store.state.showSANav);
    },
    $urlContains(id) {
      return this.$route.path.includes(id);
    },
    $comma(amount) {
      if (!amount) {
        return;
      }
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    $dateToDay(date) {
      if (!date) {
        return;
      }
      // convert date like this 2021-10-12  to this format Saturday, 07,01,2023
      let newDate = new Date(date);
      let day = newDate.getDay();
      let month = newDate.getMonth();
      let year = newDate.getFullYear();
      let dayName = newDate.toLocaleString("default", { weekday: "long" });
      let monthName = newDate.toLocaleString("default", { month: "long" });
      return `${day} ${monthName}, ${year}`;
    },
    $imageurlalt(type = "none") {
      if (type == "p") {
        event.target.src = "/assets/images/av.jpg";
        return;
      }
      event.target.src = "https://via.placeholder.com/300";
    },
    $changePage(allItems, page, itemsPerPage) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return allItems.slice(startIndex, endIndex);
    },
    $img(image) {
      return "/assets/images/" + image;
    },

    $uploads(image) {
      return this.$store.state.apiUploadUrl + image;
    },
    $price(price) {
      if (isNaN(price)) {
        return price;
      } else {
        return "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    $today() {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    $date(date, type = "date") {
      if (!date) {
        return;
      }
      let newDate = new Date(date);
      let day = newDate.getDay();
      let month = newDate.getMonth();
      let year = newDate.getFullYear();
      let dayName = newDate.toLocaleString("default", { weekday: "long" });
      let dayNameShort = newDate.toLocaleString("default", {
        weekday: "short",
      });
      let dayNumber = newDate.toLocaleString("default", { day: "numeric" });
      // add 0 before day if less than 10
      if (dayNumber < 10) {
        dayNumber = "0" + dayNumber;
      }
      let monthName = newDate.toLocaleString("default", { month: "long" });
      let monthNameShort = newDate.toLocaleString("default", {
        month: "short",
      });
      let monthNumber = newDate.toLocaleString("default", { month: "numeric" });
      // add 0 before month if less than 10
      if (monthNumber < 10) {
        monthNumber = "0" + monthNumber;
      }
      let hour = newDate.getHours();
      let minute = newDate.getMinutes();
      let second = newDate.getSeconds();
      let yearName = newDate.toLocaleString("default", { year: "numeric" });
      let yearShort = newDate.toLocaleString("default", { year: "2-digit" });
      let time = newDate.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
      let timeShort = newDate.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      let dateDots = `${dayNumber}.${monthNumber}.${year}`;
      if (type == "date") {
        return `${dayName}, ${dayNumber} ${monthName}, ${year}`;
      }
      if (type == "dots") {
        return dateDots;
      }
      if (type == "day") {
        return dayNumber;
      }
      if (type == "month") {
        return monthNameShort;
      }
      if (type == "year") {
        return year;
      }
    },
    $redirect(url) {
      // redirect to url in new tab even if it doen't have http or https
      if (!url) {
        return;
      }
      if (url.includes("http")) {
        window.open(url, "_blank");
      } else {
        window.open("http://" + url, "_blank");
      }
    },
    $ago(date) {
      if (!date) {
        return;
      }
      // get time ago since  date
      let newDate = new Date(date);
      let today = new Date();
      let diff = today - newDate;
      let seconds = Math.floor(diff / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      let weeks = Math.floor(days / 7);
      let months = Math.floor(days / 30);
      let years = Math.floor(days / 365);
      if (seconds < 60) {
        return seconds + " seconds ago";
      } else if (minutes < 60) {
        return minutes + " minutes ago";
      } else if (hours < 24) {
        return hours + " hours ago";
      } else if (days < 7) {
        return days + " days ago";
      } else if (weeks < 4) {
        return weeks + " weeks ago";
      } else if (months < 12) {
        return months + " months ago";
      } else {
        return years + " years ago";
      }
      
    },
    $exchange(amount, currency){
      if(!amount){
        return;
      }
      if(currency == 'USD'){
        return amount * this.$store.state.usdexchange;
      }
      if(currency == 'KRW'){
        return amount * this.$store.state.krwexchange;
      }
    }
  },
  mounted() {
    let showSANav = localStorage.getItem("showSANav");
    if (showSANav != undefined && showSANav != null) {
      // convert to boolean
      if (showSANav == "true") {
        this.$store.state.showSANav = true;
      } else {
        this.$store.state.showSANav = false;
      }
    }

    this.$store.state.apiUploadUrl = process.env.VUE_APP_UPLOAD_URL;
  },
};
</script>