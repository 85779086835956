<template>
  <div class="confirmModel">
    <div
      class="model-dialog"
      :class="[largeModel ? 'large' : '', smallModel ? 'small' : '']"
    >
      <div class="model-content">
        <div class="model-header">
          <label for="">{{ modelTitle }}</label>
          <button class="close" @click="closeModel()">&times;</button>
        </div>
        <div class="model-body">
          <label for="">{{ displayText }}</label>
        </div>
        <div class="model-footer">
          <button class="submit" @click="confirmModel()">
            {{ $txt("Yes") }}
          </button>
          <button class="close" @click="closeModel()">{{ $txt("No") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmModel",
  props: {
    modelTitle: {
      type: String,
      default: "Model Title",
    },
    displayText: {
      type: String,
      default: "Confirm",
    },
    largeModel: {
      type: Boolean,
      default: false,
    },
    smallModel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModel() {
      this.$emit("closeModel");
    },
    confirmModel() {
      this.$emit("confirmModel");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.confirmModel {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #00000079;
  .model-dialog {
    background-color: #fefefe;
    margin: 5% auto;
    border: 1px solid #888;
    width: 50%;
    border-radius: 5px;

    .model-content {
      padding: 10px 20px;
      .model-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        label {
          font-size: 24px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 10px;
        }
        .close {
          color: #aaaaaa;
          font-size: 28px;
          font-weight: bold;
          &:hover,
          &:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
      .model-body {
        margin-bottom: 30px;
      }
      .model-footer {
        display: flex;
        justify-content: flex-end;
        button {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          margin-left: 10px;
          cursor: pointer;
          &.submit {
            background-color: $blue;
            color: #ffffff;
            &:hover {
              background-color: #000000;
            }
          }
          &.close {
            background-color: #a82929;
            color: #ffffff;
          }
        }
      }
    }
  }
  .large {
    &.model-dialog {
      width: 80%;
    }
  }
  .small {
    &.model-dialog {
      width: 30%;
    }
  }
}
</style>