import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const LOGIN_URL = API_URL + '/auth/login';

const login = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['LOGIN']: ({ commit }, payload) => {
            return axios({
                url: LOGIN_URL,
                method: "POST",
                data: payload
            })
        },
    },
    modules: {},
}
export default login;