<template>
  <div class="pagination" :class="position">
    <p v-if="itemCount">
      Showing
      <span v-if="pagination.current_page == 1">1</span>
      <span v-else>
        {{ 1 * itemsPerPage * pagination.current_page - itemsPerPage + 1 }}
      </span>
      -
      <span v-if="pagesNumber != pagination.current_page">{{
        pagination.current_page * itemsPerPage
      }}</span
      ><span v-else>{{ totalItems }}</span> From {{ totalItems }}
    </p>
    <ul>
      <!-- <li>
        <a
          href="#"
          aria-label="Previous"
          @click.prevent="goToFirst()"
          :class="{ disabled: pagination.current_page <= 1 }"
        >
          <span aria-hidden="true"><i class="fa fa-angles-left"></i></span>
        </a>
      </li> -->
      <li>
        <a
          href="#"
          aria-label="Previous"
          @click.prevent="changePage(pagination.current_page - 1)"
          :class="{ disabled: pagination.current_page <= 1 }"
        >
          <span aria-hidden="true"><i class="fa-solid fa-caret-left"></i></span>
        </a>
      </li>
      <li v-for="page in pagesNumber" :key="page">
        <a
          href="#"
          @click.prevent="changePage(page)"
          :class="[pagination.current_page == page ? 'active' : '']"
          v-if="
            page < pagination.current_page + 4 &&
            page > pagination.current_page - 4
          "
          >{{ page }}</a
        >
      </li>
      <li>
        <a
          href="#"
          aria-label="Next"
          @click.prevent="changePage(pagination.current_page + 1)"
          :class="{ disabled: pagination.current_page >= pagesNumber }"
        >
          <span aria-hidden="true"
            ><i class="fa-solid fa-caret-right"></i
          ></span>
        </a>
      </li>
      <!-- <li>
        <a
          href="#"
          aria-label="Next"
          @click.prevent="goToLast()"
          :class="{ disabled: pagination.current_page >= pagesNumber }"
        >
          <span aria-hidden="true"><i class="fa fa-angles-right"></i></span>
        </a>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    itemCount: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      pagination: {
        current_page: 1,
      },
      pagesNumber: 1,
    };
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.pagesNumber) return;
      this.pagination.current_page = page;
      this.$emit("pageChanged", page);
    },
    calculatePagesNumber() {
      this.pagesNumber = Math.ceil(this.totalItems / this.itemsPerPage);
    },
    goToLast() {
      this.pagination.current_page = this.pagesNumber;
      this.$emit("pageChanged", this.pagesNumber);
    },
    goToFirst() {
      this.pagination.current_page = 1;
      this.$emit("pageChanged", 1);
    },
  },
  watch: {
    totalItems() {
      this.calculatePagesNumber();
      this.pagination.current_page = 1;
    },
    itemsPerPage() {
      this.calculatePagesNumber();
      this.pagination.current_page = 1;
    },
  },
  mounted() {
    this.calculatePagesNumber();
    this.pagination.current_page = 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.pagination {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  padding-left: 1rem;
  &.center{
    justify-content: center;
  }
  p {
    color: #667085;
    font-weight: 500;
  }
  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-right: 2rem;
    li {
      a {
        margin: 0 5px;
        display: flex;
        width: 35px;
        height: 35px;
        border: none;
        border-radius: 5px;
        outline: none;
        color: $blue;
        justify-content: center;
        align-items: center;
        background: $lightBlue;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        &:hover {
          background: $blue;
          color: #fff;
          span {
            color: #fff;
            transition: all 0.1s ease-in-out;
          }
        }
        &.active {
          background: $blue !important;
          color: #fff !important;
        }
        &.disabled {
          cursor: not-allowed;
          color: $blue;
          opacity: 0.5;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>