<template>
  <div class="SiteNavbar">
    <div class="navbar">
      <div class="nav-top">
        <router-link :to="{ name: 'Home' }" class="left">
          <img
            :src="$img('logobst.png')"
            alt="Art Ocean Logo"
            @error="$imageurlalt()"
          />
        </router-link>
        <div class="middle on-lg">
          <form @submit.prevent="searchNow()" class="search-form">
            <i class="fas fa-sort-amount-up-alt"></i>
            <input
              type="text"
              placeholder="Search by artist, gallery, tag, etc"
              v-model="searchQuery"
            />
            <button class="fas fa-search"></button>
          </form>
        </div>
        <div class="on-sm">
          <router-link :to="{ name: 'Search' }" class="left">
            <i class="fas fa-search"></i>
          </router-link>
        </div>
        <div class="right on-lg">
          <div class="btns">
            <router-link :to="{ name: 'Vision' }" class="btn login"
              >Analyse&nbsp;Artwork</router-link
            >
          </div>
          <!-- <div class="btns">
            <router-link :to="{ name: 'Login' }" class="btn login"
              >LOG&nbsp;IN</router-link
            >
            <router-link :to="{ name: 'Signup' }" class="btn signup"
              >SIGN&nbsp;UP</router-link
            >
          </div> -->
        </div>

        <div class="toggler on-sm">
          <div class="nav-lines" @click="$toggleSANav()">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="nav-links on-lg">
        <ul>
          <li>
            <router-link :to="{ name: 'Home' }">{{ $txt("Home") }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ArtWorks' }">{{
              $txt("ArtWorks")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Artists' }">{{
              $txt("Artists")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Galleries' }">{{
              $txt("Galleries")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AuctionHouses' }">{{
              $txt("Auction Houses")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Auctions' }">{{
              $txt("Auctions")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Exhibit' }">{{
              $txt("Exhibit")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Articles' }">{{
              $txt("Articles")
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="small-nav" :class="{ active: showSmallNav }">
      <ul>
        <div class="toggler on-sm mb-1">
          <div class="nav-lines" @click="$toggleSANav()">
            <span class="fa fa-times"></span>
          </div>
        </div>
        <li>
          <router-link :to="{ name: 'Home' }">{{ $txt("Home") }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ArtWorks' }">{{
            $txt("ArtWorks")
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Artists' }">{{
            $txt("Artists")
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Galleries' }">{{
            $txt("Galleries")
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AuctionHouses' }">{{
            $txt("Auction Houses")
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Auctions' }">{{
            $txt("Auctions")
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Exhibit' }">{{
            $txt("Exhibit")
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Articles' }">{{
            $txt("Articles")
          }}</router-link>
        </li>
        <!-- <li>
          <router-link :to="{ name: 'Login' }">{{ $txt("Login") }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Signup' }">{{
            $txt("Signup")
          }}</router-link>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "SiteNavbar",
  directives: {
    clickOutSide,
  },
  data() {
    return {
      translatedText: "",
      showDrop: false,
      showAccountDrop: false,
      showSmallNav: false,
      searchQuery: "",
    };
  },
  methods: {
    customMethod() {
      this.showDrop = false;
    },
    customMethod2() {
      this.showAccountDrop = false;
    },
    $toggleSANav() {
      this.showSmallNav = !this.showSmallNav;
    },
    searchNow() {
      if (this.searchQuery) {
        this.$router.push({
          name: "Search",
          query: { searchQuery: this.searchQuery },
        });
      }
    },
    saveIp() {
      this.$store
        .dispatch("SAVE_IP")
        .then((res) => {})
        .catch((err) => {});
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "auto";
    this.saveIp();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.SiteNavbar {
  width: 100%;
  @media screen and (max-width: 850px) {
    margin-bottom: 5rem;
  }
  .navbar {
    width: 100%;
    position: fixed;
    padding: 1rem 3rem;
    border-bottom: 1px solid #d3d3d3;
    // box-shadow: 0px -1px 10px 1px #ababab;
    background: white;
    top: 0;
    z-index: 1;
    @media screen and (max-width: 1500px) {
      padding-bottom: 0px;
    }
    @media screen and (max-width: 850px) {
      padding: 1.2rem 1rem;
    }
    .nav-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: white;
      .left {
        img {
          width: 200px;
          @media screen and (max-width: 1500px) {
            width: 140px;
          }
          @media screen and (max-width: 850px) {
            width: 100px;
          }
        }
      }
      .middle {
        display: flex;
        justify-content: center;
        width: 70%;
        .search-form {
          display: flex;
          align-items: center;
          border-radius: 10px;
          border: 0.5px solid rgba(0, 0, 0, 0.5);
          background: var(--style-color-1, #fdfdfc);
          width: 60%;
          input {
            padding: 10px;
            outline: none;
            width: 100%;
            @media screen and (max-width: 1500px) {
              padding: 5px 10px;
              font-size: 0.8rem;
            }
          }
          i,
          button {
            padding: 0 20px;
            font-size: 1.5rem;
            @media screen and (max-width: 1500px) {
              padding: 5px 10px;
              font-size: 1rem;
            }
          }
        }
      }
      .right {
        .btns {
          display: flex;
          column-gap: 1rem;
          @media screen and (max-width: 1500px) {
            column-gap: 0.5rem;
          }
          a {
            padding: 10px 30px;
            background: white;
            border: 1px $blue solid;
            border-radius: 5px;
            font-size: 1.2rem;
            @media screen and (max-width: 1500px) {
              padding: 5px 20px;
              font-size: 0.9rem;
            }
            &:hover {
              background: #eee;
            }
            &.signup {
              background: $blue;
              color: white;
              &:hover {
                background: darken($color: $blue, $amount: 10%);
              }
            }
          }
        }
      }
    }
    .nav-links {
      background: white;
      ul {
        list-style-type: none;
        display: flex;
        column-gap: 15px;
        padding: 1rem 2rem;
        padding-top: 2.5rem;
        @media screen and (max-width: 1500px) {
          padding: 1rem 2rem;
          padding-top: 1.5rem;
        }
        a {
          color: black;
          font-size: 1.2rem;
          font-weight: 400;
          text-transform: uppercase;
          position: relative;
          @media screen and (max-width: 1500px) {
            font-size: 0.85rem;
          }
          &::after {
            content: "";
            border-bottom: 2px $blue solid;
            width: 100%;
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 0%;
            transition: all 0.5s ease-in-out;
          }
          &:hover,
          &.router-link-active,
          &.router-link-exact-active {
            color: darken($blue, 5%);
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.toggler {
  display: flex;
  .nav-lines {
    display: flex;
    flex-direction: column;
    border: 1px #949494 solid;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    row-gap: 2px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      background-color: #949494;
    }
    div {
      width: 28px;
      border-top: 2px #6e6e6e solid;
      margin: 1.4px 0;
    }
  }
}
.small-nav {
  position: fixed;
  height: 0vh;
  width: 0vw;
  z-index: 1;
  &.active {
    width: 100vw;
    height: 100vh;
    background: transparent;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    ul {
      margin-right: 0;
    }
  }
  ul {
    top: 0;
    right: 0;
    position: fixed;
    margin-right: -800px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    background: white;
    height: 100vh;
    width: 30vw;
    z-index: 9999;
    padding: 1rem 2rem;
    padding-top: 1.5rem;
    @media screen and (max-width: 600px) {
      width: 50vw;
    }

    a {
      color: black;
      font-size: 1rem;
      font-weight: 400;
      text-transform: uppercase;
      position: relative;
      display: block;
      padding: 5px 0;
      // &::after {
      //   content: "";
      //   border-bottom: 2px $blue solid;
      //   width: 100%;
      //   position: absolute;
      //   bottom: 0px;
      //   left: 0;
      //   width: 0%;
      //   transition: all 0.5s ease-in-out;
      // }
      &:hover,
      &.router-link-active,
      &.router-link-exact-active {
        color: darken($blue, 5%);
        &::after {
          width: 100%;
        }
      }
    }
  }
  .toggler {
    margin-bottom: 1rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}
</style>
