import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/site/Home.vue'
import Login from '../views/site/Login.vue'
import Signup from '../views/site/Signup.vue'
import ArtWorks from '../views/site/ArtWorks.vue'
import ViewArtWork from '../views/site/ViewArtWork.vue'
import Artists from '../views/site/Artists.vue'
import ViewArtist from '../views/site/ViewArtist.vue'
import Galleries from '../views/site/Galleries.vue'
import ViewGallery from '../views/site/ViewGallery.vue'
import Auctions from '../views/site/Auctions.vue'
import AuctionHouses from '../views/site/AuctionHouses.vue'
import ViewAuctionHouses from '../views/site/ViewAuctionHouses.vue'
import Articles from '../views/site/Articles.vue'
import ViewArticle from '../views/site/ViewArticle.vue'
import Exhibit from '../views/site/Exhibit.vue'
import ViewExhibit from '../views/site/ViewExhibit.vue'
import Search from '../views/site/Search.vue'
import Vision from '../views/site/Vision.vue'
import ViewAuction from '../views/site/ViewAuction.vue'

// admin
import AdminLogin from '../views/admin/AdminLogin.vue'
import AdminForgotPass from '../views/admin/AdminForgotPass.vue'
import AdminResetPass from '../views/admin/AdminResetPass.vue'
import Admin from '../views/admin/Home.vue'
import AdminDashboard from '../views/admin/Dashboard.vue'
import AdminArtworks from '../views/admin/Artwork/Artworks.vue'
import AdminAddArtwork from '../views/admin/Artwork/AddArtwork.vue'
import AdminArtists from '../views/admin/Artist/Artists.vue'
import AdminAddArtist from '../views/admin/Artist/AddArtist.vue'
import AdminGalleries from '../views/admin/Gallery/Gallery.vue'
import AdminAddGallery from '../views/admin/Gallery/AddGallery.vue'
import AdminExhibitions from '../views/admin/Exhibition/Exhibitions.vue'
import AdminAddExhibition from '../views/admin/Exhibition/AddExhibition.vue'
import AdminAuctionHouses from '../views/admin/AuctionHouse/AuctionHouses.vue'
import AdminAddAuctionHouse from '../views/admin/AuctionHouse/AddAuctionHouse.vue'
import AdminAuctions from '../views/admin/Auction/Auctions.vue'
import AdminAddAuction from '../views/admin/Auction/AddAuction.vue'
import AdminArticles from '../views/admin/Article/Articles.vue'
import AdminAddArticle from '../views/admin/Article/AddArticle.vue'
import AdminProfile from '../views/admin/Profile/Profile.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/artworks',
    name: 'ArtWorks',
    component: ArtWorks
  },
  {
    path: '/artworks/:id/:name',
    name: 'ViewArtWork',
    component: ViewArtWork
  },
  // {
  //   path: '/view-artwork',
  //   name: 'ViewArtWork',
  //   component: ViewArtWork
  // },
  {
    path: '/artists',
    name: 'Artists',
    component: Artists,
    props: (route) => ({ artist: route.query.artist }),
  },
  {
    path: '/artists/:id/:name',
    name: 'ViewArtist',
    component: ViewArtist
  },
  {
    path: '/galleries',
    name: 'Galleries',
    component: Galleries
  },
  {
    path: '/galleries/:id/:name',
    name: 'ViewGallery',
    component: ViewGallery
  },
  {
    path: '/auctions',
    name: 'Auctions',
    component: Auctions
  },
  {
    path: '/auction-houses',
    name: 'AuctionHouses',
    component: AuctionHouses
  },
  {
    path: '/auction-houses/:id/:name',
    name: 'ViewAuctionHouses',
    component: ViewAuctionHouses
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles
  },
  {
    path: '/articles/:id/:name',
    name: 'ViewArticle',
    component: ViewArticle
  },
  {
    path: '/exhibitions',
    name: 'Exhibit',
    component: Exhibit
  },
  {
    path: '/exhibitions/:id/:name',
    name: 'ViewExhibit',
    component: ViewExhibit
  },

  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/admin/forgot-password',
    name: 'AdminForgotPass',
    component: AdminForgotPass
  },
  {
    path: '/admin/reset-password',
    name: 'AdminResetPass',
    component: AdminResetPass
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/analyze-artwork',
    name: 'Vision',
    component: Vision
  },
  {
    path: '/auctions/:id/:name',
    name: 'ViewAuction',
    component: ViewAuction
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    redirect: '/admin/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        component: AdminDashboard
      },
      {
        path: 'artworks/all-artworks',
        name: 'AdminArtworks',
        component: AdminArtworks
      },
      {
        path: 'artworks/add-artwork/:actionType',
        name: 'AdminAddArtwork',
        component: AdminAddArtwork
      },
      {
        path: 'artworks/add-artwork/:actionType/:id',
        name: 'AdminEditArtwork',
        component: AdminAddArtwork
      },
      {
        path: 'artists/all-artists',
        name: 'AdminArtists',
        component: AdminArtists
      },
      {
        path: 'artists/add-artist/:actionType',
        name: 'AdminAddArtist',
        component: AdminAddArtist
      },
      {
        path: 'artists/add-artist/:actionType/:id',
        name: 'AdminEditArtist',
        component: AdminAddArtist
      },
      {
        path: 'galleries/all-galleries',
        name: 'AdminGalleries',
        component: AdminGalleries
      },
      {
        path: 'galleries/add-gallery/:actionType',
        name: 'AdminAddGallery',
        component: AdminAddGallery
      },
      {
        path: 'galleries/add-gallery/:actionType/:id',
        name: 'AdminEditGallery',
        component: AdminAddGallery
      },
      {
        path: 'exhibitions/all-exhibitions',
        name: 'AdminExhibitions',
        component: AdminExhibitions
      },
      {
        path: 'exhibitions/add-exhibition/:actionType',
        name: 'AdminAddExhibition',
        component: AdminAddExhibition
      },
      {
        path: 'exhibitions/add-exhibition/:actionType/:id',
        name: 'AdminEditExhibition',
        component: AdminAddExhibition
      },
      {
        path: 'auction-houses/all-auction-houses',
        name: 'AdminAuctionHouses',
        component: AdminAuctionHouses
      },
      {
        path: 'auction-houses/add-auction-house/:actionType',
        name: 'AdminAddAuctionHouse',
        component: AdminAddAuctionHouse
      },
      {
        path: 'auction-houses/edit-auction-house/:actionType/:id',
        name: 'AdminEditAuctionHouse',
        component: AdminAddAuctionHouse
      },
      {
        path: 'auctions/all-auctions',
        name: 'AdminAuctions',
        component: AdminAuctions
      },
      {
        path: 'auctions/add-auction/:actionType',
        name: 'AdminAddAuction',
        component: AdminAddAuction
      },
      {
        path: 'auctions/add-auction/:actionType/:id',
        name: 'AdminEditAuction',
        component: AdminAddAuction
      },
      {
        path: 'articles/all-articles',
        name: 'AdminArticles',
        component: AdminArticles
      },
      {
        path: 'articles/add-article/:actionType',
        name: 'AdminAddArticle',
        component: AdminAddArticle
      },
      {
        path: 'articles/add-article/:actionType/:id',
        name: 'AdminEditArticle',
        component: AdminAddArticle
      },
      {
        path: 'profile/:tab',
        name: 'AdminProfile',
        component: AdminProfile
      }

    ]
  },

  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
