import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const ADD_GALLERY_URL = API_URL + '/galleries/createGallery';
const GET_GALLERIES_URL = API_URL + '/galleries/all';
const GET_GALLERY_BYID_URL = API_URL + '/galleries/';
const UPDATE_GALLERY_URL = API_URL + '/galleries/updateGallery';
const DELETE_GALLERY_URL = API_URL + '/galleries/deleteGallery';


const galleries = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_GALLERY']: ({ commit }, payload) => {
            return axios({
                url: ADD_GALLERY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_GALLERIES']: ({ commit }, payload) => {
            return axios({
                url: GET_GALLERIES_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_GALLERY_BYID']: ({ commit }, payload) => {
            return axios({
                url: GET_GALLERY_BYID_URL + payload.id,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['UPDATE_GALLERY']: ({ commit }, payload) => {
            return axios({
                url: UPDATE_GALLERY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_GALLERY']: ({ commit }, payload) => {
            return axios({
                url: DELETE_GALLERY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default galleries;