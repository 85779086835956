<template>
  <div class="dashboard">
    <div class="page-top-section">
      <div class="left">
        <h2>AUCTIONS</h2>
        <div class="page-indication">
          <p>Auctions</p>
          <i class="fa-solid fa-caret-right"></i>
          <p>Auctions List</p>
          <i class="fa-solid fa-caret-right"></i>
          <p class="active">Add Auctions</p>
        </div>
      </div>
      <div class="right">
        <div class="export-btn">
          <router-link :to="{ name: 'AdminAuctions' }" class="btn">
            <i class="fa-solid fa-times"></i>
            <span>Cancel</span>
          </router-link>
        </div>
        <div class="add-btn">
          <button class="btn" @click="saveItem()" v-if="actionType == 'Add'">
            <i class="fa-solid fa-plus"></i>
            <span>Save</span>
          </button>
          <button class="btn" @click="updateItem()" v-else>
            <i class="fa-solid fa-save"></i>
            <span>Update</span>
          </button>
        </div>
      </div>
    </div>
    <div class="page-content-section">
      <div class="left">
        <div class="form">
          <div class="form-body">
            <div iv class="form-group">
              <div class="form-header">
                <h3>General Information</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artwork-name"
                    >Auction House registered on Art Ocean?</label
                  >
                  <select v-model="newItem.is_art_auction_house">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
                <div
                  class="input-group-item"
                  v-if="newItem.is_art_auction_house"
                >
                  <label for="artwork-name">Auction House</label>
                  <select v-model="newItem.auction_house_id">
                    <option value="">Select Auction House</option>
                    <option
                      v-for="house in allHouses"
                      :value="house.auction_house_id"
                      :key="house.auction_house_id"
                    >
                      {{ house.auction_house_name_eng }}
                    </option>
                  </select>
                </div>
                <div
                  class="input-group-item"
                  v-if="!newItem.is_art_auction_house"
                >
                  <label for="artwork-name">Auction House(ENG)</label>
                  <input
                    type="text"
                    id="artwork-name"
                    placeholder="Type Auction House(ENG) here. . ."
                    v-model="newItem.auction_house_name_eng"
                  />
                </div>
                <div
                  class="input-group-item"
                  v-if="!newItem.is_art_auction_house"
                >
                  <label for="artwork-location">경매장(KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="경매장. . ."
                    v-model="newItem.auction_house_name_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Auction Name(ENG)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Auction Name(ENG)"
                    v-model="newItem.auction_name_eng"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">경매명(KOR)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="경매명"
                    v-model="newItem.auction_name_kor"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Auction Date(Global)</label>
                  <input
                    type="date"
                    id="artwork-location"
                    placeholder="Auction Date(Global)"
                    v-model="newItem.auction_date"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Auction Time(Global)</label>
                  <input
                    type="date"
                    id="artwork-location"
                    placeholder="Auction Time(Global)"
                    v-model="newItem.auction_time"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Auction Location(Global)</label>
                  <input
                    type="text"
                    id="artwork-location"
                    placeholder="Auction Location(Global)"
                    v-model="newItem.auction_location"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location"
                    >Auction Page website(Original)(Global)</label
                  >
                  <input
                    type="url"
                    id="artwork-location"
                    placeholder="Auction Page website(Original)(Global)"
                    v-model="newItem.auction_website"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Crawling Channel</label>
                  <input
                    type="text"
                    placeholder="Crawling Channel"
                    v-model="newItem.crawling_channel"
                  />
                </div>
                <div class="input-group-item">
                  <label for="artwork-location">Lots</label>
                  <input
                    type="text"
                    placeholder="Lots"
                    v-model="newItem.slots"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-header">
                <h3>Auction thumbnails(Global)</h3>
              </div>
              <div class="input-group">
                <div class="input-group-item">
                  <label for="artist-name">Photo</label>
                  <o-upload
                    :multiple="false"
                    :uploadUrl="'files/upload'"
                    @imagesUploaded="
                      (data) => {
                        newItem.auction_image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.auction_image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      newItem: {
        auction_name_eng: "",
        auction_name_kor: "",
        auction_house_name_eng: "",
        auction_house_name_kor: "",
        auction_date: "",
        auction_website: "",
        crawling_channel: "",
        auction_image: "",
        is_art_auction_house: true,
        auction_house_id: "",
        slots: "",
      },
      actionType: "Add",
      allHouses: [],
    };
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.artWorks = this.$changePage(
        this.allartWorks,
        page,
        this.itemsPerPage
      );
      window.scrollTo(0, 0);
    },
    saveItem() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_AUCTION", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminAuctions" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_AUCTION", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$router.push({ name: "AdminAuctions" });
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },

    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AUCTION_BYID", {
          id: this.$route.params.id,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.newItem = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    getAuctionHouses() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AUCTIONHOUSES", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allHouses = res.data;
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.getAuctionHouses();
    this.actionType = this.$route.params.actionType;
    if (this.actionType == "Edit") {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/dashboard.scss";
</style>