<template>
  <div class="filters">
    <div class="filter-header">
      <h3 class="header">Filters</h3>
      <!-- <div class="sorts">
        <i class="fa-solid fa-arrow-down-wide-short"></i>
        <select name="" id="">
          <option value="">Sort</option>
          <option>Date listed: New to Old</option>
          <option>Date listed: Old to New</option>
          <option>Price: Low to High</option>
          <option>Price: High to Low</option>
        </select>
      </div> -->
      <div class="sorts">
        <i class="fa-solid fa-search"></i>
        <input
          type="text"
          placeholder="Artwork title"
          v-model="filterData.title_eng"
        />
      </div>
    </div>
    <div class="filter-categories">
      <div
        class="category-item"
        :class="filterData.category_eng == '' ? 'active' : ''"
        @click="filterData.category_eng = ''"
      >
        All
      </div>
      <div
        class="category-item"
        v-for="category in allCategories"
        :key="category.category_id"
        :class="filterData.category_eng == category.category_id ? 'active' : ''"
        @click="filterData.category_eng = category.category_id"
      >
        {{ category.category_name_eng }}
      </div>
    </div>
    <div class="filter-expands">
      <div class="expand-item">
        <div class="top" @click="filters.materials = !filters.materials">
          <i class="fa-solid fa-plus" v-if="!filters.materials"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>Material</p>
        </div>
        <div class="bottom" :class="filters.materials ? 'active' : ''">
          <my-checkbox
            :checked="
              filterData.materials_eng.includes(material.material_id)
                ? true
                : false
            "
            @checkNow="
              filterData.materials_eng.includes(material.material_id)
                ? (filterData.materials_eng = filterData.materials_eng.filter(
                    (item) => item != material.material_id
                  ))
                : filterData.materials_eng.push(material.material_id)
            "
            v-for="material in allMaterials"
            :key="material.material_id"
            :text="material.material_name_eng"
            class="checkbox-item"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.medium = !filters.medium">
          <i class="fa-solid fa-plus" v-if="!filters.medium"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>Medium</p>
        </div>
        <div class="bottom" :class="filters.medium ? 'active' : ''">
          <my-checkbox
            :checked="
              filterData.mediums_eng.includes(medium.medium_id) ? true : false
            "
            @checkNow="
              filterData.mediums_eng.includes(medium.medium_id)
                ? (filterData.mediums_eng = filterData.mediums_eng.filter(
                    (item) => item != medium.medium_id
                  ))
                : filterData.mediums_eng.push(medium.medium_id)
            "
            v-for="medium in allMediums"
            :key="medium.medium_id"
            :text="medium.medium_name_eng"
            class="checkbox-item"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.year = !filters.year">
          <i class="fa-solid fa-plus" v-if="!filters.year"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>CREATION YEAR</p>
        </div>
        <div class="bottom" :class="filters.year ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by year"
            v-model="filterData.creation_year"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.prices = !filters.prices">
          <i class="fa-solid fa-plus" v-if="!filters.prices"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>SALE PRICE</p>
        </div>
        <div class="bottom" :class="filters.prices ? 'active' : ''">
          <div class="options">
            <div
              class="option-item"
              @click="
                filterData.hammer_price = '';
                filterData.sale_price = 'Under $500';
              "
              :class="filterData.sale_price == 'Under $500' ? 'active' : ''"
            >
              Under $500
            </div>
            <div
              class="option-item"
              @click="
                filterData.hammer_price = '';
                filterData.sale_price = '$500 - $1000';
              "
              :class="filterData.sale_price == '$500 - $1000' ? 'active' : ''"
            >
              $500 - $1000
            </div>
            <div
              class="option-item"
              @click="
                filterData.hammer_price = '';
                filterData.sale_price = '$1000 - $2000';
              "
              :class="filterData.sale_price == '$1000 - $2000' ? 'active' : ''"
            >
              $1000 - $2000
            </div>
            <div
              class="option-item"
              @click="
                filterData.hammer_price = '';
                filterData.sale_price = '$2000 - $5000';
              "
              :class="filterData.sale_price == '$2000 - $5000' ? 'active' : ''"
            >
              $2000 - $5000
            </div>
            <div
              class="option-item"
              @click="
                filterData.hammer_price = '';
                filterData.sale_price = '$5000 - $10000';
              "
              :class="filterData.sale_price == '$5000 - $10000' ? 'active' : ''"
            >
              $5000 - ${{ $comma(10000) }}
            </div>
            <div
              class="option-item"
              @click="
                filterData.hammer_price = '';
                filterData.sale_price = 'Over $10000';
              "
              :class="filterData.sale_price == 'Over $10000' ? 'active' : ''"
            >
              Over ${{ $comma(10000) }}
            </div>
          </div>
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.hammer = !filters.hammer">
          <i class="fa-solid fa-plus" v-if="!filters.hammer"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>HAMMER PRICE</p>
        </div>
        <div class="bottom" :class="filters.hammer ? 'active' : ''">
          <div class="options">
            <div
              class="option-item"
              @click="
                filterData.sale_price = '';
                filterData.hammer_price = 'Under $500';
              "
              :class="filterData.hammer_price == 'Under $500' ? 'active' : ''"
            >
              Under $500
            </div>
            <div
              class="option-item"
              @click="
                filterData.sale_price = '';
                filterData.hammer_price = '$500 - $1000';
              "
              :class="filterData.hammer_price == '$500 - $1000' ? 'active' : ''"
            >
              $500 - $1000
            </div>
            <div
              class="option-item"
              @click="
                filterData.sale_price = '';
                filterData.hammer_price = '$1000 - $2000';
              "
              :class="
                filterData.hammer_price == '$1000 - $2000' ? 'active' : ''
              "
            >
              $1000 - $2000
            </div>
            <div
              class="option-item"
              @click="
                filterData.sale_price = '';
                filterData.hammer_price = '$2000 - $5000';
              "
              :class="
                filterData.hammer_price == '$2000 - $5000' ? 'active' : ''
              "
            >
              $2000 - $5000
            </div>
            <div
              class="option-item"
              @click="
                filterData.sale_price = '';
                filterData.hammer_price = '$5000 - $10000';
              "
              :class="
                filterData.hammer_price == '$5000 - $10000' ? 'active' : ''
              "
            >
              $5000 - ${{ $comma(10000) }}
            </div>
            <div
              class="option-item"
              @click="
                filterData.sale_price = '';
                filterData.hammer_price = 'Over $10000';
              "
              :class="filterData.hammer_price == 'Over $10000' ? 'active' : ''"
            >
              Over ${{ $comma(10000) }}
            </div>
          </div>
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.location = !filters.location">
          <i class="fa-solid fa-plus" v-if="!filters.location"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>ARTWORK LOCATION</p>
        </div>
        <div class="bottom" :class="filters.location ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by location"
            v-model="filterData.artwork_location_eng"
          />
        </div>
      </div>
      <div class="expand-item">
        <div
          class="top"
          @click="filters.signiture_location = !filters.signiture_location"
        >
          <i class="fa-solid fa-plus" v-if="!filters.signiture_location"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>SIGNITURE LOCATION</p>
        </div>
        <div class="bottom" :class="filters.signiture_location ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by location"
            v-model="filterData.signiture_location_eng"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.artist = !filters.artist">
          <i class="fa-solid fa-plus" v-if="!filters.artist"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>ARTIST NAME</p>
        </div>
        <div class="bottom" :class="filters.artist ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by artist name"
            v-model="filterData.artist_name_eng"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.genre = !filters.genre">
          <i class="fa-solid fa-plus" v-if="!filters.genre"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>GENRE</p>
        </div>
        <div class="bottom" :class="filters.genre ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by genre"
            v-model="filterData.genres_eng"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {
        materials: false,
        medium: false,
        year: false,
        prices: false,
        hammer: false,
        location: false,
        countries: false,
        signiture_location: false,
        artist: false,
      },
      filterData: {
        title_eng: "",
        creation_year: "",
        category_eng: "",
        materials_eng: [],
        mediums_eng: [],
        mediums_techniques_eng: "",
        genres_eng: "",
        signiture_location_eng: "",
        artwork_location_eng: "",
        artist_name_eng: "",
        sale_price: "",
        hammer_price: "",
        selectedCategory: "All",
      },
      allCategories: [],
      allMaterials: [],
      allMediums: [],
    };
  },
  methods: {
    getAllCategories() {
      this.$store
        .dispatch("GET_CATEGORIES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allCategories = res.data;
        })
        .catch((err) => {});
    },
    getAllMaterials() {
      this.$store
        .dispatch("GET_MATERIALS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allMaterials = res.data;
        })
        .catch((err) => {});
    },
    getAllMediums() {
      this.$store
        .dispatch("GET_MEDIUMS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allMediums = res.data;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getAllCategories();
    this.getAllMaterials();
    this.getAllMediums();
  },
  watch: {
    filterData: {
      handler() {
        this.$emit("filterData", this.filterData);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.checkbox-item {
  line-height: 2;
}
</style>