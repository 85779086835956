<template>
  <div class="Topbar">
    <div class="toggler">
      <div class="nav-lines" @click="$toggleSANav()">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div
      class="profile"
      @click="showAccountDrop = true"
      v-click-out-side="customMethod2"
    >
      <LoggedUser :showDrop="showAccountDrop" />
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import LoggedUser from "@/views/admin/components/LoggedUser.vue";
export default {
  name: "Topbar",
  props: {
    pageTitle: {
      type: String,
      default: "Dashboard",
    },
  },
  directives: {
    clickOutSide,
  },
  components: {
    LoggedUser,
  },
  data() {
    return {
      showAccountDrop: false,
    };
  },
  methods: {
    customMethod2() {
      this.showAccountDrop = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Topbar {
  width: 100%;
  // height: 60px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1500px) {
    // height: 40px;
  }
  label {
    font-size: 20px;
    font-weight: 600;
    @media screen and (max-width: 1500px) {
      font-size: 14px;
    }
  }
  .toggler {
    display: flex;
    .nav-lines {
      display: flex;
      flex-direction: column;
      border: 1px #c7c7c7 solid;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 5px;
      row-gap: 2px;
      margin-right: 10px;
      cursor: pointer;
      @media screen and (max-width: 1500px) {
        padding: 3px;
      }
      &:hover {
        background-color: #fdfdfd;
      }
      div {
        width: 25px;
        border-top: 2px #c7c7c7 solid;
        margin: 1.4px 0;
        @media screen and (max-width: 1500px) {
          width: 20px;
          border-top: 2px #c7c7c7 solid;
        }
      }
    }
  }
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    margin-top: 1rem;
    padding: 0.25rem 0;
    border-radius: 1000px;
    padding-left: 1.5rem;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
    }
  }
}
</style>