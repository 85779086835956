import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const ADD_ARTWORK_URL = API_URL + '/artworks/createArtwork';
const GET_ARTWORKS_URL = API_URL + '/artworks/all';
const GET_ARTWORK_BYID_URL = API_URL + '/artworks/';
const UPDATE_ARTWORK_URL = API_URL + '/artworks/updateArtwork';
const DELETE_ARTWORK_URL = API_URL + '/artworks/deleteArtwork';


const artworks = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_ARTWORK']: ({ commit }, payload) => {
            return axios({
                url: ADD_ARTWORK_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ARTWORKS']: ({ commit }, payload) => {
            return axios({
                url: GET_ARTWORKS_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ARTWORK_BYID']: ({ commit }, payload) => {
            return axios({
                url: GET_ARTWORK_BYID_URL + payload.id,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['UPDATE_ARTWORK']: ({ commit }, payload) => {
            return axios({
                url: UPDATE_ARTWORK_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_ARTWORK']: ({ commit }, payload) => {
            return axios({
                url: DELETE_ARTWORK_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default artworks;