<template>
  <img :src="$uploads(artwork_images[0])" alt="" class="art-img" />
  <div class="art-desc">
    <label class="art-name" :for="item.title_eng">{{ item.title_eng }}</label>
    <p class="art-dime">{{ item.size }}</p>
    <label
      class="art-name artist"
      :for="item.artist.artist_name"
      v-if="item.is_art_artist"
    >
      <span v-if="item.artist.artist_name">{{ item.artist.artist_name }}</span>
      <span v-else>-</span>
    </label>
    <label class="art-name artist" :for="item.artist_name_eng" v-else>
      <span v-if="item.artist_name_eng">{{ item.artist_name_eng }}</span>
      <span v-else>-</span></label
    >
    <p class="art-dime loc" v-if="item.is_art_artist">
      <span v-if="item.artist.artist_activity_area_eng">{{
        item.artist.artist_activity_area_eng
      }}</span>
      <span v-else>-</span>
    </p>
    <p class="art-dime loc" v-else>{{ item.artwork_location_eng }}</p>
    <div class="owner">
      <div class="left">
        <label class="owner-name" v-if="item.sale_price">Sale Price</label>
        <label class="owner-name" v-else>Hammer Price</label>
      </div>
      <div class="right">
        <h3 v-if="item.sale_price != ''">
          {{ $comma(item.sale_price) }} {{ item.sale_price_currency }}
        </h3>
        <h3 v-else>
          {{ $comma(item.hammer_price) }} {{ item.hammer_price_currency }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      artwork_images: [],
    };
  },
  mounted() {
    this.artwork_images = JSON.parse(this.item.artwork_images);
  },
};
</script>

<style>
</style>