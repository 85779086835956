import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const ADD_ARTIST_URL = API_URL + '/artists/createArtist';
const GET_ARTISTS_URL = API_URL + '/artists/all';
const GET_ARTIST_BYID_URL = API_URL + '/artists/';
const UPDATE_ARTIST_URL = API_URL + '/artists/updateArtist';
const DELETE_ARTIST_URL = API_URL + '/artists/deleteArtist';


const artists = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_ARTIST']: ({ commit }, payload) => {
            return axios({
                url: ADD_ARTIST_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ARTISTS']: ({ commit }, payload) => {
            return axios({
                url: GET_ARTISTS_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ARTIST_BYID']: ({ commit }, payload) => {
            return axios({
                url: GET_ARTIST_BYID_URL + payload.id,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['UPDATE_ARTIST']: ({ commit }, payload) => {
            return axios({
                url: UPDATE_ARTIST_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_ARTIST']: ({ commit }, payload) => {
            return axios({
                url: DELETE_ARTIST_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default artists;