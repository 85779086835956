<template>
  <div class="Home" v-scroll="handleScroll">
    <site-navbar />
    <div class="slider-container">
      <carousel
        :wrap-around="true"
        :items-to-show="1"
        class="hooper"
        :autoplay="5000"
      >
        <Slide
          v-for="myslide in slides"
          :key="myslide.exhibition_id"
          class="myslide"
        >
          <div
            class="slide-img"
            :style="{
              backgroundImage:
                'url(' + $uploads(myslide.exhibition_image) + ')',
            }"
          >
            <div class="slide-content">
              <div class="left-bottom">
                <h3>{{ myslide.exhibition_name_eng }}</h3>
                <div class="description">
                  <p>{{ myslide.exhibition_description_eng }}</p>
                  <div class="date">{{ $date(myslide.startDate, "dots") }}</div>
                </div>
                <div class="book" v-if="myslide.gallery_url">
                  <button
                    @click="$redirect(myslide.gallery_url)"
                    class="btn btn-primary"
                    target="_blank"
                  >
                    Book Your Spot
                  </button>
                </div>
              </div>
              <div class="featured">NEW</div>
            </div>
          </div>
        </Slide>
      </carousel>
    </div>
    <div class="page-content">
      <div class="populars-items-widget">
        <div class="popular-header">
          <h3>Upcoming events</h3>
        </div>
        <div class="d-grid">
          <div class="exhibit-list-items">
            <router-link
              :to="{
                name: 'ViewExhibit',
                params: {
                  id: item.exhibition_id,
                  name: $urlCompatible(item.exhibition_name_eng),
                },
              }"
              class="item"
              v-for="item in items"
              :key="item.exhibition_id"
            >
              <exhibit-card :item="item" />
            </router-link>
          </div>

          <exhibit-filter
            class="on-lg"
            @filterData="
              (data) => {
                filterData = data;
              }
            "
          />
        </div>
      </div>
      <pagination
        class="pagination"
        :totalItems="allResults.length"
        :itemsPerPage="itemsPerPage"
        :itemCount="false"
        @pageChanged="
          (page) => {
            changePage(page);
          }
        "
      />
    </div>
    <site-footer />
  </div>
</template>

<script>
// import { Hooper, Slide } from "hooper-vue3";
// import "hooper-vue3/dist/hooper.css";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      current_index: 0,
      viewPopup: false,
      selectedItem: null,
      currentPage: 1,
      itemsPerPage: 21,
      filterData: {
        exhibition_name_eng: "",
        exhibition_location_eng: "",
        exhibition_artists_eng: "",
        startDate: "",
        exhibition_gallery_name_eng: "",
        selectedCategory: "All",
      },
      allResults: [],
      items: [],
      allItems: [],
      slides: [],
    };
  },
  methods: {
    closeView() {
      this.viewPopup = false;
      document.body.style.overflowY = "auto";
    },
    filteritems() {
      if (this.selectedCategory == "All") {
        this.items = this.allItems;
      } else {
        this.items = this.allItems.filter((article) => {
          return (
            item.article_tag.toLocaleLowerCase() ==
            this.selectedCategory.toLocaleLowerCase()
          );
        });
      }
    },

    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_EXHIBITIONS", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allItems = res.data;
          this.allResults = res.data;

          let slides = [...res.data];
          this.slides = [...slides.splice(0, 3)];
          this.changePage(1);
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    filterNow() {
      let results1 = [...this.allItems];
      let results2 = [];
      if (this.filterData.exhibition_name_eng != "") {
        results2 = results1.filter((item) => {
          return (
            item.exhibition_name_eng
              .toLowerCase()
              .includes(this.filterData.exhibition_name_eng.toLowerCase()) ||
            item.exhibition_name_kor
              .toLowerCase()
              .includes(this.filterData.exhibition_name_eng.toLowerCase())
          );
        });
      } else {
        results2 = results1;
      }

      // address_eng

      let results3 = [];
      if (this.filterData.exhibition_location_eng != "") {
        results3 = results2.filter((item) => {
          return (
            item.exhibition_location_eng
              .toLowerCase()
              .includes(
                this.filterData.exhibition_location_eng.toLowerCase()
              ) ||
            item.exhibition_location_kor
              .toLowerCase()
              .includes(this.filterData.exhibition_location_eng.toLowerCase())
          );
        });
      } else {
        results3 = results2;
      }
      let results4 = [];
      if (this.filterData.selectedCategory != "All") {
        results4 = results3.filter((item) => {
          return item.exhibition_name_eng
            .toLowerCase()
            .startsWith(this.filterData.selectedCategory.toLowerCase());
        });
      } else {
        results4 = results3;
      }

      let results5 = [];
      if (this.filterData.exhibition_artists_eng != "") {
        results5 = results4.filter((item) => {
          return (
            item.exhibition_artists_eng
              .toLowerCase()
              .includes(this.filterData.exhibition_artists_eng.toLowerCase()) ||
            item.exhibition_artists_kor
              .toLowerCase()
              .includes(this.filterData.exhibition_artists_eng.toLowerCase())
          );
        });
      } else {
        results5 = results4;
      }

      let results6 = [];
      if (this.filterData.exhibition_gallery_name_eng != "") {
        results6 = results5.filter((item) => {
          return (
            item.exhibition_gallery_name_eng
              .toLowerCase()
              .includes(
                this.filterData.exhibition_gallery_name_eng.toLowerCase()
              ) ||
            item.exhibition_gallery_name_kor
              .toLowerCase()
              .includes(
                this.filterData.exhibition_gallery_name_eng.toLowerCase()
              )
          );
        });
      } else {
        results6 = results5;
      }

      let results7 = [];
      if (this.filterData.startDate != "") {
        results7 = results6.filter((item) => {
          return (
            item.startDate
              .toLowerCase()
              .includes(this.filterData.startDate.toLowerCase()) ||
            item.startDate
              .toLowerCase()
              .includes(this.filterData.startDate.toLowerCase())
          );
        });
      } else {
        results7 = results6;
      }

      this.allResults = results7;

      this.currentPage = 1;
      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;
      this.items = this.$changePage(this.allResults, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getItems();
    this.current_index = 10;
  },
  watch: {
    filterData: {
      handler: function (val) {
        this.filterNow();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Home {
  background: #fcfcfc;
  .slider-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 180px;
    margin-bottom: 50px;
    @media screen and (max-width: 1500px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 850px) {
      // height: 300px;
      margin-top: 60px;
    }
    .slide-img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .slide-content {
        min-height: 800px;
        background: rgba(0, 0, 0, 0.582);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1500px) {
          min-height: 500px;
        }
        @media screen and (max-width: 850px) {
          min-height: 400px;
        }
        .left-bottom {
          padding: 5rem;
          padding-bottom: 2rem;
          color: #fff;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 2rem;
          width: 50%;
          @media screen and (max-width: 850px) {
            width: 100%;
            padding: 1rem;
            padding-bottom: 2rem;
          }
          h6 {
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            background: rgba(23, 177, 204, 0.592);
            padding: 0.5rem 1rem;
            border-radius: 500px;
            @media screen and (max-width: 1500px) {
              font-size: 0.8rem;
              padding: 0.2rem 0.5rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
          h3 {
            font-size: 2.7rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            width: 100%;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1.5rem;
            }
          }
          .description {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            .date {
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-align: left;
              margin-right: 1rem;
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
            hr {
              width: 40px;
              height: 2px;
              background: #fff;
              margin-top: 0.7rem;
              margin-right: 1rem;
            }
            p {
              font-size: 1.25rem;
              text-align: left;
              color: #ececec;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;

              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
          .book {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            row-gap: 1rem;
            a,
            button {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #06748321;
              padding: 1rem 1rem;
              border-radius: 500px;
              color: #fff;
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-align: left;
              border: 1px $blue solid;
              text-transform: uppercase;
              &:hover {
                background: #06748398;
              }
              @media screen and (max-width: 1500px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
        .featured {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          padding: 0.2rem 1rem;
          color: #fff;
          background: rgba(255, 255, 255, 0.259);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          font-size: 1.2rem;
          border-radius: 500px;
        }
      }
    }
  }
  .populars-items-widget {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    .popular-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0rem;
      margin-bottom: 50px;
      @media screen and (max-width: 850px) {
        padding: 1rem;
        padding-left: 0px;
      }
      h3 {
        color: #000000;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        @media screen and (max-width: 1500px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1100px) {
          font-size: 27px;
        }
        @media screen and (max-width: 850px) {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 1rem;
        }
      }
      .search {
        width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        input {
          width: 100%;
          padding: 0.5rem 1rem;
          border: 1px #adadad solid;
          border-radius: 5px;
          padding-left: 2.5rem;
          font-size: 1rem;
          outline: none;
          @media screen and (max-width: 1500px) {
            font-size: 0.9rem;
            padding: 0.25rem 0.5rem;
            padding-left: 1.8rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
        i {
          color: #adadad;
          font-size: 1.25rem;
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-50%);
          @media screen and (max-width: 1500px) {
            left: 0.5rem;
            font-size: 1rem;
          }
          @media screen and (max-width: 1100px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 850px) {
            font-size: 0.9rem;
          }
        }
      }
    }
    .d-grid {
      display: flex;
      align-items: flex-start;
    }
  }
  .page-content {
    margin-top: 0;
    padding: 1rem 5rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 850px) {
      padding: 1rem 1rem;
    }
    .page-body {
      width: calc(100% - 300px);
      padding-top: 2rem;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 250px);
      }
      @media screen and (max-width: 850px) {
        width: 100%;
      }
      .items-container {
        .items-header {
          h3 {
            color: #000000;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media screen and (max-width: 1500px) {
              font-size: 2rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 27px;
            }
            @media screen and (max-width: 850px) {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
          p {
            color: #333;
            font-size: 1.6rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            @media screen and (max-width: 1500px) {
              margin-top: 0.2rem;
              font-size: 1rem;
            }
            @media screen and (max-width: 1100px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 850px) {
              font-size: 1rem;
            }
          }
        }
        .on-phone-search {
          display: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          column-gap: 10px;
          @media screen and (max-width: 850px) {
            padding: 1rem;
            padding-left: 0px;
          }
          .search {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .form {
              input {
                width: 100%;
                padding: 0.5rem 1rem;
                border: 1px #adadad solid;
                border-radius: 5px;
                font-size: 1.25rem;
                padding-left: 2.5rem;
                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
              i {
                color: #adadad;
                font-size: 1.25rem;
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);

                @media screen and (max-width: 1100px) {
                  font-size: 1rem;
                }
                @media screen and (max-width: 850px) {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .filter {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 5px;
            background: $blue;
            color: #fff;
            padding: 10px 1.5rem;
            border-radius: 500px;
            position: relative;
            i {
              color: #fff;
              position: relative;
              font-size: 1.25rem;
              @media screen and (max-width: 1100px) {
                font-size: 1rem;
              }
              @media screen and (max-width: 850px) {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>