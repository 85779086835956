<template>
  <div class="dashboard">
    <div class="page-top-section">
      <div class="left">
        <h2>AUCTIONS</h2>
        <div class="page-indication">
          <p>Auctions</p>
          <i class="fa-solid fa-caret-right"></i>
          <p class="active">Auctions List</p>
        </div>
      </div>
      <div class="right">
        <div class="export-btn">
          <button class="btn">
            <i class="fa-solid fa-download"></i>
            <span>Export</span>
          </button>
        </div>
        <div class="add-btn">
          <router-link
            :to="{ name: 'AdminAddAuction', params: { actionType: 'Add' } }"
            class="btn"
          >
            <i class="fa-solid fa-plus"></i>
            <span>Add Auction</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="categories-section">
      <div class="categories-left">
        <div class="categories-list">
          <span class="active">All</span>
        </div>
      </div>
      <div class="categories-right">
        <div class="search">
          <form action="">
            <i class="fa-solid fa-search"></i>
            <input type="text" placeholder="Search auctions. . ." />
          </form>
        </div>
        <div class="filter-btn">
          <i class="fa-solid fa-filter"></i>
          <span>Filters</span>
        </div>
      </div>
    </div>
    <div class="art-table">
      <table>
        <!-- Artwork, UCI Code, Artist Name, Dimensions, Color, Sales Price, Hammer Price, Status, Creation year, Action -->
        <thead>
          <tr>
            <th class="sm">
              <my-checkbox
                :checked="checkedItems.length > 0"
                @checkNow="checkNow(1)"
                :mainCheckbox="true"
                @UncheckAllNow="checkedItems = []"
                @checkAllNow="checkAllNow()"
              />
            </th>
            <th>Image</th>
            <th>Auction House(ENG)</th>
            <th>경매장(KOR)</th>
            <th>Auction Name(ENG)</th>
            <th>경매명(KOR)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
            :class="{ active: checkedContains(item.auction_id) }"
          >
            <td class="sm">
              <my-checkbox
                :checked="checkedContains(item.auction_id)"
                @checkNow="checkNow(item.auction_id)"
              />
            </td>
            <td>
              <div class="img">
                <img :src="$uploads(item.auction_image)" alt="" />
              </div>
            </td>
            <td v-if="item.is_art_auction_house">
              {{ item.auction_house.auction_house_name_eng }}
            </td>
            <td v-else>{{ item.auction_house_name_eng }}</td>
            <td v-if="item.is_art_auction_house">
              {{ item.auction_house.auction_house_name_kor }}
            </td>
            <td v-else>{{ item.auction_house_name_kor }}</td>
            <td>{{ item.auction_name_eng }}</td>
            <td>{{ item.auction_name_kor }}</td>
            <td>
              <div class="actions">
                <router-link
                  :to="{
                    name: 'AdminEditAuction',
                    params: { actionType: 'Edit', id: item.auction_id },
                  }"
                  class="fa-solid fa-pen"
                ></router-link>
                <router-link
                  :to="{
                    name: 'AdminEditAuction',
                    params: { actionType: 'Edit', id: item.auction_id },
                  }"
                  class="fa-solid fa-eye"
                ></router-link>
                <i
                  class="fa-solid fa-trash"
                  @click="
                    (e) => {
                      e.preventDefault();
                      selectedItem = item;
                      showDeleteModel = true;
                    }
                  "
                >
                </i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <pagination
        class="pagination"
        :totalItems="allItems.length"
        :itemsPerPage="itemsPerPage"
        @pageChanged="
          (page) => {
            changePage(page);
          }
        "
      />
    </div>
    <confirm-model
      :modelTitle="'Delete Auction'"
      :displayText="`Are you sure you want to delete ${selectedItem.auction_name_eng} auction?`"
      :smallModel="true"
      @closeModel="showDeleteModel = false"
      @confirmModel="deleteItem()"
      v-if="showDeleteModel"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      allItems: [],
      items: [],
      checkedItems: [],
      currentPage: 1,
      itemsPerPage: 10,
      showDeleteModel: false,
      selectedItem: null,
    };
  },
  methods: {
    checkedContains(id) {
      return this.checkedItems.includes(id);
    },
    checkNow(id) {
      if (this.checkedItems.includes(id)) {
        this.checkedItems = this.checkedItems.filter((item) => item !== id);
      } else {
        this.checkedItems.push(id);
      }
    },
    checkAllNow() {
      this.checkedItems = [];
      this.items.forEach((item) => {
        this.checkedItems.push(item.id);
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.items = this.$changePage(this.allItems, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AUCTIONS", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allItems = res.data;
          this.items = this.$changePage(
            this.allItems,
            this.currentPage,
            this.itemsPerPage
          );
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_AUCTION", {
          data: this.selectedItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.showDeleteModel = false;
            this.getItems();
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/dashboard.scss";
</style>