<template>
  <div class="item-img">
    <img :src="$uploads(item.article_image)" alt="" />
    <div class="tag">{{ item.article_source_eng }}</div>
  </div>
  <div class="item-content">
    <div class="item-header">
      <div class="item-date flex-btn">
        <h6>{{ item.article_date }}</h6>
        <p>{{ item.article_writer_eng }}</p>
      </div>
      <div class="item-title">
        <h3>{{ item.article_name_eng }}</h3>
      </div>
    </div>
    <div class="item-description">
      <p v-html="item.article_text_eng"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>