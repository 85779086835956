<template>
  <div class="dashboard">
    <div class="page-top-section">
      <div class="left">
        <h2>ARTICLES</h2>
        <div class="page-indication">
          <p>Articles</p>
          <i class="fa-solid fa-caret-right"></i>
          <p class="active">Articles List</p>
        </div>
      </div>
      <div class="right">
        <div class="export-btn">
          <button class="btn">
            <i class="fa-solid fa-download"></i>
            <span>Export</span>
          </button>
        </div>
        <div class="add-btn">
          <router-link
            :to="{ name: 'AdminAddArticle', params: { actionType: 'Add' } }"
            class="btn"
          >
            <i class="fa-solid fa-plus"></i>
            <span>Add Article</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="categories-section">
      <div class="categories-left">
        <div class="categories-list">
          <span class="active">All</span>
        </div>
      </div>
      <div class="categories-right">
        <div class="search">
          <form action="">
            <i class="fa-solid fa-search"></i>
            <input type="text" placeholder="Search articles. . ." />
          </form>
        </div>
        <div class="filter-btn">
          <i class="fa-solid fa-filter"></i>
          <span>Filters</span>
        </div>
      </div>
    </div>
    <div class="art-table">
      <table>
        <!-- Artwork, UCI Code, Artist Name, Dimensions, Color, Sales Price, Hammer Price, Status, Creation year, Action -->
        <thead>
          <tr>
            <th class="sm">
              <my-checkbox
                :checked="checkedItems.length > 0"
                @checkNow="checkNow(1)"
                :mainCheckbox="true"
                @UncheckAllNow="checkedItems = []"
                @checkAllNow="checkAllNow()"
              />
            </th>
            <th>Image</th>
            <th>Article Name (ENG)</th>
            <th>Article Name (KOR)</th>
            <th>Article Source (ENG)</th>
            <th>Article Source (KOR)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
            :class="{ active: checkedContains(item.article_id) }"
          >
            <td class="sm">
              <my-checkbox
                :checked="checkedContains(item.article_id)"
                @checkNow="checkNow(item.article_id)"
              />
            </td>
            <td>
              <div class="img">
                <img :src="$uploads(item.article_image)" alt="" />
              </div>
            </td>
            <td>{{ item.article_name_eng }}</td>
            <td>{{ item.article_name_kor }}</td>
            <td>{{ item.article_source_eng }}</td>
            <td>{{ item.article_source_kor }}</td>
            <td>
              <div class="actions">
                <router-link
                  :to="{
                    name: 'AdminEditArticle',
                    params: { actionType: 'Edit', id: item.article_id },
                  }"
                  class="fa-solid fa-pen"
                ></router-link>
                <router-link
                  :to="{
                    name: 'AdminEditArticle',
                    params: { actionType: 'Edit', id: item.article_id },
                  }"
                  class="fa-solid fa-eye"
                ></router-link>
                <i
                  class="fa-solid fa-trash"
                  @click="
                    (e) => {
                      e.preventDefault();
                      selectedItem = item;
                      showDeleteModel = true;
                    }
                  "
                >
                </i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <pagination
        class="pagination"
        :totalItems="allItems.length"
        :itemsPerPage="itemsPerPage"
        @pageChanged="
          (page) => {
            changePage(page);
          }
        "
      />
      <confirm-model
        :modelTitle="'Delete Article'"
        :displayText="`Are you sure you want to delete ${selectedItem.article_name_eng} ?`"
        :smallModel="true"
        @closeModel="showDeleteModel = false"
        @confirmModel="deleteItem()"
        v-if="showDeleteModel"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allItems: [],
      items: [],
      checkedItems: [],
      currentPage: 1,
      itemsPerPage: 10,
      showDeleteModel: false,
      selectedItem: null,
    };
  },
  methods: {
    checkedContains(id) {
      return this.checkedItems.includes(id);
    },
    checkNow(id) {
      if (this.checkedItems.includes(id)) {
        this.checkedItems = this.checkedItems.filter((item) => item !== id);
      } else {
        this.checkedItems.push(id);
      }
    },
    checkAllNow() {
      this.checkedItems = [];
      this.items.forEach((item) => {
        this.checkedItems.push(item.id);
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.items = this.$changePage(this.allItems, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ARTICLES", {
          data: this.newItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.allItems = res.data;
          this.items = this.$changePage(
            this.allItems,
            this.currentPage,
            this.itemsPerPage
          );
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ARTICLE", {
          data: this.selectedItem,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "error") {
            this.$notify({
              type: "error",
              title: "Something went wrong",
              text: res.data.message,
            });
          } else {
            this.$notify({
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.showDeleteModel = false;
            this.getItems();
          }
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 400) {
            err.response.data.errors.forEach((error) => {
              this.$notify({
                type: "error",
                title: "Something went wrong",
                text: error.msg,
              });
            });
          }
          if (err.response.status == 401) {
            this.$notify({
              type: "error",
              title: "Authentication failed",
              text: err.response.data.message,
            });
            this.$router.push({ name: "AdminLogin" });
          }
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.dashboard {
  padding: 1.5rem;
  padding-bottom: 5rem;
  .page-top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h2 {
        font-size: 1.75rem;
        font-weight: 600;
        color: black;
        margin-right: 20px;
        margin-bottom: 1rem;
      }
      .page-indication {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0.3rem;
        p {
          font-size: 1.1rem;
          font-weight: 500;
          color: #333;
          margin-right: 5px;
          &:last-child {
            color: $blue;
          }
        }
        i {
          font-size: 16px;
          color: rgb(184, 184, 184);
          margin-right: 5px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      .btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        background-color: $lightBlue;
        border-radius: 10px;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover {
          background-color: lighten($color: $blue, $amount: 30%);
          i {
            color: #000;
          }
          span {
            color: #000;
          }
        }
        i {
          font-size: 20px;
          color: $blue;
          margin-right: 5px;
        }
        span {
          font-size: 1.2rem;
          font-weight: 400;
          color: $blue;
        }
      }
      .export-btn {
        margin-right: 20px;
      }
      .add-btn {
        .btn {
          background-color: $blue;
          span {
            color: white;
          }
          i {
            color: white;
          }
          &:hover {
            background-color: darken($color: $blue, $amount: 10%);
            i {
              color: #fff;
            }
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .categories-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .categories-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .categories-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0.2rem;
        border: 1px solid #dddddd;
        border-radius: 10px;
        padding: 5px;
        span {
          font-size: 1.1rem;
          font-weight: 500;
          color: #333;
          padding: 5px 20px;
          border-radius: 10px;
          cursor: pointer;
          &:hover,
          &.active {
            color: $blue;
            font-weight: 600;
            background-color: $lightBlue;
          }
        }
      }
    }
    .categories-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 1rem;
      .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        outline: none;
        border: 1px solid #dddddd;
        cursor: pointer;
        &:hover {
        }
        i {
          font-size: 20px;
          color: #858d9d;
          margin-right: 10px;
        }
        span {
          font-size: 1.2rem;
          font-weight: 400;
          color: $blue;
        }
      }
      .dates {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        padding-left: 20px;
        background-color: white;
        border-radius: 10px;
        outline: none;
        border: 1px solid #dddddd;
        cursor: pointer;
        &:hover {
        }
        i {
          font-size: 20px;
          color: #858d9d;
          margin-right: 10px;
        }
        span {
          font-size: 1.2rem;
          font-weight: 400;
          color: #858d9d;
        }
      }
      .filter-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        padding-left: 20px;
        background-color: white;
        border-radius: 10px;
        outline: none;
        border: 1px solid #dddddd;
        cursor: pointer;
        &:hover {
        }
        i {
          font-size: 20px;
          color: #858d9d;
          margin-right: 10px;
        }
        span {
          font-size: 1.2rem;
          font-weight: 400;
          color: #858d9d;
        }
      }
      input {
        color: #858d9d;
      }
    }
  }
  .art-table {
    margin-top: 2rem;
  }
}
</style>