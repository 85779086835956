<template>
  <div class="dashboard">
    <div class="profile-content">
      <div class="profile-tabs">
        <div
          class="tab"
          :class="{ active: tab == 'profile' }"
          @click="
            tab = 'profile';
            redirect();
          "
        >
          {{ $txt(`Account  Setting`) }}
        </div>
        <div
          class="tab"
          :class="{ active: tab == 'security' }"
          @click="
            tab = 'security';
            redirect();
          "
        >
          {{ $txt(`Login & Security`) }}
        </div>
      </div>
      <div class="profile-body">
        <div class="profile-tab-content" v-if="tab == 'profile'">
          <form action="">
            <div class="form-group group-1">
              <div class="input-group">
                <label for="artist-name">Profile Picture</label>
                <o-upload
                  class="img-upload"
                  :text="'Upload your photo'"
                  :multiple="false"
                  :uploadUrl="'files/upload'"
                  @imagesUploaded="
                    (data) => {
                      user.image = data;
                    }
                  "
                  @imagesUploadedFailed="
                    (data) => {
                      console.log('failed');
                    }
                  "
                  :value="user.image"
                  @startLoader="() => $startLoader()"
                  @stopLoader="() => $stopLoader()"
                />
              </div>
            </div>
            <hr />
            <div class="form-group group-2">
              <div class="input-group">
                <label for="artist-name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="artist-name"
                  v-model="user.name"
                />
              </div>
              <div class="input-group">
                <label for="artist-email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="artist-email"
                  v-model="user.email"
                />
              </div>
            </div>
            <!-- Username, Role -->
            <div class="form-group group-2">
              <div class="input-group">
                <label for="artist-username">Username</label>
                <input
                  type="text"
                  name="username"
                  id="artist-username"
                  v-model="user.username"
                />
              </div>
              <div class="input-group">
                <label for="artist-role">Role</label>
                <input
                  type="text"
                  name="role"
                  id="artist-role"
                  v-model="user.role"
                  disabled
                />
              </div>
            </div>
            <!-- submit -->
            <div class="form-group group-1">
              <div class="add-btn">
                <button type="submit" class="btn wd">
                  {{ $txt(`Update Profile`) }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="profile-tab-content" v-if="tab == 'security'">
          <form action="">
            <div class="form-group group-2">
              <div class="input-group">
                <label for="artist-name">{{ $txt(`Current Password`) }}</label>
                <input
                  type="password"
                  name="name"
                  id="artist-name"
                  v-model="passwords.current"
                />
              </div>
            </div>
            <div class="form-group group-2">
              <div class="input-group">
                <label for="artist-name">{{ $txt(`New Password`) }}</label>
                <input
                  type="password"
                  name="name"
                  id="artist-name"
                  v-model="passwords.new"
                />
              </div>
            </div>
            <div class="form-group group-2">
              <div class="input-group">
                <label for="artist-name">{{
                  $txt(`Confirm New Password`)
                }}</label>
                <input
                  type="password"
                  name="name"
                  id="artist-name"
                  v-model="passwords.confirm"
                />
              </div>
            </div>
            <div class="form-group group-1">
              <div class="add-btn">
                <button type="submit" class="btn wd">
                  {{ $txt(`Update Password`) }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      allitems: [],
      items: [],
      checkedItems: [],
      currentPage: 1,
      itemsPerPage: 10,
      tab: "profile",
      user: {
        image: null,
      },
      passwords: {
        current: null,
        new: null,
        confirm: null,
      },
    };
  },
  methods: {
    redirect(path) {
      this.$router.push({ name: "AdminProfile", params: { tab: this.tab } });
    },
  },
  mounted() {
    this.tab = this.$route.params.tab;
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.dashboard {
  .profile-content {
    margin: 1rem 8rem;
    .profile-tabs {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      column-gap: 20px;
      .tab {
        padding: 10px 20px;
        background-color: #fff;
        color: #000;
        font-weight: 500;
        cursor: pointer;
        line-height: 0.8;
        &.active,
        &:hover {
          font-weight: 600;
          background-color: #fff;
          color: $blue;
          border-bottom: 2px solid $blue;
        }
      }
    }
    hr {
      margin-bottom: 2rem;
      border-bottom: 2px solid #f2f2f2;
    }
    .profile-body {
      padding-top: 2rem;
      .profile-tab-content {
        form {
          .form-group {
            margin-bottom: 10px !important;
            column-gap: 1rem;
            &.group-1 {
              .input-group {
                .img-upload {
                  width: 200px;
                  transform: scale(1);
                }
              }
            }
          }
          .add-btn {
          }
        }
      }
    }
  }
}
</style>