<template>
  <div class="Sidebar">
    <div class="sidebar-header">
      <img
        :src="$img('logobst.png')"
        alt="Art Ocean Logo"
        @error="$imageurlalt()"
      />
    </div>
    <ul class="nav-links">
      <li>
        <router-link
          :to="{ name: 'AdminDashboard' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/dashboard') }"
        >
          <span>Overview</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminArtworks' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/artworks/all-artworks') }"
        >
          <span>Artworks</span>
        </router-link>
        <!-- <ul
          class="sub-links"
          :class="$store.state.activePage == 'artworks' ? 'active' : ''"
        >
          <li>
            <router-link
              :to="{ name: 'AdminArtworks' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/artworks/all-artworks') }"
            >
              <span>Artworks list</span>
            </router-link>
          </li>
        </ul> -->
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminArtists' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/artists') }"
        >
          <span>Artists</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminGalleries' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/galleries') }"
        >
          <span>Galleries</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminAuctionHouses' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/auction-houses') }"
        >
          <span>Auction Houses</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminAuctions' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/auctions') }"
        >
          <span>Auctions</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminExhibitions' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/exhibitions') }"
        >
          <span>Exhibitions</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminArticles' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/articles') }"
        >
          <span>Articles</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      userRole: "",
    };
  },
  methods: {
    toggleSubLinks(id) {
      if (this.$store.state.activePage == id) {
        this.$store.state.activePage = "";
        localStorage.setItem("art_activePage", "");
      } else {
        this.$store.state.activePage = id;
        localStorage.setItem("art_activePage", id);
      }
    },
  },
  mounted() {
    if (
      localStorage.getItem("art_activePage") &&
      localStorage.getItem("art_activePage") != ""
    ) {
      this.$store.state.activePage = localStorage.getItem("art_activePage");
    } else {
      this.$store.state.activePage = "";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Sidebar {
  width: 100%;
  height: 100%;
  background-color: white;
  border-right: 1px solid white;
  border-right: 1px solid var(--neutral-gray-gray-50, #e5e8ee);
  box-shadow: 4px 0px 30px 0px rgba(131, 98, 234, 0.05);

  .sidebar-header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
      max-width: 200px;
    }
  }
  .nav-links {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      .nav-link {
        border-bottom: 1px solid white;
        border-left: 4px solid white;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 1rem;
        padding-left: 2rem;
        height: 50px;
        cursor: pointer;
        span {
          font-size: 0.92rem;
          font-weight: 300;
          color: black;
          text-transform: uppercase;
        }
        &:hover,
        &.active {
          background-color: $lightBlue;
          border-left: 4px solid $blue;
          span {
            color: $blue;
            font-weight: 600;
          }
        }
        &.nav-link-with-sublinks {
          display: flex;
          justify-content: space-between;
          i {
            margin-left: auto;
            font-size: 0.8rem;
            color: var(--neutral-gray-gray-100, #c4c4c4);
            cursor: pointer;
          }
        }
      }
      .sub-links {
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        &.active {
          height: 100%;
          li {
            .nav-link {
              padding-left: 3rem;
              color: $blue;
              background: white;
              border-left: 4px solid white;
              span {
                font-weight: 400;
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>