<template>
  <div class="filters on-lg">
    <div class="filter-header">
      <h3 class="header">Filters</h3>
      <div class="sorts">
        <i class="fa-solid fa-search"></i>
        <input
          type="text"
          placeholder="Auction Name"
          v-model="filterData.auction_name_eng"
        />
      </div>
    </div>
    <div class="filter-categories">
      <div
        class="category-item"
        :class="filterData.selectedCategory == 'All' ? 'active' : ''"
        @click="filterData.selectedCategory = 'All'"
      >
        All
      </div>
      <div
        class="category-item"
        v-for="category in categories"
        :key="category.id"
        :class="filterData.selectedCategory == category ? 'active' : ''"
        @click="filterData.selectedCategory = category"
      >
        {{ category }}
      </div>
    </div>
    <div class="filter-expands">
      <div class="expand-item">
        <div class="top" @click="filters.location = !filters.location">
          <i class="fa-solid fa-plus" v-if="!filters.location"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>LOCATION</p>
        </div>
        <div class="bottom" :class="filters.location ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by location"
            v-model="filterData.auction_location"
          />
        </div>
      </div>
      <div class="expand-item">
        <div class="top" @click="filters.auctionhouse = !filters.auctionhouse">
          <i class="fa-solid fa-plus" v-if="!filters.auctionhouse"></i>
          <i class="fa-solid fa-minus" v-else></i>
          <p>AUCTION HOUSE</p>
        </div>
        <div class="bottom" :class="filters.auctionhouse ? 'active' : ''">
          <input
            type="text"
            placeholder="Search by auction house"
            v-model="filterData.auction_house_name_eng"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {
        sizes: false,
        location: false,
        auctionhouse: false,
      },
      categories: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      filterData: {
        auction_name_eng: "",
        auction_location: "",
        auction_house_name_eng: "",
        selectedCategory: "All",
      },
    };
  },
  methods: {},
  watch: {
    filterData: {
      handler() {
        this.$emit("filterData", this.filterData);
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>