import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const ADD_ARTICLE_URL = API_URL + '/articles/createArticle';
const GET_ARTICLES_URL = API_URL + '/articles/all';
const GET_ARTICLE_BYID_URL = API_URL + '/articles/';
const UPDATE_ARTICLE_URL = API_URL + '/articles/updateArticle';
const DELETE_ARTICLE_URL = API_URL + '/articles/deleteArticle';


const articles = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_ARTICLE']: ({ commit }, payload) => {
            return axios({
                url: ADD_ARTICLE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ARTICLES']: ({ commit }, payload) => {
            return axios({
                url: GET_ARTICLES_URL,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ARTICLE_BYID']: ({ commit }, payload) => {
            return axios({
                url: GET_ARTICLE_BYID_URL + payload.id,
                method: "GET",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['UPDATE_ARTICLE']: ({ commit }, payload) => {
            return axios({
                url: UPDATE_ARTICLE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_ARTICLE']: ({ commit }, payload) => {
            return axios({
                url: DELETE_ARTICLE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default articles;